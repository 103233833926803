import React from "react";
import Modal from "@material-ui/core/Modal";

export default function SimpleModal({ open, handleClose, deleteBlog }) {
  const handleDelete = () => {
    deleteBlog();
    handleClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className=" bg-white border rounded-md border-gray-500 p-4 shadow-custom md:width-600 space-y-2 rtl">
          <h1 className="primary-blue">
            هل أنت متأكد أنك تريد حذف هذه المدونة
          </h1>
          <div className="flex justify-end gap-2">
            <button
              className="bg-red-700 px-4 py-2 text-white font-semibold rounded-md"
              onClick={handleDelete}
            >
              تأكيد
            </button>
            <button
              className="bg-green-700 px-4 py-2 text-white font-semibold rounded-md"
              onClick={handleClose}
            >
              غلق
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
