import React from "react";
import FirstCardsSection from "./content/overview/FirstCardsSection";
import SecondCoursesSection from "./content/overview/SecondCoursesSection";
import ThirdDetailSection from "./content/overview/ThirdDetailSection";
import FourthCardsSection from "./content/overview/FourthCardsSection";

const Overview = () => {
  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto md:pb-12 ">
      <div className="p-8 text-right">
        <p className="md:text-base 2xl:text-2xl">مرحبا بك</p>
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">نظرة عامة</h3>
      </div>
      <div className="h-full x-sm:px-4 md:px-6 2xl:p-12 space-y-16">
        <FirstCardsSection></FirstCardsSection>
        <SecondCoursesSection></SecondCoursesSection>
        <ThirdDetailSection></ThirdDetailSection>
        <FourthCardsSection></FourthCardsSection>
      </div>
    </div>
  );
};

export default Overview;
