import React, { useState } from "react";
import LoaderModal from "./content/LoaderModal";
import BookCard from "./content/books/BookCard";
import { useHistory } from "react-router-dom";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../services/fetcher";
import { domain } from "./../../../global/domain";
import { deleteBook } from "../../../services/books";

const Books = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { data, error } = useSWR(`https://${domain}/books/books/`, fetcher);

  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto">
      <div className="p-8 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">الكتب</h3>
      </div>
      <div className="x-sm:px-2 md:px-6 2xl:p-12 mb-16 space-y-10">
        <div className="x-sm:w-full md:w-11/12 rounded-lg flex flex-col x-sm:p-2 md:p-4 2xl:p-12">
          <div className="flex flex-row space-x-1 items-center self-end x-sm:text-sm md:text-base 2xl:text-4xl">
            <div className="text-center">
              <button
                onClick={() => {
                  history.push("/admin/books/add-book");
                }}
                className="bg-reghda-blue text-white  p-2 px-8 rounded-xl x-sm:text-xs md:text-base 2xl:text-4xl">
                أضف كتاب
              </button>
            </div>
          </div>
        </div>
        <div className="x-sm:p-4 md:p-8 x-sm:w-full md:w-11/12 h-full grid x-sm:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-8 justify-around">
          {/* {!data && <LoaderModal modalIsOpen={true}></LoaderModal>} */}
          {data &&
            data.results.map((book) => (
              <BookCard
                deleteOne={async () => {
                  setLoading(true);
                  let res = await deleteBook(book.id);
                  if (res && res.status === 204) {
                    mutate(`https://${domain}/books/books/`);
                  } else {
                    alert("retry! something wrong");
                  }
                  setLoading(false);
                }}
                detail={book}></BookCard>
            ))}
          {/* <ProductCard></ProductCard>
          <ProductCard></ProductCard>
          <ProductCard></ProductCard>
          */}
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default Books;
