import React, { useState } from "react";
import Modal from "react-modal";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../../../services/fetcher";

import { domain } from "./../../../../../global/domain";
import { addCategory, deleteCategory } from "../../../../../services/blogs";

import { IconContext } from "react-icons";
import { AiOutlineClose } from "react-icons/ai";
import LoaderModal from "./../LoaderModal";
import { useWindowWidth } from "@react-hook/window-size/throttled";

const CategoryModal = ({ isOpen, closing }) => {
  const screenWidth = useWindowWidth();

  const [newCategory, setNewCategory] = useState("");
  const [loading, setLoading] = useState(false);

  const { data, error } = useSWR(`https://${domain}/blog/categories/`, fetcher);

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: screenWidth >= 768 ? "40%" : "80%",
        },
      }}>
      <div class="flex flex-row justify-end pb-3">
        <div
          onClick={closing}
          class="self-end cursor-pointer z-50 text-reghda-blue">
          <svg
            class="fill-current text-black"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18">
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>
      </div>
      <div className="flex x-sm:flex-col-reverse x-sm:mb-4 md:mb-0 md:flex-row justify-between md:space-x-10 2xl:space-x-16 items-center md:mr-10 2xl:mr-20">
        <button
          onClick={async () => {
            if (newCategory === "") {
              return;
            }
            let res = await addCategory({ title: newCategory });
            if (res && res.status === 201) {
              mutate(`https://${domain}/blog/categories/`);
            } else {
              alert("retry! something wrong");
            }
            setNewCategory("");
          }}
          className="bg-reghda-blue flex flex-row items-center text-white py-1 px-2 rounded-xl x-sm:text-xs md:text-base 2xl:text-3xl">
          <span>أضف صنف</span>
        </button>
        <input
          onChange={(event) => setNewCategory(event.target.value)}
          value={newCategory}
          type="text"
          class="x-sm:w-full x-sm:mb-4 md:mb-0 md:w-1/2 text-reghda-black text-right p-1 pr-6 rounded-2xl border-2 border-gray-200 outline-none focus:outline-none  focus:border-pink-300"
        />
      </div>
      <div className="h-72 2xl:h-80 md:mt-4 2xl:mt-8">
        <div className="flex flex-col overflow-y-auto items-center justify-center x-sm:space-y-3 md:space-y-6 2xl:space-y-12 x-sm:text-xs md:text-base 2xl:text-xl">
          {data &&
            data.map((category) => (
              <div className="flex flex-row items-center x-sm:w-full md:w-1/2 text-right">
                <div
                  onClick={async () => {
                    setLoading(true);

                    let res = await deleteCategory(category.id);
                    if (res && res.status === 204) {
                      mutate(`https://${domain}/blog/categories/`);
                    } else {
                      alert("retry! something wrong");
                    }
                    setLoading(false);
                  }}
                  className="cursor-pointer w-1/4 p-0">
                  <IconContext.Provider
                    value={{ size: "1em", style: { color: "#e53e3e" } }}>
                    <AiOutlineClose></AiOutlineClose>
                  </IconContext.Provider>
                </div>
                <div className="w-3/4">
                  <p className="w-full">{category.title}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </Modal>
  );
};

export default CategoryModal;
