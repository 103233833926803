import Axios from "axios";
import { domain } from "./../global/domain";

export async function getTimes(date, category) {
  try {
    const response = await Axios.get(
      `https://${domain}/appointments/times/available?date=${date}&type=${category}`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function getTimesWithDuration(date, duration) {
  try {
    const response = await Axios.get(
      `https://${domain}/appointments/times/available?date=${date}&duration=${duration}`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
