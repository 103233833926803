import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./schedule.css";

import Select from "react-select";
import { iconsColor } from "./../../../../../global/iconsColor";

import LoaderModal from "../LoaderModal";

import useSWR, { mutate } from "swr";
import { domain } from "./../../../../../global/domain";
import { fetcher } from "./../../../../../services/fetcher";

import { addAppointment } from "./../../../../../services/appointments";
import { getTimes } from "../../../../../services/times";

const Schedule = ({ closing, mutate }) => {
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState(1);

  const [formState, setFormState] = useState({
    category: null,
    date: null,
    time: null,
    isAvailable: "true",
    meetLink: null,
  });

  const [currentCategoryName, setCurrentCategoryName] = useState(null);

  const { data, error } = useSWR(
    `https://${domain}/appointments/types`,
    fetcher
  );

  const [times, setTimes] = useState([]);

  var arabicDays = [
    "اﻷحد",
    "اﻷثنين",
    "الثلاثاء",
    "اﻷربعاء",
    "الخميس",
    "الجمعة",
    "السبت",
  ];

  var ArabicMonths = [
    "يناير",
    "فبراير",
    "مارس",
    "أبريل",
    "مايو",
    "يونيو",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];

  const formatedDate = (date) => {
    if (!date) {
      return;
    }
    const offset = date.getTimezoneOffset();
    const yourDate = new Date(date.getTime() - offset * 60 * 1000);
    return yourDate.toISOString().split("T")[0];
  };

  return (
    <div className="w-full x-sm:text-xs md:text-base xl:text-lg 2xl:text-3xl">
      {stage === 1 && (
        <div className="w-full flex flex-col space-y-4 text-reghda-black x-sm:mt-12 sm:mt-16 md:mt-20 2xl:mt-36">
          <fieldset className="w-full">
            <Select
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  color: state.isFocused ? "white" : "bg-reghda-black",
                  backgroundColor: state.isFocused ? iconsColor : "white",
                }),
                control: (base, state) => ({
                  ...base,
                  "&:hover": { borderColor: iconsColor }, // border style on hover
                  border: "1px solid lightgray", // default border color
                  boxShadow: "none", // no box-shadow
                }),
              }}
              classNamePrefix="place-holder-center text-center outline-none focus:outline-none"
              className="place-holder-center text-center outline-none focus:outline-none"
              placeholder={"اختر صنف الجلسة"}
              onChange={(selectedOption) => {
                setFormState({
                  ...formState,
                  category: selectedOption.value,
                });
                setCurrentCategoryName(selectedOption.label);
              }}
              options={data.map((category) => {
                return { label: category.title, value: category.id };
              })}
            />
          </fieldset>
          <button
            onClick={() => {
              if (formState.category) {
                setStage(stage + 1);
              }
            }}
            className="w-full bg-reghda-blue text-center text-white py-1 px-2 rounded-xl md:text-base 2xl:text-3xl outline-none focus:outline-none">
            التالي
          </button>
          {stage !== 1 && (
            <button
              onClick={() => setStage(stage - 1)}
              className="w-full bg-reghda-blue text-center text-white py-1 px-2 rounded-xl md:text-base 2xl:text-3xl outline-none focus:outline-none">
              العودة
            </button>
          )}
        </div>
      )}

      {stage === 2 && (
        <div className="x-sm:w-full flex flex-col items-center self-center text-reghda-black">
          <div className="x-sm:w-full">
            <Calendar
              minDate={new Date()}
              calendarType="Arabic"
              locale="ar-AE"
              onChange={(value) => {
                setFormState({
                  ...formState,
                  date: value,
                });
              }}
              value={formState.date}
            />
          </div>
          <div className="w-full flex flex-col space-y-4">
            <button
              onClick={async () => {
                if (formState.date && formState.category) {
                  setLoading(true);
                  const res = await getTimes(
                    formatedDate(formState.date),
                    formState.category
                  );
                  setTimes([...res.data.available_times]);
                  setLoading(false);
                  setStage(stage + 1);
                }
              }}
              className="w-full bg-reghda-blue text-center text-white py-1 px-2 rounded-xl md:text-base 2xl:text-3xl outline-none focus:outline-none">
              التالي
            </button>
            {stage !== 1 && (
              <button
                onClick={() => setStage(stage - 1)}
                className="w-full bg-reghda-blue text-center text-white py-1 px-2 rounded-xl md:text-base 2xl:text-3xl outline-none focus:outline-none">
                العودة
              </button>
            )}
          </div>
        </div>
      )}

      {stage === 3 && times.length > 0 && (
        <div className="w-full ">
          <div className="w-full flex flex-col space-y-4 text-reghda-black x-sm:mt-12 sm:mt-16 md:mt-20 2xl:mt-36">
            <fieldset className="w-full">
              <Select
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    color: state.isFocused ? "white" : "bg-reghda-black",
                    backgroundColor: state.isFocused ? iconsColor : "white",
                  }),
                  control: (base, state) => ({
                    ...base,
                    "&:hover": { borderColor: iconsColor }, // border style on hover
                    border: "1px solid lightgray", // default border color
                    boxShadow: "none", // no box-shadow
                  }),
                }}
                classNamePrefix="place-holder-center text-center outline-none focus:outline-none"
                className="place-holder-center text-center outline-none focus:outline-none"
                placeholder={"اختر وقت"}
                onChange={(selectedOption) => {
                  setFormState({ ...formState, time: selectedOption.value });
                }}
                options={times.map((time) => {
                  return { label: time, value: time };
                })}
              />
            </fieldset>
            <button
              onClick={async () => {
                if (formState.time) {
                  setStage(stage + 1);
                }
              }}
              className="w-full bg-reghda-blue text-center text-white py-1 px-2 rounded-xl md:text-base 2xl:text-3xl outline-none focus:outline-none">
              التالي
            </button>
            {stage !== 1 && (
              <button
                onClick={() => setStage(stage - 1)}
                className="w-full bg-reghda-blue text-center text-white py-1 px-2 rounded-xl md:text-base 2xl:text-3xl outline-none focus:outline-none">
                العودة
              </button>
            )}
          </div>
        </div>
      )}

      {stage === 3 && times.length < 1 && (
        <div className="w-full ">
          <div className="w-full flex flex-col space-y-4 text-reghda-black x-sm:mt-12 sm:mt-16 md:mt-20 2xl:mt-36">
            <p className="w-full text-center text-red-500 2xl:text-2xl">
              هذا اليوم تم حجزه بالكامل
            </p>
            {stage !== 1 && (
              <button
                onClick={() => setStage(stage - 1)}
                className="w-full bg-reghda-blue text-center text-white py-1 px-2 rounded-xl md:text-base 2xl:text-3xl outline-none focus:outline-none">
                العودة
              </button>
            )}
          </div>
        </div>
      )}

      {stage === 4 && (
        <div className="w-full">
          <div className="w-full flex flex-col space-y-4 text-reghda-black x-sm:mt-12 sm:mt-16 md:mt-20 2xl:mt-36">
            <div className="w-full flex flex-row items-center justify-center space-x-2 2xl:text-2xl">
              <span>{formState.date.toString().slice(11, 15)}</span>
              <span>{ArabicMonths[formState.date.getMonth()]}</span>
              <span>{formState.date.toString().slice(8, 10)}</span>
              <span>{arabicDays[formState.date.getDay()]}</span>
            </div>
            <div className="w-full flex flex-row items-center justify-center space-x-4">
              <span>{formState.time}</span>
              <span className="font-extrabold text-reghda-blue">التوقيت</span>
            </div>
            <div className="w-full flex flex-row items-center justify-center space-x-4">
              <span>{currentCategoryName}</span>
              <span className="font-extrabold text-reghda-blue">الصنف</span>
            </div>

            <fieldset className="w-full flex flex-row items-center justify-center">
              <input
                onChange={(event) =>
                  setFormState({ ...formState, meetLink: event.target.value })
                }
                value={formState.meetLink}
                placeholder="رابط الملتقى"
                type="text"
                className="w-1/2 text-reghda-black text-right p-1 pr-6 rounded-2xl border-2 border-gray-200 outline-none focus:outline-none  focus:border-pink-300"
              />
            </fieldset>

            <button
              onClick={async () => {
                if (!formState.meetLink) {
                  return;
                }
                setLoading(true);
                const res = await addAppointment({
                  type: formState.category,
                  date: formatedDate(formState.date),
                  time: formState.time,
                  is_available: formState.isAvailable,
                  meet_url: formState.meetLink,
                });
                setLoading(false);
                mutate();
                closing();
                setFormState({
                  category: null,
                  date: null,
                  time: null,
                  isAvailable: "true",
                });
                setStage(1);
              }}
              className="w-full bg-reghda-blue text-center text-white py-1 px-2 rounded-xl md:text-base 2xl:text-3xl outline-none focus:outline-none">
              تأكيد
            </button>
            {stage !== 1 && (
              <button
                onClick={() => setStage(stage - 1)}
                className="w-full bg-reghda-blue text-center text-white py-1 px-2 rounded-xl md:text-base 2xl:text-3xl outline-none focus:outline-none">
                العودة
              </button>
            )}
          </div>
        </div>
      )}

      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default Schedule;
