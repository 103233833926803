import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { updateProfile } from "../../../../../services/profile";
import LoaderModal from "./../LoaderModal";
import { getTheUserFromStorage } from "./../../../../../services/auth";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../../../services/fetcher";
import { domain } from "./../../../../../global/domain";

const ProfileForm = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState(null);
  const validationSchema = Yup.object({
    givenName: Yup.string().required("هذا الحقل مطلوب"),
    familyName: Yup.string().required("هذا الحقل مطلوب"),
  });

  const { data, error } = useSWR(
    `https://${domain}/users/profile/${getTheUserFromStorage().user_id}`,
    fetcher
  );

  useEffect(() => {
    data && setImage(data.User.image);
  }, [data]);

  return (
    <>
      {data && (
        <Formik
          initialValues={{
            givenName: data.User.given_name,
            familyName: data.User.family_name,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            console.log(values, "👀");
            setLoading(true);
            const formData = new FormData();
            formData.append(`given_name`, values.givenName);
            formData.append(`family_name`, values.familyName);
            if (image && typeof image !== "string") {
              formData.append(`image`, image);
            }
            let res = await updateProfile(
              getTheUserFromStorage().user_id,
              formData
            );
            if (res.status === 200) {
              setLoading(false);
              mutate(
                `https://ragda.site/users/profile/${
                  getTheUserFromStorage().user_id
                }`
              );
            } else {
              setLoading(false);
              alert("something wrong,retry");
            }
            setSubmitting(false);
          }}>
          <Form className="flex flex-col x-sm:space-y-6 md:space-y-6 2xl:space-y-12 text-right x-sm:text-xs  sm:text-sm md:text-xl 2xl:text-4xl">
            <fieldset className="flex flex-row items-center justify-between space-x-0">
              <Field
                name="givenName"
                type="text"
                defaultValue={data.User.given_name}
                class="bg-reghda-blue x-sm:w-full md:w-2/4 md:ml-44 text-white text-right p-2 pr-6 rounded-2xl shadow-lg outline-none focus:outline-none  focus:border-pink-300"
              />
              <label htmlFor="name" className="pl-4">
                الأسم
              </label>
            </fieldset>
            <div className="text-center text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="givenName" />
            </div>
            <fieldset className="flex flex-row items-center justify-between space-x-0">
              <Field
                name="familyName"
                defaultValue={data.User.family_name}
                type="text"
                class="bg-reghda-blue x-sm:w-full md:w-2/4 md:ml-44 text-white text-right p-2 pr-6 rounded-2xl shadow-lg outline-none focus:outline-none  focus:border-pink-300"
              />
              <label htmlFor="name" className="pl-4">
                اللقب
              </label>
            </fieldset>
            <div className="text-center text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="familyName" />
            </div>
            <fieldset className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center justify-around space-x-5">
                <div>
                  <input
                    onChange={(event) => {
                      if (event.target.files[0]) {
                        setImage(event.target.files[0]);
                      }
                    }}
                    id="upload-img"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <div
                    onClick={() => {
                      document.querySelector("#upload-img").click();
                    }}
                    className="md:ml-44 text-reghda-blue text-right cursor-pointer">
                    تحميل صورة جديدة
                  </div>
                </div>
                <img
                  className="rounded-full x-sm:w-12 x-sm:h-12  md:w-20 md:h-20 2xl:w-28 2xl:h-28 picfit"
                  alt="logo"
                  src={
                    image
                      ? typeof image === "string"
                        ? image
                        : URL.createObjectURL(image)
                      : "/images/admin/default-user.png"
                  }
                />
              </div>
              <label htmlFor="upload-img">الصورة الشخصية</label>
            </fieldset>
            <div className="">
              <button
                type="submit"
                className="bg-reghda-black float-left text-white py-2 px-4 rounded-xl x-sm:text-sm md:text-base 2xl:text-3xl">
                حفظ
              </button>
            </div>
            <LoaderModal modalIsOpen={loading}></LoaderModal>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default ProfileForm;
