import Axios from "axios";
import { domain } from "./../global/domain";

export async function enroll(data) {
  try {
    const response = await Axios.post(
      `https://${domain}/students/enroll`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function disEnroll(data) {
  try {
    const response = await Axios.post(
      `https://${domain}/students/disenroll`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
