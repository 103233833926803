import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { fetcher, fetcherAuth } from "../../../../services/fetcher";
import { domain } from "../../../../global/domain";
import { CircularProgress, MenuItem, Select } from "@material-ui/core";
import { getTheTokenFromStorage } from "../../../../services/auth";
import AllReferrers from "../content/affiliate/AllReferrers";
import AddReferrer from "../content/affiliate/AddReferrer";

const Affiliate = () => {
  const token = getTheTokenFromStorage();

  const [addReferrer, setAddReferrer] = useState(false);
  const [loading, setLoading] = useState(false);

  //Query Referrers
  const { data, error, mutate } = useSWR(
    [`https://${domain}/affiliate/referrers/`, token],
    fetcherAuth
  );

  if (!data)
    return (
      <div className="min-h-screen h-full flex flex-col overflow-y-auto base-clamp">
        <div className="p-8 text-right">
          <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
            تسويق بالعمولة
          </h3>
        </div>
        <div className="x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-10">
          <div className="h-48 flex justify-center items-center w-full bg-white rounded-lg">
            <CircularProgress />
          </div>
        </div>
      </div>
    );

  if (data)
    return (
      <div className="min-h-screen h-full flex flex-col overflow-y-auto base-clamp rtl">
        <div className="p-8 text-right">
          <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
            تسويق بالعمولة
          </h3>
        </div>

        <div className="x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-2">
          <div className="w-full bg-white rounded-md p-4">
            <AllReferrers data={data} mutate={mutate} />
          </div>
          <button
            onClick={() => setAddReferrer(!addReferrer)}
            className="bg-reghda-blue text-white px-4 py-2 rounded-md">
            أضف مسوق
          </button>
          {addReferrer && (
            <AddReferrer
              closeAddReferrer={() => setAddReferrer(false)}
              mutate={mutate}
            />
          )}
        </div>
      </div>
    );
};

export default Affiliate;
