import React from "react";

const DashboardContainer = ({ children }) => {
  return (
    <div className="adminDashboard flex flex-col h-full text-reghda-black bg-reghda-light-blue ">
      <div className="flex x-sm:flex-col-reverse md:flex-row flex-1 ">
        {children}
      </div>
    </div>
  );
};

export default DashboardContainer;
