import React, { useState } from "react";
import { IconContext } from "react-icons";
import { AiOutlinePlus, AiOutlineClose, AiFillEdit } from "react-icons/ai";
import {
  AiFillVideoCamera,
  AiFillFileAdd,
  AiFillAudio,
  AiFillPicture,
  AiOutlineArrowUp,
  AiOutlineArrowDown,
} from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";

import Modal from "react-modal";
import MediaCenter from "./MediaCenter";
// import ContentModal from "./ContentModal";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import { iconsColor } from "./../../../../../global/iconsColor";
import ModalDeleteLesson from "./ModalDeleteLesson";

const LessonSection = ({
  order,
  title,
  children,
  addItem,
  deleteLesson,
  updateLesson,
  up,
  down,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [chooseOpen, setChooseOpen] = useState(true);
  const [desktopUploadOpen, setDesktopUploadOpen] = useState(false);
  const [youtubeUploadOpen, setYoutubeUploadOpen] = useState(false);
  const [mediaUploadOpen, setMediaUploadOpen] = useState(false);
  const [youtubeUrl, SetYoutubeUrl] = useState("");
  const screenWidth = useWindowWidth();

  const [openModal, setOpenModal] = useState(false);

  const [editTitle, setEditTitle] = useState(false);
  const [inputTitle, setInputTitle] = useState(title);

  return (
    <>
      <div className="flex flex-col">
        <header className="bg-reghda-black text-white p-2 rounded-2xl flex flex-row items-center justify-between">
          <div className="space-x-5">
            <button
              onClick={() => setOpenModal(true)}
              className="bg-white float-left text-reghda-black py-1 px-1 rounded-xl x-sm:text-xs md:text-base 2xl:text-3xl"
            >
              <IconContext.Provider
                value={{ size: "1.5em", style: { color: "#5b5b5b" } }}
              >
                <AiOutlineClose></AiOutlineClose>
              </IconContext.Provider>
            </button>
            <button
              onClick={() => {
                setIsOpen(true);
              }}
              className="bg-white float-left text-reghda-black py-1 px-1 rounded-xl x-sm:text-xs md:text-base 2xl:text-3xl"
            >
              <IconContext.Provider
                value={{ size: "1.5em", style: { color: "#5b5b5b" } }}
              >
                <AiOutlinePlus></AiOutlinePlus>
              </IconContext.Provider>
            </button>
            <button
              onClick={() => {
                setEditTitle(!editTitle);
              }}
              className="bg-white float-left text-reghda-black py-1 px-1 rounded-xl x-sm:text-xs md:text-base 2xl:text-3xl"
            >
              <IconContext.Provider
                value={{ size: "1.5em", style: { color: "#5b5b5b" } }}
              >
                <AiFillEdit></AiFillEdit>
              </IconContext.Provider>
            </button>
            <button
              onClick={() => {
                up();
              }}
              className="bg-white float-left text-reghda-black py-1 px-1 rounded-xl x-sm:text-xs md:text-base 2xl:text-3xl"
            >
              <IconContext.Provider
                value={{ size: "1.5em", style: { color: "#5b5b5b" } }}
              >
                <AiOutlineArrowUp></AiOutlineArrowUp>
              </IconContext.Provider>
            </button>
            <button
              onClick={() => {
                down();
              }}
              className="bg-white float-left text-reghda-black py-1 px-1 rounded-xl x-sm:text-xs md:text-base 2xl:text-3xl"
            >
              <IconContext.Provider
                value={{ size: "1.5em", style: { color: "#5b5b5b" } }}
              >
                <AiOutlineArrowDown></AiOutlineArrowDown>
              </IconContext.Provider>
            </button>
          </div>
          {!editTitle && <p className="w-2/3">{title}</p>}
          {editTitle && (
            <div className="flex flex-row items-center justify-center space-x-2">
              <button
                onClick={() => {
                  if (inputTitle.length > 0) {
                    updateLesson(inputTitle);
                    setInputTitle("");
                    setEditTitle(false);
                    return;
                  }
                  alert("يجب عليك إدخل إسم الدرس");
                }}
                className="bg-reghda-blue float-right flex flex-row items-center space-x-2 text-white py-2 px-4 rounded-xl x-sm:text-xxs sm:text-xs md:text-base 2xl:text-3xl"
              >
                <span>حفظ</span>
              </button>
              <input
                onChange={(event) => setInputTitle(event.target.value)}
                defaultValue={inputTitle}
                type="text"
                class="w-1/3 text-reghda-black text-right p-1 pr-6 rounded-2xl border-2 border-gray-200 outline-none focus:outline-none  focus:border-pink-300"
              />
            </div>
          )}
        </header>
        <section className="flex flex-col">
          {children}
          {/* <Item number={1}></Item>
        <Item number={2}></Item> */}
        </section>
        {/* <ContentModal
        isOpen={modalIsOpen}
        closing={() => setIsOpen(false)}
      ></ContentModal> */}
        <Modal
          isOpen={modalIsOpen}
          style={{
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: screenWidth >= 768 ? "40%" : "80%",
            },
          }}
        >
          <div class="mb-16">
            {!chooseOpen && (
              <div
                onClick={() => {
                  setChooseOpen(true);
                  setYoutubeUploadOpen(false);
                  setDesktopUploadOpen(false);
                  setMediaUploadOpen(false);
                }}
                class="float-left cursor-pointer z-50 text-reghda-blue"
              >
                <IconContext.Provider
                  value={{ size: "1.3em", style: { color: "#000" } }}
                >
                  <BiArrowBack></BiArrowBack>
                </IconContext.Provider>
              </div>
            )}
            <div
              onClick={() => {
                setIsOpen(false);
                setChooseOpen(true);
                setYoutubeUploadOpen(false);
                setDesktopUploadOpen(false);
                setMediaUploadOpen(false);
              }}
              class="float-right cursor-pointer z-50 text-reghda-blue"
            >
              <svg
                class="fill-current text-black"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
              </svg>
            </div>
          </div>
          <div className="flex flex-col items-center x-sm:space-y-5 md:space-y-10 2xl:space-y-20 2xl:m-28">
            <p className="text-reghda-blue text-center md:text-3xl 2xl:text-6xl">
              أضافة محتوى
            </p>
            {chooseOpen && (
              <div className="flex x-sm:flex-col md:flex-row justify-between x-sm:text-xs sm:text-sm md:text-base 2xl:text-3xl x-sm:space-y-5 md:space-y-0 md:space-x-5 2xl:space-x-12 md:space-x-10 2xl:space-x-16 items-center md:mr-10 2xl:mr-20">
                <div
                  onClick={() => {
                    setChooseOpen(false);
                    setYoutubeUploadOpen(true);
                  }}
                  className="border-2 border-reghda-blue rounded-lg text-reghda-blue p-4 text-center cursor-pointer"
                >
                  <span>ارفع رابط</span>
                </div>
                <div
                  onClick={() => {
                    setChooseOpen(false);
                    setDesktopUploadOpen(true);
                  }}
                  className="border-2 border-reghda-blue rounded-lg text-reghda-blue p-4 text-center cursor-pointer"
                >
                  <span>ارفع من الحاسوب</span>
                </div>
                <div
                  onClick={() => {
                    setChooseOpen(false);
                    setMediaUploadOpen(true);
                  }}
                  className="border-2 border-reghda-blue rounded-lg text-reghda-blue p-4 text-center cursor-pointer"
                >
                  <span>ارفع من مكتبتك</span>
                </div>
              </div>
            )}
            {youtubeUploadOpen && (
              <div className="flex x-sm:flex-col-reverse md:flex-row justify-between md:space-x-10 2xl:space-x-16 items-center md:mr-10 2xl:mr-20">
                <button
                  onClick={(e) => {
                    if (youtubeUrl.length > 0) {
                      addItem({
                        from: "youtube",
                        url: youtubeUrl,
                        type: "video",
                      });
                      SetYoutubeUrl("");
                      setIsOpen(false);
                      setChooseOpen(true);
                      setYoutubeUploadOpen(false);
                      setDesktopUploadOpen(false);
                      setMediaUploadOpen(false);
                    }
                  }}
                  className="bg-reghda-blue flex flex-row items-center text-white py-1 px-2 rounded-xl x-sm:text-xs md:text-base 2xl:text-3xl"
                >
                  <span>أضف الرابط</span>
                </button>
                <input
                  onChange={(event) => SetYoutubeUrl(event.target.value)}
                  value={youtubeUrl}
                  type="text"
                  class="x-sm:w-full x-sm:mb-6 md:mb-0 md:w-1/2 text-reghda-black text-right p-1 pr-6 rounded-2xl border-2 border-gray-200 outline-none focus:outline-none  focus:border-pink-300"
                />
              </div>
            )}
            {desktopUploadOpen && (
              <div className="flex x-sm:flex-col md:flex-row justify-between x-sm:text-sm x-sm:space-y-5 md:space-y-0 md:text-lg 2xl:text-3xl md:space-x-5 2xl:space-x-12 items-center md:mr-10 2xl:mr-20">
                <input
                  id="upload-file"
                  type="file"
                  accept=".doc,.docx,.pdf"
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      addItem({
                        from: "desktop",
                        type: "file",
                        file: e.target.files[0],
                      });
                      setIsOpen(false);
                      setChooseOpen(true);
                      setYoutubeUploadOpen(false);
                      setDesktopUploadOpen(false);
                      setMediaUploadOpen(false);
                    }
                  }}
                  style={{ display: "none" }}
                />
                <div
                  onClick={() => {
                    document.querySelector("#upload-file").click();
                  }}
                  className="flex flex-row items-center space-x-2 border-2 border-reghda-blue rounded-lg text-reghda-blue x-sm:p-2 md:p-4 cursor-pointer"
                >
                  <span>مرفق</span>
                  <IconContext.Provider
                    value={{ size: "1em", style: { color: iconsColor } }}
                  >
                    <AiFillFileAdd></AiFillFileAdd>
                  </IconContext.Provider>
                </div>
                <input
                  id="upload-picture"
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      addItem({
                        from: "desktop",
                        type: "image",
                        file: e.target.files[0],
                      });
                      setIsOpen(false);
                      setChooseOpen(true);
                      setYoutubeUploadOpen(false);
                      setDesktopUploadOpen(false);
                      setMediaUploadOpen(false);
                    }
                  }}
                  style={{ display: "none" }}
                />
                <div
                  onClick={() => {
                    document.querySelector("#upload-picture").click();
                  }}
                  className="flex flex-row items-center space-x-2 border-2 border-reghda-blue rounded-lg text-reghda-blue x-sm:p-2 md:p-4 cursor-pointer"
                >
                  <span>صورة</span>
                  <IconContext.Provider
                    value={{ size: "1em", style: { color: iconsColor } }}
                  >
                    <AiFillPicture></AiFillPicture>
                  </IconContext.Provider>
                </div>
                <input
                  id="upload-audio"
                  type="file"
                  accept="audio/*"
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      addItem({
                        from: "desktop",
                        type: "audio",
                        file: e.target.files[0],
                      });
                      setIsOpen(false);
                      setChooseOpen(true);
                      setYoutubeUploadOpen(false);
                      setDesktopUploadOpen(false);
                      setMediaUploadOpen(false);
                    }
                  }}
                  style={{ display: "none" }}
                />
                <div
                  onClick={() => {
                    document.querySelector("#upload-audio").click();
                  }}
                  className="flex flex-row items-center space-x-2 border-2 border-reghda-blue rounded-lg text-reghda-blue x-sm:p-2 md:p-4 cursor-pointer"
                >
                  <span>اوديو</span>
                  <IconContext.Provider
                    value={{ size: "1em", style: { color: iconsColor } }}
                  >
                    <AiFillAudio></AiFillAudio>
                  </IconContext.Provider>
                </div>
                <input
                  id="upload-video"
                  type="file"
                  accept="video/*"
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      addItem({
                        from: "desktop",
                        type: "video",
                        file: e.target.files[0],
                      });
                      setIsOpen(false);
                      setChooseOpen(true);
                      setYoutubeUploadOpen(false);
                      setDesktopUploadOpen(false);
                      setMediaUploadOpen(false);
                    }
                  }}
                  style={{ display: "none" }}
                />
                <div
                  onClick={() => {
                    document.querySelector("#upload-video").click();
                  }}
                  className="flex flex-row items-center space-x-2 border-2 border-reghda-blue rounded-lg text-reghda-blue x-sm:p-2 md:p-4 cursor-pointer"
                >
                  <span>فيديو</span>
                  <IconContext.Provider
                    value={{ size: "1em", style: { color: iconsColor } }}
                  >
                    <AiFillVideoCamera></AiFillVideoCamera>
                  </IconContext.Provider>
                </div>
              </div>
            )}
            {mediaUploadOpen && (
              <div className="">
                <MediaCenter
                  addItem={addItem}
                  setIsOpen={setIsOpen}
                ></MediaCenter>
              </div>
            )}
          </div>
        </Modal>
      </div>
      <ModalDeleteLesson
        open={openModal}
        handleClose={() => setOpenModal(false)}
        deleteLesson={deleteLesson}
      />
    </>
  );
};

export default LessonSection;
