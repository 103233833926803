import React from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useSWR from "swr";
import { domain } from "../../../../global/domain";
import {
  deleteWithdrawRequest,
  editStatusWithdrawRequest,
} from "../../../../services/affiliate";
import { getTheTokenFromStorage } from "../../../../services/auth";
import { fetcherAuth } from "../../../../services/fetcher";
import LoaderModal from "../content/LoaderModal";

const ReferralLinkDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const token = getTheTokenFromStorage();
  const [loading, setLoading] = useState(false);

  //Query Links
  const { data, error, mutate } = useSWR(
    [`https://${domain}/payments/affiliate/withdraw/${id}/`, token],
    fetcherAuth
  );

  const handleDelete = async () => {
    setLoading(true);
    const res = await deleteWithdrawRequest(id, token);
    if (res?.status === 204) {
      history.push("/admin/affiliate/withdraw");
    } else {
      alert(res?.data?.message);
    }
    setLoading(false);
  };

  const handleAccept = async () => {
    setLoading(true);
    const dataSent = {
      withdraw: id,
      status: "مقبول",
    };
    const res = await editStatusWithdrawRequest(dataSent, token);

    if (res?.status === 200) {
      mutate();
    } else {
      alert(res?.data?.message);
    }
    setLoading(false);
  };

  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto base-clamp rtl">
      <div className="p-8 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
          تفاصيل طلب الانسحاب{" "}
        </h3>
      </div>

      <div className="x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-2">
        <div className="bg-white w-full rounded-lg p-4 md:p-8 2xl:p-16 flex flex-col space-y-4 md:space-y-8 2xl:space-y-16">
          <fieldset className="p-4 border rounded-md ">
            <legend className="px-2">معلومات المستخدم</legend>
            <div className="space-y-2">
              <div className="flex gap-2">
                <p className="w-32"> اسم المستخدم</p>
                <p>{data?.user?.username}</p>
              </div>
              <div className="flex gap-2">
                <p className="w-32">الاسم الكامل</p>
                <p>
                  {data?.user?.family_name} {data?.user?.given_name}
                </p>
              </div>
            </div>
          </fieldset>
          <fieldset className="p-4 border rounded-md ">
            <legend className="px-2"> معلومات عن خيار السحب</legend>
            <div className="space-y-2">
              <div className="flex gap-2">
                <p className="w-32"> إسم الحساب</p>
                <p>{data?.withdraw_option?.account_name}</p>
              </div>
              <div className="flex gap-2">
                <p className="w-32">نوع الحساب</p>
                <p>{data?.withdraw_option?.account_type}</p>
              </div>
              <div className="flex gap-2">
                <p className="w-32">رقم حساب</p>
                <p>{data?.withdraw_option?.account_number}</p>
              </div>
            </div>
          </fieldset>
          <fieldset className="p-4 border rounded-md ">
            <legend className="px-2"> معلومات عن السحب</legend>
            <div className="space-y-2">
              <div className="flex gap-2">
                <p className="w-32">مبلغ</p>
                <p>{data?.amount}</p>
              </div>
              <div className="flex gap-2">
                <p className="w-32"> الحالة</p>
                <p>{data?.status}</p>
              </div>
              <div className="flex gap-2">
                <p className="w-32"> تاريخ الإنشاء</p>
                <p>{data?.created_at?.slice(0, 10)}</p>
              </div>
            </div>
          </fieldset>
          <div className="flex justify-end gap-2">
            <button
              className="bg-red-600 px-4 py-2 rounded-md text-white"
              onClick={handleDelete}>
              حذف
            </button>
            <button
              className="bg-reghda-blue px-4 py-2 rounded-md text-white"
              onClick={handleAccept}>
              قبول
            </button>
          </div>
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default ReferralLinkDetails;
