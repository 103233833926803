import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { updatePassword } from "../../../../../services/profile";
import LoaderModal from "./../LoaderModal";
import { getTheUserFromStorage } from "./../../../../../services/auth";

const ProfileForm = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const validationSchemaPassword = Yup.object({
    oldPassword: Yup.string().required("هذا الحقل مطلوب"),
    newPassword: Yup.string()
      .required("هذا الحقل مطلوب")
      .min(8, "كلمة المرور قصيرة جدًا - يجب ألا تقل عن 8 أحرف."),
    confirmNewPassword: Yup.string()
      .required("هذا الحقل مطلوب")
      .min(8, "كلمة المرور قصيرة جدًا - يجب ألا تقل عن 8 أحرف.")
      .oneOf([Yup.ref("newPassword"), null], "خطاء في تأكيد كلمة السر الجديدة"),
  });

  return (
    <Formik
      initialValues={{
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      }}
      validationSchema={validationSchemaPassword}
      onSubmit={async (values, { setSubmitting }) => {
        setLoading(true);
        let res = await updatePassword({
          user: getTheUserFromStorage().user_id,
          old_password: values.oldPassword,
          new_password: values.newPassword,
        });
        if (res.status === 200) {
          setLoading(false);
        } else {
          setLoading(false);
          alert("something wrong,retry");
        }
        setSubmitting(false);
      }}
    >
      <Form className="flex flex-col x-sm:space-y-6 md:space-y-6 2xl:space-y-12 text-right x-sm:text-xs  sm:text-sm md:text-xl 2xl:text-4xl">
        <fieldset className="flex flex-row items-center justify-between">
          <Field
            name="oldPassword"
            type="password"
            placeholder=""
            class="bg-reghda-blue x-sm:w-full md:w-2/4 md:ml-44 text-white text-right p-2 pr-6 rounded-2xl shadow-lg outline-none focus:outline-none  focus:border-pink-300"
          />
          <label htmlFor="password">كلمة السر القديمة</label>
        </fieldset>
        <div className="text-center text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
          <ErrorMessage name="oldPassword" />
        </div>
        <fieldset className="flex flex-row items-center justify-between">
          <Field
            name="newPassword"
            type="password"
            placeholder=""
            class="bg-reghda-blue x-sm:w-full md:w-2/4 md:ml-44 text-white text-right p-2 pr-6 rounded-2xl shadow-lg outline-none focus:outline-none  focus:border-pink-300"
          />
          <label htmlFor="password">كلمة السر الجديدة</label>
        </fieldset>
        <div className="text-center text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
          <ErrorMessage name="newPassword" />
        </div>
        <fieldset className="flex flex-row items-center justify-between">
          <Field
            name="confirmNewPassword"
            type="password"
            placeholder=""
            class="bg-reghda-blue x-sm:w-full  md:w-2/4 md:ml-44 text-white text-right p-2 pr-6 rounded-2xl shadow-lg outline-none focus:outline-none  focus:border-pink-300"
          />
          <label htmlFor="confirm-password">تأكيد كلمة السر</label>
        </fieldset>
        <div className="text-center text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
          <ErrorMessage name="confirmNewPassword" />
        </div>
        <div className="">
          <button
            type="submit"
            className="bg-reghda-black float-left text-white py-2 px-4 rounded-xl x-sm:text-sm md:text-base 2xl:text-3xl"
          >
            حفظ
          </button>
        </div>
        <LoaderModal modalIsOpen={loading}></LoaderModal>
      </Form>
    </Formik>
  );
};

export default ProfileForm;
