import Axios from "axios";
import { domain } from "./../global/domain";

export async function addCategory(data) {
  try {
    const response = await Axios.post(
      `https://${domain}/appointments/types/`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function editCategory(data, id) {
  try {
    const response = await Axios.patch(
      `https://${domain}/appointments/types/${id}`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function deleteCategory(id) {
  try {
    const response = await Axios.delete(
      `https://${domain}/appointments/types/${id}`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function addAppointment(data) {
  try {
    const response = await Axios.post(
      `https://${domain}/appointments/sessions/`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function deleteAppointment(id) {
  try {
    const response = await Axios.delete(
      `https://${domain}/appointments/sessions/${id}`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
