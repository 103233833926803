import Axios from "axios";
import { domain } from "./../global/domain";

export async function login(data) {
  // try {
  //   const response = await Axios.post(`https://ragda.site/users/token`, data);
  //   return response;
  // } catch (error) {
  //   let errorObject = JSON.stringify(error.response);
  //   console.log(errorObject, "🎉");
  //   return errorObject;
  // }
  const formData = new FormData();
  formData.append("email", data.email);
  formData.append("password", data.password);
  const res = await fetch(`https://${domain}/users/token`, {
    method: "POST",
    body: formData,
  });
  const jsonData = await res.json();
  const status = await res.status;
  return { status, data: jsonData };
}

export function storeTheUser(data, token, refresh) {
  localStorage.setItem("token", token);
  localStorage.setItem("refresh", refresh);
  localStorage.setItem("user", JSON.stringify(data));
}

export function getTheUserFromStorage() {
  return JSON.parse(localStorage.getItem("user"));
}

export function getTheTokenFromStorage() {
  return localStorage.getItem("token");
}

export function getTheRefresh() {
  return localStorage.getItem("refresh");
}

export function deleteTheUserAndTokenFromStorage() {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh");
  localStorage.removeItem("user");
}
