import React, { useState } from "react";
import LoaderModal from "./content/LoaderModal";

import { IconContext } from "react-icons";
import { AiOutlineDown } from "react-icons/ai";
import { iconsColor } from "./../../../global/iconsColor";
import BlogCard from "./content/blogs/BlogCard";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../services/fetcher";
import { domain } from "./../../../global/domain";
import { useHistory } from "react-router-dom";
import CategoryModal from "./content/blogs/CategoryModal";
import { deleteBlog } from "../../../services/blogs";

import cn from "classnames";

const Blogs = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  const categories = useSWR(`https://${domain}/blog/categories/`, fetcher);

  const [blogsUrl, setBlogsUrl] = useState(`https://${domain}/blog/posts/`);

  const { data, error } = useSWR(blogsUrl, fetcher);
  const [currentCategory, setCurrentCategory] = useState("الكل");

  const [menuOpen, setMenuOpen] = useState(false);
  let menuStyle = cn("relative cursor-pointer", { dropdownBlog: menuOpen });

  let menuItemsStyle = cn(
    "mt-2 w-full text-white rounded-lg p-2 absolute hidden top-8 ",
    {
      dropdownContentBlog: menuOpen,
    }
  );

  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto ">
      <div className="p-8 text-right">
        <p className="md:text-base 2xl:text-2xl">مقالات</p>
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
          عرض المقالات
        </h3>
      </div>
      <div className="x-sm:px-2 md:px-6 2xl:p-12 mb-16 space-y-10">
        <div className="text-reghda-black x-sm:w-full md:w-11/12 flex flex-row items-center justify-center rounded-lg x-sm:p-1 md:p-2 2xl:p-4  font-semibold x-sm:text-sm md:text-lg 2xl:text-4xl">
          <div className="w-1/2 flex flex-row items-center justify-start">
            <div className={`${menuStyle}`}>
              <button
                onClick={() => setMenuOpen(!menuOpen)}
                className="self-start flex flex-row items-center justify-center space-x-2 bg-white text-reghda-blue font-extrabold p-2 md:px-8 rounded-xl x-sm:text-sm md:text-base 2xl:text-4xl outline-none focus:outline-none shadow-xl">
                <span>
                  <IconContext.Provider
                    value={{ size: "1em", style: { color: iconsColor } }}>
                    <AiOutlineDown></AiOutlineDown>
                  </IconContext.Provider>
                </span>
                <span> {currentCategory} </span>
              </button>
              <div className={menuItemsStyle}>
                {categories.data &&
                  [{ id: 0, title: "الكل" }, ...categories.data].map(
                    (category) => (
                      <div
                        onClick={() => {
                          if (category.id === 0) {
                            setBlogsUrl(`https://${domain}/blog/posts/`);
                            setMenuOpen(!menuOpen);
                            setCurrentCategory(category.title);
                            return;
                          }

                          setBlogsUrl(
                            `https://${domain}/blog/posts/?category=${category.id}`
                          );
                          setMenuOpen(!menuOpen);
                          setCurrentCategory(category.title);
                          return;
                        }}
                        className="bg-reghda-blue py-2 flex flex-row space-x-1 items-center justify-center cursor-pointer">
                        <span className="text-sm text-center">
                          {category.title}
                        </span>
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
          <div className="w-1/2 flex flex-row items-center justify-end x-sm:space-x-2 md:space-x-8 2xl:space-x-16">
            <button
              onClick={() => history.push("/admin/add-blog")}
              className="bg-reghda-blue text-white  p-2 md:px-8 rounded-xl x-sm:text-sm md:text-base 2xl:text-4xl outline-none focus:outline-none">
              أضف مقال
            </button>
            <button
              onClick={() => setIsOpen(true)}
              className="bg-reghda-blue text-white  p-2 md:px-8 rounded-xl x-sm:text-sm md:text-base 2xl:text-4xl outline-none focus:outline-none">
              أضف صنف
            </button>
          </div>
        </div>
        <div className="x-sm:w-full md:w-11/12 rounded-lg flex flex-col x-sm:space-y-10 md:space-y-20 2xl:space-y-28 x-sm:p-2 md:p-4 2xl:p-12">
          {data &&
            data.map((blog, i) => (
              <BlogCard
                deleteBlog={async () => {
                  setLoading(true);
                  let res = await deleteBlog(blog.id);
                  if (res && res.status === 204) {
                    mutate(blogsUrl);
                  } else {
                    alert("retry! something wrong");
                  }
                  setLoading(false);
                }}
                blogDetail={blog}></BlogCard>
            ))}
        </div>
      </div>
      <CategoryModal
        isOpen={modalIsOpen}
        closing={() => setIsOpen(false)}></CategoryModal>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default Blogs;
