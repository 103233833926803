import Axios from "axios";
import { domain } from "./../global/domain";

Axios.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

export async function uploadMedia(data, type, config) {
  try {
    const response = await Axios.post(
      `https://${domain}/media-library/${type}/`,
      data,
      config
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function getMedia(type) {
  try {
    const response = await Axios.get(
      `https://${domain}/media-library/${type}/`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function deleteMedia(type, id) {
  try {
    const response = await Axios.delete(
      `https://${domain}/media-library/${type}/${id}/`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
