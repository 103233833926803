import React, { useContext } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, Redirect, useHistory } from "react-router-dom";
import { storeContext } from "./../../global/store";
import jwt from "jwt-decode";
import { login, storeTheUser } from "./../../services/auth";

const Login = () => {
  const { store, setStore } = useContext(storeContext);
  let history = useHistory();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("عنوان البريد الإلكتروني غير صالح")
      .required("هذا الحقل مطلوب"),
    password: Yup.string()
      .required("هذا الحقل مطلوب")
      .min(8, "كلمة المرور قصيرة جدًا - يجب ألا تقل عن 8 أحرف."),
  });

  if (store.isLogged) {
    return <Redirect to="/admin/overview"></Redirect>;
  }

  return (
    <div className="flex flex-row h-screen">
      <div className="hidden md:block bg-indigo-200 h-screen w-1/2 overview"></div>
      <div className="h-screen overflow-y-auto x-sm:w-full md:w-1/2 flex flex-col x-sm:space-y-3 md:space-y-6 2xl:space-y-12 text-right text-reghda-black x-sm:px-6 md:px-12 2xl:px-24 x-sm:text-sm md:text-base 2xl:text-3xl">
        <Link to="/">
          <div className="p-4 flex flex-col items-center">
            <img
              className="flex-1 x-sm:w-20 x-sm:h-20 md:w-20 md:h-20 2xl:w-48 2xl:h-48"
              alt="logo"
              src="/images/bottom_logo.png"
            />
          </div>
        </Link>
        <p className="md:text-2xl 2xl:text-6xl font-extrabold">تسجيل الدخول</p>
        <p className="md:text-xl 2xl:text-5xl font-medium md:leading-relaxed 2xl:leading-relaxed">
          للقيام بتسجيل الدخول, يرجى إدخال البريد الألكتروني و كلمة السر الخاصين
          بك
        </p>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const res = await login(values);
            // console.log(res, "👀");
            if (res && res.status === 200 && res.data) {
              const token = res.data.access;
              const refresh = res.data.refresh;
              const user = jwt(res.data.access);
              if (user.is_staff) {
                setStore({
                  ...store,
                  token,
                  user,
                  isLogged: true,
                });
                storeTheUser(user, token, refresh);
                window.location.reload();
              } else {
                alert("لا يمكن الدخول إلا للعاملين بالموقع");
              }
            } else {
              console.log(res.data.message, "👀");
              alert(res.data.message);
            }
            setSubmitting(false);
          }}
        >
          <Form className="text-white x-sm:space-y-2 md:space-y-4 2xl:space-y-6">
            <Field
              className="bg-pink-200 w-full text-white placeholder-white text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300"
              placeholder="البريد الالكتروني"
              name="email"
              type="email"
            />
            <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="email" />
            </div>
            <Field
              className="bg-pink-200 w-full text-white placeholder-white text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300"
              placeholder="كلمة السر"
              name="password"
              type="password"
            />
            <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="password" />
            </div>
            <button
              className="bg-reghda-black text-pink-300 text-center w-full x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl shadow-lg"
              type="submit"
            >
              تسجيل الدخول
            </button>
          </Form>
        </Formik>
        {/* <Link to="/signup">
          ليس لديك حساب ؟ <span className="text-pink-200">سجل معنا</span>
        </Link> */}
      </div>
    </div>
  );
};

export default Login;
