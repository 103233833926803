import React, { useState } from "react";
import { IconContext } from "react-icons";
import { FaRegFileVideo } from "react-icons/fa";
import { domain } from "./../../../../../global/domain";
import { iconsColor } from "./../../../../../global/iconsColor";

const PreviewVideo = ({ file, deleteOne }) => {
  return (
    <div className="flex flex-col items-center space-y-2 md:text-xs 2xl:text-lg cursor-pointer">
      <div>
        <IconContext.Provider
          value={{ size: "8em", style: { color: iconsColor } }}>
          <FaRegFileVideo></FaRegFileVideo>
        </IconContext.Provider>
        <p className="text-center break-all">
          {file.uploaded_video.replace(`http://${domain}/media/videos/`, "")}
        </p>
      </div>
    </div>
  );
};

export default PreviewVideo;
