import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { storeContext } from "./../global/store";
import ProtectedRoute from "./ProtectedRoute";
import AdminDashboard from "./../components/admin/Dashboard";
// import Signup from "./../components/admin/Signup";
import Login from "./../components/admin/Login";
import ScrollToTop from "./scrollToTop";

const App = () => {
  const { store } = useContext(storeContext);

  return (
    <React.Fragment>
      <Router>
        <ScrollToTop />
        <Switch>
          {/* <Route
            path="/signup"
            exact
            render={(props) => <Signup {...props}></Signup>}
          ></Route> */}
          <Route
            path="/login"
            exact
            render={(props) => <Login overview {...props}></Login>}
          ></Route>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/overview"
            exact
            render={(props) => (
              <AdminDashboard overview {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/normal-courses"
            exact
            render={(props) => (
              <AdminDashboard normalCourses {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/course-subscribers/:id"
            exact
            render={(props) => (
              <AdminDashboard courseSubscribers {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/normal-courses/add-course/:type"
            exact
            render={(props) => (
              <AdminDashboard addCourse {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/edit-course/:type/:id"
            exact
            render={(props) => (
              <AdminDashboard editCourse {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/follow-courses"
            exact
            render={(props) => (
              <AdminDashboard followCourses {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/follow-courses/add-course"
            exact
            render={(props) => (
              <AdminDashboard addCourseFollow {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/follow-courses/edit-course/:id"
            exact
            render={(props) => (
              <AdminDashboard editCourseFollow {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/courses-orders"
            exact
            render={(props) => (
              <AdminDashboard coursesOrders {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/courses-orders/:id"
            exact
            render={(props) => (
              <AdminDashboard courseOrderDetail {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/subscribers"
            exact
            render={(props) => (
              <AdminDashboard subscribers {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/subscribers/:courseId"
            exact
            render={(props) => (
              <AdminDashboard subscribersList {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/subscribers/:courseId/:id"
            exact
            render={(props) => (
              <AdminDashboard subscribersDetail {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/dates"
            exact
            render={(props) => (
              <AdminDashboard dates {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/sessions"
            exact
            render={(props) => (
              <AdminDashboard sessions {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>

          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/blogs"
            exact
            render={(props) => (
              <AdminDashboard blogs {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/add-blog"
            exact
            render={(props) => (
              <AdminDashboard addBlog {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/edit-blog/:id"
            exact
            render={(props) => (
              <AdminDashboard editBlog {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>

          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/media-center"
            exact
            render={(props) => (
              <AdminDashboard mediaCenter {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/media-center/:type"
            exact
            render={(props) => (
              <AdminDashboard mediaCenterType {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/books"
            exact
            render={(props) => (
              <AdminDashboard books {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/books/add-book"
            exact
            render={(props) => (
              <AdminDashboard addBook {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/books/edit-book/:id"
            exact
            render={(props) => (
              <AdminDashboard editBook {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/store"
            exact
            render={(props) => (
              <AdminDashboard store {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/store/add-product"
            exact
            render={(props) => (
              <AdminDashboard addProduct {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/store/edit-product/:id"
            exact
            render={(props) => (
              <AdminDashboard editProduct {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/orders"
            exact
            render={(props) => (
              <AdminDashboard orders {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/orders/:id"
            exact
            render={(props) => (
              <AdminDashboard ordersDetail {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/settings"
            exact
            render={(props) => (
              <AdminDashboard settings {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/who-are-we"
            exact
            render={(props) => (
              <AdminDashboard aboutUs {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/users"
            exact
            render={(props) => (
              <AdminDashboard Users {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/user/:id"
            exact
            render={(props) => (
              <AdminDashboard User {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/certifications"
            exact
            render={(props) => (
              <AdminDashboard certification {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/affiliate"
            exact
            render={(props) => (
              <AdminDashboard affiliate {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/affiliate/campaigns"
            exact
            render={(props) => (
              <AdminDashboard campaigns {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/affiliate/referrers"
            exact
            render={(props) => (
              <AdminDashboard referrers {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/affiliate/referrers/:id"
            exact
            render={(props) => (
              <AdminDashboard referrer {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/affiliate/referral-links"
            exact
            render={(props) => (
              <AdminDashboard referralLinks {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/affiliate/referral-links/:id"
            exact
            render={(props) => (
              <AdminDashboard referralLinkDetails {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/affiliate/balances"
            exact
            render={(props) => (
              <AdminDashboard balances {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/affiliate/withdraw"
            exact
            render={(props) => (
              <AdminDashboard withdrawOptions {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/affiliate/withdraw/:id"
            exact
            render={(props) => (
              <AdminDashboard
                withdrawRequestDetails
                {...props}
              ></AdminDashboard>
            )}
          ></ProtectedRoute>
          <Redirect to="/admin/overview"></Redirect>
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default App;
