import React, { useState } from "react";
import { IconContext } from "react-icons";
import { AiOutlinePlus } from "react-icons/ai";

const AddContent = ({ children, addChapter, clearAll }) => {
  const [title, setTitle] = useState("");

  return (
    <div className="flex flex-col x-sm:space-y-4 md:space-y-6 2xl:space-y-12">
      <div className="flex flex-col x-sm:space-y-10 md:space-y-20 2xl:space-x-32">
        {children}
        {/* <ChapterSection number={1}></ChapterSection>
        <ChapterSection number={2}></ChapterSection>
        <ChapterSection number={3}></ChapterSection> */}
      </div>
      <div className="text-center flex flex-col items-center justify-center space-y-2">
        <div className="flex flex-row items-center justify-end space-x-2">
          <button
            onClick={(e) => {
              if (title.length > 0) {
                addChapter(title);
                setTitle("");
                return;
              }
              alert("يجب عليك إدخل إسم الفصل");
            }}
            className="bg-reghda-blue flex flex-row items-center space-x-2 text-white py-2 px-4 rounded-xl x-sm:text-xxs sm:text-xs md:text-base 2xl:text-3xl"
          >
            <IconContext.Provider
              value={{ size: "1em", style: { color: "#fff" } }}
            >
              <AiOutlinePlus></AiOutlinePlus>
            </IconContext.Provider>
            <span>أضف فصل</span>
          </button>
          <input
            onChange={(event) => setTitle(event.target.value)}
            value={title}
            type="text"
            class="w-1/2 text-reghda-black text-right p-1 pr-6 rounded-2xl border-2 border-gray-200 outline-none focus:outline-none  focus:border-pink-300"
          />
        </div>
        {/* <button
          onClick={clearAll}
          className="bg-pink-200 flex flex-row items-center space-x-2 text-white py-2 px-4 rounded-xl md:text-base 2xl:text-3xl"
        >
          <span>امسح الكل</span>
        </button> */}
      </div>
    </div>
  );
};

export default AddContent;
