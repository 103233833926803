import React, { useEffect, useState } from "react";
import axios from "axios";
import { IconContext } from "react-icons";
import { AiFillYoutube, AiFillInstagram } from "react-icons/ai";
import { FaTelegram } from "react-icons/fa";
import { iconsColor } from "./../../../../../global/iconsColor";

const FourthCardsSection = () => {
  const [youtubeCount, setYoutubeCount] = useState(0);
  const [instaCount, setInstaCount] = useState(0);
  const [telegramCount, setTelegramCount] = useState(0);
  const API_KEY = "AIzaSyARNt5ssMvwzQjIYMWyzTuFC4QP1PHXP9E";
  const ChannelId = "UCaO6VoaYJv4kS-TQO_M-N_g";
  const profileId = "logitechgesport";

  async function telegramSubs(username) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Content-Length": "96",
        Host: "infotelbot.com",
      },
    };
    try {
      const response = await axios.post(
        `https://subcounter.infotelbot.com/info`,
        { username: username },
        config
      );
      setTelegramCount(response);
      return response;
    } catch (error) {
      return { error, isError: true };
    }
  }

  useEffect(async () => {
    // Youtube API Call
    let popcornHeaders = new Headers();
    popcornHeaders.append("cache-control", "no-store");

    fetch(
      `https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${ChannelId}&key=${API_KEY}`,
      {
        credentials: "same-origin",
        headers: popcornHeaders,
      }
    )
      .then((response) => response.json())
      .then(function (response) {
        setYoutubeCount(response.items[0].statistics.subscriberCount);
      });

    // Instagram API CALL

    fetch(`https://counts.live/api/instagram-follower-count/${profileId}/live`)
      .then((response) => response.json())
      .then(function (response) {
        response.data && setInstaCount(response.data.followers);
      });

    // Telegram API Call
    const res = await telegramSubs("karim");
    console.log(res);
  }, []);

  return (
    <div className="x-sm:w-full x-sm:pb-4 md:w-11/12 flex x-sm:flex-col x-sm:space-y-4 md:flex-row md:space-y-0 md:space-x-8 2xl:space-x-16">
      <div className="flex-1 bg-white flex flex-row items-center justify-around font-bold text-reghda-black md:text-2xl 2xl:text-4xl md:p-4 2xl:p-12 rounded-lg shadow-2xl">
        <IconContext.Provider
          value={{ size: "4em", style: { color: iconsColor } }}
        >
          <AiFillYoutube></AiFillYoutube>
        </IconContext.Provider>
        <div className="text-right">
          <p>{youtubeCount}</p>
          <p>مشترك</p>
        </div>
      </div>
      <div className="flex-1 bg-white flex flex-row items-center justify-around font-bold text-reghda-black md:text-2xl 2xl:text-4xl md:p-4 2xl:p-12 rounded-lg shadow-2xl">
        <IconContext.Provider
          value={{ size: "4em", style: { color: iconsColor } }}
        >
          <AiFillInstagram></AiFillInstagram>
        </IconContext.Provider>
        <div className="text-right">
          <p>{instaCount}</p>
          <p>متابع</p>
        </div>
      </div>
      <div className="flex-1 bg-white flex flex-row items-center justify-around font-bold text-reghda-black md:text-2xl 2xl:text-4xl md:p-4 2xl:p-12 rounded-lg shadow-2xl">
        <IconContext.Provider
          value={{ size: "4em", style: { color: iconsColor } }}
        >
          <FaTelegram></FaTelegram>
        </IconContext.Provider>
        <div className="text-right">
          <p>500</p>
          <p>مشترك</p>
        </div>
      </div>
    </div>
  );
};

export default FourthCardsSection;
