import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import Say from "./Say";
import LoaderModal from "../LoaderModal";

import { addSaying, deleteSaying } from "../../../../../services/aboutUs";

import useSWR, { mutate } from "swr";
import { fetcher } from "../../../../../services/fetcher";
import { domain } from "../../../../../global/domain";

const EditAboutUs = () => {
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({ author: "", body: "" });
  const history = useHistory();

  const { data, error } = useSWR(`https://${domain}/about/sayings/`, fetcher);

  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto">
      <div className="p-8 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">ماذا قالوا</h3>
      </div>
      <div className="x-sm:px-2 md:px-6 2xl:p-12 mb-16 space-y-10">
        <div className="bg-white x-sm:w-full md:w-11/12 rounded-lg flex flex-col x-sm:p-2 md:p-4 2xl:p-12">
          <div className="w-full max-h-screen overflow-y-scroll flex flex-col rounded-lg border-b-2 border-reghda-blue">
            {data &&
              data.map((say, i) => (
                <Say
                  deleteSay={async () => {
                    setLoading(true);
                    let res = await deleteSaying(say.id);
                    if (res && res.status === 204) {
                      mutate(`https://${domain}/about/sayings/`);
                    } else {
                      alert("retry! something wrong");
                    }
                    setLoading(false);
                  }}
                  say={say}
                  formState={formState}
                  setFormState={setFormState}
                  setLoading={setLoading}></Say>
              ))}
          </div>
          <div className="x-sm:mt-5 md:mt-10 2xl:mt-16 w-full flex flex-col items-center space-y-4 x-sm:text-sm md:text-base 2xl:text-4xl">
            <fieldset className="w-full flex flex-row items-center justify-center">
              <textarea
                onChange={(event) =>
                  setFormState({
                    ...formState,
                    author: event.target.value,
                  })
                }
                value={formState.author}
                rows="1"
                placeholder="قائل"
                type="text"
                className="w-1/2 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
              />
            </fieldset>
            <fieldset className="w-full flex flex-row items-center justify-center">
              <textarea
                onChange={(event) =>
                  setFormState({
                    ...formState,
                    body: event.target.value,
                  })
                }
                value={formState.body}
                placeholder="مقولة"
                rows="3"
                type="text"
                className="w-1/2 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
              />
            </fieldset>
            <button
              onClick={async () => {
                if (formState.author.length < 1 || formState.body.length < 1) {
                  return;
                }
                setLoading(true);
                let res = await addSaying({
                  author: formState.author,
                  body: formState.body,
                });
                if (res && res.status === 201) {
                  mutate(`https://${domain}/about/sayings/`);
                } else {
                  alert("something wrong!");
                }
                setFormState({
                  author: "",
                  body: "",
                });
                setLoading(false);
              }}
              className="bg-reghda-blue text-white  p-2 px-8 rounded-xl x-sm:text-sm md:text-base 2xl:text-4xl outline-none focus:outline-none">
              أضف مقولة
            </button>
          </div>
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default EditAboutUs;
