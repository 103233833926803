import React, { useState } from "react";
import AddForm from "./content/normal-courses/AddForm";
import { useParams, useHistory } from "react-router-dom";
import LoaderModal from "./content/LoaderModal";
import { addCourse } from "../../../services/course";

const AddCourse = () => {
  const history = useHistory();
  let { type } = useParams();

  const [loading, setLoading] = useState(false);

  const [formState, setFormState] = useState({
    courseTitle: "",
    duration: 0,
    courseDescription: "",
    about: "",
    what_you_get: "",
    courseInformation: "",
    isLocked: "false",
    img: null,
    price: null,
    showLowIncomePrice: "false",
    lowIncomePrice: null,
    courseVideo: "",
    title_1: "",
    title_2: "",
    title_3: "",
    title_4: "",
    low_income_name: "",
  });

  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto">
      <div className="p-8 text-right">
        <p className="md:text-base 2xl:text-2xl">دورات عادية</p>
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">اضافة دورة</h3>
      </div>
      <div className="x-sm:px-2 md:px-6 2xl:p-12 mb-16 space-y-10">
        <div className="bg-white x-sm:w-full md:w-11/12 rounded-lg flex flex-col x-sm:p-2 md:p-4 2xl:p-12">
          <div className="flex flex-row items-center justify-end x-sm:space-x-4 md:space-x-8 2xl:space-x-16 border-b border-gray-200 md:mb-2 md:pb-2 2xl:mb-4 2xl:pb-4 md:text-xl 2xl:text-4xl">
            <div>محتوى</div>
            <div className="text-reghda-blue">معلومات عامة</div>
          </div>
          <div>
            <div className="x-sm:p-4 md:p-8 2xl:p-16">
              <AddForm
                formState={formState}
                setFormState={setFormState}
                paid={type === "paid"}
              ></AddForm>

              <div>
                <button
                  onClick={async (e) => {
                    if (
                      type === "free" &&
                      (formState.courseTitle.length > 0 ||
                        formState.courseDescription.length > 0 ||
                        formState.img)
                    ) {
                      setLoading(true);
                      const formData = new FormData();
                      formData.append(`title`, formState.courseTitle);

                      formData.append(
                        `description`,
                        formState.courseDescription
                      );

                      formData.append(`duration`, formState.duration);

                      if (formState.img) {
                        formData.append(`picture`, formState.img);
                      }

                      let res = await addCourse(formData, type);

                      console.log(res, "🎂");

                      setLoading(false);
                      res &&
                        res.data &&
                        history.push(
                          `/admin/edit-course/${type}/${res.data.id}`
                        );
                    }
                    if (type === "paid") {
                      if (formState.price < 3.75) {
                        alert("الحد الأدنى للسعر هو 3.75  ريال");
                        return;
                      }
                      if (
                        formState.lowIncomePrice &&
                        formState.lowIncomePrice < 3.75
                      ) {
                        alert("الحد الأدنى للسعر هو 3.75  ريال");
                        return;
                      }
                      setLoading(true);
                      const formData = new FormData();
                      formData.append(`title`, formState.courseTitle);
                      formData.append(
                        `description`,
                        formState.courseDescription
                      );
                      formData.append(`duration`, formState.duration);
                      formData.append(`about_course`, formState.about);

                      formData.append(`is_locked`, formState.isLocked);
                      formData.append(`price`, formState.price);

                      if (formState.img) {
                        formData.append(`picture`, formState.img);
                      }

                      if (formState.what_you_get) {
                        formData.append(`what_you_get`, formState.what_you_get);
                      }
                      if (formState.courseInformation) {
                        formData.append(
                          `course_information`,
                          formState.courseInformation
                        );
                      }

                      if (formState.courseVideo) {
                        formData.append(`course_video`, formState.courseVideo);
                      }

                      if (formState.showLowIncomePrice) {
                        formData.append(
                          `show_low_income_price`,
                          formState.showLowIncomePrice
                        );
                      }

                      if (formState.lowIncomePrice) {
                        formData.append(
                          `low_income_price`,
                          formState.lowIncomePrice
                        );
                      }

                      if (formState.title_1) {
                        formData.append(`title_1`, formState.title_1);
                      }

                      if (formState.title_2) {
                        formData.append(`title_2`, formState.title_2);
                      }

                      if (formState.title_3) {
                        formData.append(`title_3`, formState.title_3);
                      }

                      if (formState.title_4) {
                        formData.append(`title_4`, formState.title_4);
                      }

                      if (formState.low_income_name) {
                        formData.append(
                          `low_income_name`,
                          formState.low_income_name
                        );
                      }

                      let res = await addCourse(formData, type);

                      setLoading(false);
                      res &&
                        res.data &&
                        history.push(
                          `/admin/edit-course/${type}/${res.data.id}`
                        );
                    }
                  }}
                  className="bg-reghda-blue float-left text-white py-2 px-4 rounded-xl md:text-base 2xl:text-3xl"
                >
                  التالي
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default AddCourse;
