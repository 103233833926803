import Axios from "axios";
import { domain } from "./../global/domain";

Axios.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

export async function addLessonToServer(data) {
  try {
    const response = await Axios.post(
      `https://${domain}/courses/modules/`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function updateLesson(id, data) {
  try {
    const response = await Axios.patch(
      `https://${domain}/courses/modules/${id}/`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function deleteLessonFromServer(id) {
  try {
    const response = await Axios.delete(
      `https://${domain}/courses/modules/${id}/`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function changeLessonOrder(data) {
  try {
    const response = await Axios.post(
      `https://${domain}/courses/change-order/modules`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
