import React, { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { editorSettings } from "./../../../../../global/editorSettings";

const TextEditorWhatYouGet = ({
  initialContentState,
  formState,
  setFormState,
}) => {
  const editorRef = useRef(null);
  return (
    <>
      <Editor
        apiKey="njtffp9ypeuapm7uz41wuog40ile19a4iunjgdd22gponnng"
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={initialContentState ? initialContentState : ""}
        init={editorSettings}
        onEditorChange={(newValue, editor) => {
          setFormState({
            ...formState,
            what_you_get: newValue,
          });
        }}
      />
    </>
  );
};

export default TextEditorWhatYouGet;
