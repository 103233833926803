import React, { useState } from "react";
import LoaderModal from "./content/LoaderModal";

import { IconContext } from "react-icons";
import { AiOutlineClose } from "react-icons/ai";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../services/fetcher";
import { domain } from "./../../../global/domain";
import { useParams } from "react-router-dom";
import AddSubscriberModal from "./content/course-subscribers/AddSubscriberModal";
import RemoveSubscriberModal from "./content/course-subscribers/RemoveSubscriberModal";

const CourseSubscribers = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [clickedUser, setClickedUser] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalRemove, setModalRemove] = useState(false);
  const [url, setUrl] = useState(`https://${domain}/courses/list/${id}/`);

  const { data, error } = useSWR(url, fetcher);

  const [search, setSearch] = useState(null);

  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto">
      <div className="p-8 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
          المشتركين في الدورة
        </h3>
      </div>
      <div className="x-sm:px-2 md:px-6 2xl:p-12 mb-16 space-y-10">
        <div className="bg-white x-sm:w-full md:w-11/12 rounded-lg x-sm:p-2 md:p-8 2xl:p-16 flex flex-col x-sm:space-y-3 md:space-y-8 2xl:space-y-16">
          <div className="flex flex-row space-x-4 items-center justify-end x-sm:text-sm md:text-lg 2xl:text-3xl">
            <button
              onClick={() => {
                if (!search) {
                  return;
                }
                setUrl(
                  `https://${domain}/courses/list/${id}/?search=${search}`
                );
              }}
              className="bg-reghda-blue text-white py-2 px-4 rounded-xl x-sm:text-xxs sm:text-xs md:text-base 2xl:text-3xl outline-none focus:outline-none">
              بحث
            </button>
            <input
              onChange={(event) => {
                if (event.target.value === "") {
                  setUrl(`https://${domain}/courses/list/${id}/`);
                }
                setSearch(event.target.value);
              }}
              value={search}
              type="text"
              id="search"
              placeholder="أدخل إسم المشترك"
              className="w-1/3 text-reghda-black text-right p-1 pr-6 rounded-2xl border-2 border-gray-200 outline-none focus:outline-none"
            />
          </div>
          <table className="border-collapse w-full text-center x-sm:text-xxs sm:text-sm md:text-lg 2xl:text-3xl">
            <tr>
              {data && data.type !== "free" && (
                <th className="border-2 border-solid border-gray-200 x-sm:p-2 md:p-4">
                  حذف
                </th>
              )}

              <th className="border-2 border-solid border-gray-200 x-sm:p-2 md:p-4">
                البريد الالكتروني
              </th>
              <th className="border-2 border-solid border-gray-200 x-sm:p-2 md:p-4">
                الأسم الكامل
              </th>
              <th className="border-2 border-solid border-gray-200 x-sm:p-2 md:p-4">
                id
              </th>
            </tr>
            {data &&
              data.students.map((student, i) => {
                return (
                  <tr key={i}>
                    {data.type !== "free" && (
                      <td className="border-2 border-solid border-gray-200 x-sm:p-2 md:p-4">
                        <span
                          onClick={() => {
                            setClickedUser(student.id);
                            setModalRemove(true);
                          }}
                          className="text-red-500 flex flex-row items-center justify-center cursor-pointer">
                          <IconContext.Provider
                            value={{
                              size: "1em",
                              style: { color: "#e53e3e" },
                            }}>
                            <AiOutlineClose></AiOutlineClose>
                          </IconContext.Provider>
                        </span>
                      </td>
                    )}
                    <td className="border-2 border-solid border-gray-200 x-sm:p-2 md:p-4">
                      {student.email}
                    </td>
                    <td className="border-2 border-solid border-gray-200 x-sm:p-2 md:p-4">
                      {`${student.given_name} ${student.family_name}`}
                    </td>
                    <td className="border-2 border-solid border-gray-200 x-sm:p-2 md:p-4">
                      {student.id}
                    </td>
                  </tr>
                );
              })}
          </table>
          {data && data.type !== "free" && (
            <div className="flex flex-row items-center justify-end x-sm:text-xs sm:text-sm md:text-base 2xl:text-2xl">
              <button
                onClick={() => setIsOpen(true)}
                className="w-1/4 bg-reghda-blue text-white py-2 px-4 rounded-xl x-sm:text-xxs sm:text-xs md:text-base 2xl:text-3xl outline-none focus:outline-none">
                أضف مشترك
              </button>
            </div>
          )}
          {/* <div className="flex flex-row space-x-4 items-center justify-end x-sm:text-xs sm:text-sm md:text-base 2xl:text-2xl">
            <p className="bg-pink-200 px-2 rounded-l-2xl text-white cursor-pointer">
              السابق
            </p>
            <p className="text-pink-200">1</p>
            <p className="bg-pink-200 px-2 rounded-r-2xl text-white cursor-pointer">
              التالي
            </p>
          </div> */}
        </div>
      </div>
      {data && data.type !== "free" && (
        <AddSubscriberModal
          courseId={id}
          isOpen={modalIsOpen}
          closing={() => setIsOpen(false)}
          mutate={() =>
            mutate(`https://${domain}/courses/list/${id}/`)
          }></AddSubscriberModal>
      )}
      {clickedUser && data && data.type !== "free" && (
        <RemoveSubscriberModal
          userId={clickedUser}
          courseId={id}
          isOpen={modalRemove}
          closing={() => setModalRemove(false)}
          mutate={() =>
            mutate(`https://${domain}/courses/list/${id}/`)
          }></RemoveSubscriberModal>
      )}
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default CourseSubscribers;
