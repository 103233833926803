export const editorSettings = {
  height: 500,
  automatic_uploads: true,
  menubar: true,
  plugins: [
    "advlist autolink lists link image charmap print preview anchor",
    "searchreplace visualblocks code fullscreen",
    "insertdatetime media table paste code help wordcount",
  ],
  toolbar:
    "fontselect | fontsizeselect bold forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat",
  font_formats: `Bahij TheSansArabic Light=Bahij TheSansArabic Light;Almarai=Almarai;Aref Ruqaa=Aref Ruqaa;Cairo=Cairo;Tajawal=Tajawal;Amiri=Amiri;Changa=Changa;El Messiri=El Messiri;Markazi Text=Markazi Text;Reem Kufi=Reem Kufi;Lemonada=Lemonada;Mada=Mada;Lalezar=Lalezar;Scheherazade=Scheherazade;Lateef=Lateef;Harmattan=Harmattan;Kufam=Kufam;Mirza=Mirza;Rakkas=Rakkas;Katibeh=Katibeh;Jomhuria=Jomhuria;Vibes=Vibes;Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats`,
  content_style: `@import url('https://fonts.googleapis.com/css2?family=Almarai&display=swap');@import url('https://fonts.googleapis.com/css2?family=Aref+Ruqaa:wght@700&display=swap');@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200&display=swap');@import url('https://fonts.googleapis.com/css2?family=Tajawal&display=swap');@import url('https://fonts.googleapis.com/css2?family=Amiri:ital@1&display=swap');@import url('https://fonts.googleapis.com/css2?family=Changa&display=swap');@import url('https://fonts.googleapis.com/css2?family=El+Messiri&display=swap');@import url('https://fonts.googleapis.com/css2?family=Markazi+Text&display=swap');@import url('https://fonts.googleapis.com/css2?family=Reem+Kufi&display=swap');@import url('https://fonts.googleapis.com/css2?family=Lemonada&display=swap');@import url('https://fonts.googleapis.com/css2?family=Mada&display=swap');@import url('https://fonts.googleapis.com/css2?family=Lalezar&display=swap');@import url('https://fonts.googleapis.com/css2?family=Scheherazade&display=swap');@import url('https://fonts.googleapis.com/css2?family=Lateef&display=swap');@import url('https://fonts.googleapis.com/css2?family=Harmattan&display=swap');@import url('https://fonts.googleapis.com/css2?family=Kufam&display=swap');@import url('https://fonts.googleapis.com/css2?family=Mirza&display=swap');@import url('https://fonts.googleapis.com/css2?family=Rakkas&display=swap');@import url('https://fonts.googleapis.com/css2?family=Katibeh&display=swap');@import url('https://fonts.googleapis.com/css2?family=Jomhuria&display=swap');@import url('https://fonts.googleapis.com/css2?family=Vibes&display=swap');`,
};
