import React, { useState } from "react";
import Modal from "react-modal";
import PreviewFile from "./../media-center-modal/PreviewFile";
import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../../../services/fetcher";
import { domain } from "./../../../../../global/domain";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import { IconContext } from "react-icons";
import { BiArrowBack } from "react-icons/bi";
import { iconsColor } from "../../../../../global/iconsColor";

const MediaBookModal = ({ isOpen, closing, formState, setFormState }) => {
  const screenWidth = useWindowWidth();
  const [level, setLevel] = useState("choose");
  const [linkUrl, setLinkUrl] = useState("");

  const { data, error } = useSWR(
    `https://${domain}/media-library/books/`,
    fetcher
  );

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: screenWidth >= 768 ? "40%" : "80%",
        },
      }}>
      <div class="flex flex-row justify-end pb-3">
        <div
          onClick={closing}
          class="self-end cursor-pointer z-50 text-reghda-blue">
          <svg
            class="fill-current text-black"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18">
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>
      </div>

      {level === "choose" && (
        <div className="flex x-sm:flex-col md:flex-row items-center justify-center x-sm:text-xs sm:text-sm md:text-base 2xl:text-3xl x-sm:space-y-5 md:space-y-0 md:space-x-5 2xl:space-x-10">
          <div
            onClick={() => {
              setLevel("link");
            }}
            className="border-2 border-reghda-blue rounded-lg text-reghda-blue p-4 text-center cursor-pointer">
            <span>ارفع رابط</span>
          </div>
          <div
            onClick={() => {
              setLevel("media");
            }}
            className="border-2 border-reghda-blue rounded-lg text-reghda-blue p-4 text-center cursor-pointer">
            <span>ارفع من مكتبتك</span>
          </div>
        </div>
      )}

      {level === "link" && (
        <div className="flex x-sm:flex-col-reverse md:flex-row justify-between md:space-x-10 2xl:space-x-16 items-center md:mr-10 2xl:mr-20">
          <div
            onClick={() => {
              setLevel("choose");
            }}
            class="flex flex-col items-center space-y-2 md:text-xs 2xl:text-lg cursor-pointer">
            <IconContext.Provider
              value={{ size: "3em", style: { color: iconsColor } }}>
              <BiArrowBack></BiArrowBack>
            </IconContext.Provider>
          </div>
          <button
            onClick={(e) => {
              if (linkUrl.length > 0) {
                setFormState({ ...formState, book: linkUrl });
                closing();
              }
            }}
            className="bg-reghda-blue flex flex-row items-center text-white py-1 px-2 rounded-xl x-sm:text-xs md:text-base 2xl:text-3xl">
            <span>أضف الرابط</span>
          </button>
          <input
            onChange={(event) => setLinkUrl(event.target.value)}
            value={linkUrl}
            type="text"
            class="x-sm:w-full x-sm:mb-6 md:mb-0 md:w-1/2 text-reghda-black text-right p-1 pr-6 rounded-2xl border-2 border-gray-200 outline-none focus:outline-none  focus:border-pink-300"
          />
        </div>
      )}

      {level === "media" && (
        <div className="x-sm:w-full md:w-11/12 rounded-lg flex flex-row x-sm:p-2 md:p-4 2xl:p-12 x-sm:text-xxs md:text-sm 2xl:text-4xl">
          <div className="grid x-sm:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-16 gap-y-10 justify-around h-72">
            <div
              onClick={() => {
                setLevel("choose");
              }}
              class="flex flex-col items-center space-y-2 md:text-xs 2xl:text-lg cursor-pointer">
              <IconContext.Provider
                value={{ size: "8em", style: { color: iconsColor } }}>
                <BiArrowBack></BiArrowBack>
              </IconContext.Provider>
              <p className="text-center break-words">عودة</p>
            </div>
            {data &&
              data.length > 0 &&
              data.map((file, i) => {
                return (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setFormState({ ...formState, book: file.uploaded_file });
                      closing();
                    }}>
                    <PreviewFile
                      key={i}
                      file={file}
                      name={file.name}></PreviewFile>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default MediaBookModal;
