import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { fetcher, fetcherAuth } from "../../../../services/fetcher";
import { CircularProgress } from "@material-ui/core";
import { getTheTokenFromStorage } from "../../../../services/auth";

import AllLinks from "../content/affiliate/AllLinks";
import AddReferrerLink from "../content/affiliate/AddReferrerLink";

const Affiliate = () => {
  const token = getTheTokenFromStorage();

  const [addLinks, setAddLinks] = useState(false);
  const [loading, setLoading] = useState(false);

  //Query Links
  const {
    data: Links,
    error: errorLinks,
    mutate: mutateLinks,
  } = useSWR([`https://ragda.site/affiliate/links/`, token], fetcherAuth);

  if (!Links)
    return (
      <div className="min-h-screen h-full flex flex-col overflow-y-auto base-clamp">
        <div className="p-8 text-right">
          <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
            تسويق بالعمولة
          </h3>
        </div>
        <div className="x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-10">
          <div className="h-48 flex justify-center items-center w-full rounded-lg">
            <CircularProgress />
          </div>
        </div>
      </div>
    );

  if (Links)
    return (
      <div className="min-h-screen h-full flex flex-col overflow-y-auto base-clamp rtl">
        <div className="p-8 text-right">
          <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
            تسويق بالعمولة
          </h3>
        </div>
        <div className="x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-2">
          <button
            onClick={() => setAddLinks(!addLinks)}
            className="bg-reghda-blue text-white px-4 py-2 rounded-md"
          >
            أضف رابط
          </button>
          {addLinks && (
            <AddReferrerLink
              closeAddLink={() => setAddLinks(false)}
              mutate={mutateLinks}
            />
          )}
          <div className="w-full rounded-md p-4">
            <AllLinks data={Links} mutate={mutateLinks} />
          </div>
        </div>
      </div>
    );
};

export default Affiliate;
