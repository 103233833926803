import React from "react";
import DashboardContainer from "./DashboardContainer";
import ContentContainer from "./pages/ContentContainer";
import Sidebar from "./SideBar";
import Overview from "./pages/Overview";
import NormalCourses from "./pages/NormalCourses";
import AddCourse from "./pages/AddCourse";
import CourseSubscribers from "./pages/CourseSubscribers";
import FollowCourses from "./pages/FollowCourses";
import AddCourseFollow from "./pages/AddCourseFollow";
import EditCourseFollow from "./pages/EditCourseFollow";
import EditCourse from "./pages/EditCourse";
import Subscribers from "./pages/Subscribers";
import SubscribersDetail from "./pages/SubscribersDetail";
import Dates from "./pages/Dates";
import MediaCenter from "./pages/MediaCenter";
import MediaCenterType from "./pages/MediaCenterType";
import Settings from "./pages/Settings";
import Store from "./pages/Store";
import AddProduct from "./pages/AddProduct";
import EditProduct from "./pages/EditProduct";
import Orders from "./pages/Orders";
import OrderDetail from "./pages/OrderDetail";
import Books from "./pages/Books";
import AddBook from "./pages/AddBook";
import EditBook from "./pages/EditBook";
import SubscribersList from "./pages/SubscribersList";
import Sessions from "./pages/Sessions";
import AboutUs from "./pages/content/AboutUs/EditAboutUs";
import CoursesOrders from "./pages/CoursesOrders";
import CourseOrderDetail from "./pages/CourseOrderDetail";
import Blogs from "./pages/Blogs";
import AddBlog from "./pages/AddBlog";
import EditBlog from "./pages/EditBlog";
import UsersPage from "./pages/UsersPage";
import UserDetails from "./pages/UserDetails";
import Certifications from "./pages/Certifications";
import Affiliate from "./pages/Affiliate/Affiliate";
import Campaigns from "./pages/Affiliate/Campaigns";
import Referrers from "./pages/Affiliate/Referrers";
import ReferralLinks from "./pages/Affiliate/ReferralLinks";
import ReferrerDetails from "./pages/Affiliate/ReferrerDetails";
import ReferralLinkDetails from "./pages/Affiliate/ReferralLinkDetails";
import Balances from "./pages/Affiliate/Balances";
import Withdraw from "./pages/Affiliate/Withdraw";
import WithdrawRequestDetails from "./pages/Affiliate/WithdrawRequestDetails";

const Dashboard = ({
  overview,
  normalCourses,
  addCourse,
  editCourse,
  followCourses,
  addCourseFollow,
  editCourseFollow,
  coursesOrders,
  courseOrderDetail,
  subscribers,
  subscribersList,
  subscribersDetail,
  dates,
  sessions,
  blogs,
  addBlog,
  editBlog,
  mediaCenter,
  mediaCenterType,
  books,
  addBook,
  editBook,
  store,
  addProduct,
  editProduct,
  orders,
  ordersDetail,
  settings,
  courseSubscribers,
  aboutUs,
  Users,
  User,
  certification,
  affiliate,
  campaigns,
  referrers,
  referralLinks,
  referrer,
  referralLinkDetails,
  balances,
  withdrawOptions,
  withdrawRequestDetails,
}) => {
  const content = () => {
    switch (true) {
      case overview:
        return <Overview></Overview>;
      case normalCourses:
        return <NormalCourses></NormalCourses>;
      case addCourse:
        return <AddCourse></AddCourse>;
      case editCourse:
        return <EditCourse></EditCourse>;
      case courseSubscribers:
        return <CourseSubscribers></CourseSubscribers>;
      case followCourses:
        return <FollowCourses></FollowCourses>;
      case addCourseFollow:
        return <AddCourseFollow></AddCourseFollow>;
      case editCourseFollow:
        return <EditCourseFollow></EditCourseFollow>;
      case coursesOrders:
        return <CoursesOrders></CoursesOrders>;
      case courseOrderDetail:
        return <CourseOrderDetail></CourseOrderDetail>;
      case subscribers:
        return <Subscribers></Subscribers>;
      case subscribersList:
        return <SubscribersList></SubscribersList>;
      case subscribersDetail:
        return <SubscribersDetail></SubscribersDetail>;
      case dates:
        return <Dates></Dates>;
      case sessions:
        return <Sessions></Sessions>;
      case blogs:
        return <Blogs></Blogs>;
      case addBlog:
        return <AddBlog></AddBlog>;
      case editBlog:
        return <EditBlog></EditBlog>;
      case settings:
        return <Settings></Settings>;
      case books:
        return <Books></Books>;
      case addBook:
        return <AddBook></AddBook>;
      case editBook:
        return <EditBook></EditBook>;
      case store:
        return <Store></Store>;
      case addProduct:
        return <AddProduct></AddProduct>;
      case editProduct:
        return <EditProduct></EditProduct>;
      case orders:
        return <Orders></Orders>;
      case ordersDetail:
        return <OrderDetail></OrderDetail>;
      case mediaCenter:
        return <MediaCenter></MediaCenter>;
      case mediaCenterType:
        return <MediaCenterType></MediaCenterType>;
      case aboutUs:
        return <AboutUs></AboutUs>;
      case Users:
        return <UsersPage></UsersPage>;
      case User:
        return <UserDetails></UserDetails>;
      case certification:
        return <Certifications></Certifications>;
      case affiliate:
        return <Affiliate></Affiliate>;
      case campaigns:
        return <Campaigns></Campaigns>;
      case referrers:
        return <Referrers></Referrers>;
      case referrer:
        return <ReferrerDetails></ReferrerDetails>;
      case referralLinks:
        return <ReferralLinks></ReferralLinks>;
      case referralLinkDetails:
        return <ReferralLinkDetails></ReferralLinkDetails>;
      case balances:
        return <Balances></Balances>;
      case withdrawOptions:
        return <Withdraw></Withdraw>;
      case withdrawRequestDetails:
        return <WithdrawRequestDetails></WithdrawRequestDetails>;
      default:
        return <Overview></Overview>;
    }
  };

  return (
    <DashboardContainer>
      <ContentContainer>{content()}</ContentContainer>
      <Sidebar></Sidebar>
    </DashboardContainer>
  );
};

export default Dashboard;
