import Axios from "axios";
import { domain } from "./../global/domain";

export async function addCategory(data) {
  try {
    const response = await Axios.post(
      `https://${domain}/store/categories/`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function deleteCategory(id) {
  try {
    const response = await Axios.delete(
      `https://${domain}/store/categories/${id}`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function addProduct(data) {
  try {
    const response = await Axios.post(
      `https://${domain}/store/products/`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function editProduct(data, id) {
  try {
    const response = await Axios.patch(
      `https://${domain}/store/products/${id}/`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function deleteProduct(id) {
  try {
    const response = await Axios.delete(
      `https://${domain}/store/products/${id}`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
