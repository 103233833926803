import React from "react";
import { useState } from "react";
import useSWR from "swr";
import { domain } from "../../../../global/domain";
import { getTheTokenFromStorage } from "../../../../services/auth";
import { fetcherAuth } from "../../../../services/fetcher";
import AddWithdrawOption from "../content/affiliate/AddWithdrawOption";
import AllWithdrawOptions from "../content/affiliate/AllWithdrawOptions";
import AllWithdrawRequests from "../content/affiliate/AllWithdrawRequests";

const WithdrawOptions = () => {
  const token = getTheTokenFromStorage();

  const [addWithdrawOption, setAddWithdrawOption] = useState(false);

  const { data, mutate } = useSWR(
    [`https://${domain}/payments/affiliate/withdraw/options/`, token],
    fetcherAuth
  );

  return (
    <div className=" flex flex-col overflow-y-auto base-clamp rtl">
      <div className="p-8 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">أرصدة </h3>
      </div>
      <div className="x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-4">
        <button
          className="bg-reghda-blue px-4 py-2 rounded-md text-white"
          onClick={() => setAddWithdrawOption(!addWithdrawOption)}>
          إضافة خيار السحب
        </button>
        {addWithdrawOption && (
          <AddWithdrawOption
            closeAddWithdrawOption={() => setAddWithdrawOption(false)}
            mutate={mutate}
          />
        )}
        {data && <AllWithdrawOptions data={data} mutate={mutate} />}
        {data && <AllWithdrawRequests data={data} mutate={mutate} />}
      </div>
    </div>
  );
};

export default WithdrawOptions;
