import Axios from "axios";
import { domain } from "./../global/domain";

export async function unlockChapter(data) {
  try {
    const response = await Axios.post(
      `https://${domain}/subscriptions/follow-up/unlock-chapter`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
