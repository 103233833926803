import axios from "axios";
import { domain } from "./../global/domain";

export async function updateUserPassword(data, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.patch(
      `https://${domain}/users/password/edit`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function updateRolesUser(data, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.patch(
      `https://${domain}/users/roles/edit`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}
