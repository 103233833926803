import React from "react";
import { Link } from "react-router-dom";

const UserCard = ({ subscriber, courseId }) => {
  return (
    <div className="rounded-2xl max-h-80 2xl:max-h-120 shadow-2xl bg-white x-sm:w-full md:w-72 x-sm:p-2 md:p-4 2xl:w-80 2xl:p-8 flex flex-col items-center justify-around x-sm:space-y-2 md:space-y-3 2xl:space-y-6">
      <div>
        {subscriber.waiting && (
          <div className="text-white bg-yellow-300 rounded-full p-1  h-6 w-6 2xl:p-3 2xl:h-9 2xl:w-9 text-center flex flex-row items-center justify-center notification-shape">
            <span>1</span>
          </div>
        )}
        <img
          className="rounded-full x-sm:w-14 x-sm:h-14 md:w-20 md:h-20 2xl:w-36 2xl:h-36 picfit"
          alt="logo"
          src={subscriber.user.image}
        />
      </div>
      <h1 className="text-reghda-black font-bold x-sm:text-sm md:text-lg 2xl:text-4xl">
        {`${subscriber.user.family_name} ${subscriber.user.given_name}`}
      </h1>
      <h6 className="text-reghda-blue x-sm:text-smx-sm:text-sm md:text-base 2xl:text-xl">
        {subscriber.completed_modules} فصول مكتملة
      </h6>
      <h6 className="text-reghda-blue x-sm:text-sm md:text-base 2xl:text-xl">
        {subscriber.completed_sessions} جلسات منجزة
      </h6>
      <h6 className="text-reghda-blue x-sm:text-sm md:text-base 2xl:text-xl">
        التقدم: {subscriber.progress} حاليا
      </h6>
      <Link to={`/admin/subscribers/${courseId}/${subscriber.id}`}>
        <button className="bg-reghda-black text-white p-2 rounded-xl x-sm:text-sm md:text-base 2xl:text-xl outline-none focus:outline-none">
          التفاصيل
        </button>
      </Link>
    </div>
  );
};

export default UserCard;
