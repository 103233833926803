import React, { useState } from "react";
import Modal from "react-modal";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../../../services/fetcher";

import { domain } from "./../../../../../global/domain";
import {
  addCategory,
  editCategory,
  deleteCategory,
} from "../../../../../services/appointments";

import { IconContext } from "react-icons";
import { AiOutlineClose } from "react-icons/ai";
import LoaderModal from "./../LoaderModal";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import ModalDeleteCategory from "./ModalDeleteCategory";

const CategoryModal = ({ isOpen, closing }) => {
  const screenWidth = useWindowWidth();
  const [addCase, setAddCase] = useState(false);
  const [edit, setEdit] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [categoryId, setCategoryId] = useState(null);

  const [formState, setFormState] = useState({
    title: "",
    description: "",
    duration: "",
    price: null,
  });

  const { data, error } = useSWR(
    `https://${domain}/appointments/types`,
    fetcher
  );

  const handleDelete = async (id) => {
    setLoading(true);
    let res = await deleteCategory(id);
    if (res && res.status === 204) {
      mutate(`https://${domain}/appointments/types`);
    } else {
      alert("retry! something wrong");
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: screenWidth >= 768 ? "40%" : "80%",
          },
        }}>
        <div class="flex flex-row justify-end pb-3">
          <div
            onClick={closing}
            class="self-end cursor-pointer z-50 text-reghda-blue">
            <svg
              class="fill-current text-black"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18">
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
            </svg>
          </div>
        </div>
        {!addCase && (
          <>
            <div className="h-72 2xl:h-80 md:mt-4 2xl:mt-8">
              <div className="flex flex-col overflow-y-auto items-center justify-center x-sm:space-y-3 md:space-y-6 2xl:space-y-12 x-sm:text-xs md:text-base 2xl:text-xl">
                {data &&
                  data.map((category) => (
                    <div className="flex flex-row items-center x-sm:w-full md:w-1/2 text-right">
                      <div
                        onClick={() => {
                          setCategoryId(category.id);
                          setOpenModal(true);
                        }}
                        className="cursor-pointer w-1/4 p-0">
                        <IconContext.Provider
                          value={{ size: "1em", style: { color: "#e53e3e" } }}>
                          <AiOutlineClose></AiOutlineClose>
                        </IconContext.Provider>
                      </div>
                      <div
                        onClick={() => {
                          setFormState({
                            title: category.title,
                            description: category.description,
                            duration: category.duration,
                            price: category.price,
                          });
                          setCurrentId(category.id);
                          setAddCase(true);
                          setEdit(true);
                        }}
                        className="w-3/4 cursor-pointer">
                        <div className="w-full">{category.title}</div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="flex flex-row items-center justify-center">
              <button
                onClick={() => setAddCase(true)}
                className="bg-reghda-blue flex flex-row items-center text-white py-1 px-2 rounded-xl x-sm:text-xs md:text-base 2xl:text-3xl">
                <span>أضف صنف</span>
              </button>
            </div>
          </>
        )}
        {addCase && (
          <div className="flex flex-col items-center justify-center x-sm:space-y-3 md:space-y-6 2xl:space-y-12">
            <h3 className="text-reghda-blue font-semibold">معلومات الصنف</h3>
            <input
              onChange={(event) =>
                setFormState({ ...formState, title: event.target.value })
              }
              value={formState.title}
              placeholder="عنوان"
              type="text"
              className="x-sm:w-full md:w-1/2 text-reghda-black text-right p-1 pr-6 rounded-2xl border-2 border-gray-200 outline-none focus:outline-none  focus:border-pink-300"
            />
            <textarea
              onChange={(event) =>
                setFormState({ ...formState, description: event.target.value })
              }
              value={formState.description}
              rows="3"
              placeholder="الوصف"
              type="text"
              className="x-sm:w-full md:w-1/2 text-reghda-black text-right p-1 pr-6 rounded-2xl border-2 border-gray-200 outline-none focus:outline-none  focus:border-pink-300"
            />
            <select
              onChange={(event) => {
                if (event.target.value === 0) {
                  return;
                }
                setFormState({ ...formState, duration: event.target.value });
              }}
              value={formState.duration}
              placeholder="مدة"
              type="text"
              className="x-sm:w-full md:w-1/2 text-reghda-black text-right p-1 pr-6 rounded-2xl border-2 border-gray-200 outline-none focus:outline-none  focus:border-pink-300">
              <option value={0} selected>
                اختر مدة
              </option>
              <option value={30}>نصف ساعة</option>
              <option value={60}>ساعة</option>
              <option value={90}>ساعة و نصف</option>
              <option value={120}>ساعتان</option>
              <option value={150}>ساعتان و نصف</option>
              <option value={180}>ثلاث ساعات</option>
            </select>
            <input
              onChange={(event) =>
                setFormState({ ...formState, price: event.target.value })
              }
              value={formState.price}
              placeholder="سعر"
              type="text"
              className="x-sm:w-full md:w-1/2 text-reghda-black text-right p-1 pr-6 rounded-2xl border-2 border-gray-200 outline-none focus:outline-none  focus:border-pink-300"
            />
            <div className="w-full flex flex-col items-center justify-center space-y-4">
              {!edit && (
                <button
                  onClick={async () => {
                    if (formState.price < 3.75) {
                      alert("الحد الأدنى للسعر هو 3.75  ريال");
                      return;
                    }
                    if (
                      formState.title === "" ||
                      formState.price === "" ||
                      formState.duration === "" ||
                      formState.description === ""
                    ) {
                      return;
                    }
                    setLoading(true);
                    let res = await addCategory({
                      title: formState.title,
                      description: formState.description,
                      duration: formState.duration,
                      price: formState.price,
                    });
                    if (res && res.status === 201) {
                      setAddCase(false);
                      mutate(`https://${domain}/appointments/types`);
                      setFormState({
                        title: "",
                        description: "",
                        duration: "",
                        price: null,
                      });
                      setLoading(false);
                      closing();
                    } else {
                      alert("retry! something wrong");
                      setFormState({
                        title: "",
                        description: "",
                        duration: "",
                        price: null,
                      });
                      setLoading(false);
                      closing();
                    }
                  }}
                  className="x-sm:w-full md:w-1/2 bg-reghda-blue text-center text-white py-1 px-2 rounded-xl md:text-base 2xl:text-3xl">
                  أضف
                </button>
              )}
              {edit && (
                <button
                  onClick={async () => {
                    if (
                      formState.title === "" ||
                      formState.price === "" ||
                      formState.duration === "" ||
                      formState.description === ""
                    ) {
                      return;
                    }
                    if (formState.price < 3.75) {
                      alert("الحد الأدنى للسعر هو 3.75  ريال");
                      return;
                    }
                    setLoading(true);
                    let res = await editCategory(
                      {
                        title: formState.title,
                        description: formState.description,
                        duration: formState.duration,
                        price: formState.price,
                      },
                      currentId
                    );
                    if (res && res.status === 200) {
                      setAddCase(false);
                      mutate(`https://${domain}/appointments/types`);
                      setFormState({
                        title: "",
                        description: "",
                        duration: "",
                        price: null,
                      });
                      setLoading(false);
                      closing();
                    } else {
                      alert("retry! something wrong");
                      setFormState({
                        title: "",
                        description: "",
                        duration: "",
                        price: null,
                      });
                      setLoading(false);
                      closing();
                    }
                  }}
                  className="x-sm:w-full md:w-1/2 bg-reghda-blue text-center text-white py-1 px-2 rounded-xl md:text-base 2xl:text-3xl">
                  تعديل
                </button>
              )}
              <button
                onClick={() => {
                  setFormState({
                    title: "",
                    description: "",
                    duration: "",
                    price: null,
                  });
                  setAddCase(false);
                  setEdit(false);
                }}
                className="x-sm:w-full md:w-1/2 bg-reghda-blue text-center text-white py-1 px-2 rounded-xl md:text-base 2xl:text-3xl">
                عودة
              </button>
            </div>
          </div>
        )}
        <LoaderModal modalIsOpen={loading}></LoaderModal>
      </Modal>
      <ModalDeleteCategory
        open={openModal}
        handleClose={() => setOpenModal(false)}
        deleteOne={() => handleDelete(categoryId)}
      />
    </>
  );
};

export default CategoryModal;
