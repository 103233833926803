import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { domain } from "../../../../global/domain";
import { getTheTokenFromStorage } from "../../../../services/auth";
import { fetcherAuth } from "../../../../services/fetcher";

const ReferrerDetails = () => {
  const { id } = useParams();
  const token = getTheTokenFromStorage();

  const { data, error } = useSWR(
    [`https://${domain}/affiliate/referrers/${id}/`, token],
    fetcherAuth
  );
  data && console.log(data);
  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto base-clamp">
      <div className="p-8 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
          معلومات المُحيل
        </h3>
      </div>
      <div className="x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-10">
        <div className="flex justify-center items-center w-full bg-white rounded-lg p-4">
          Need More information on the endpoint
        </div>
      </div>
    </div>
  );
};

export default ReferrerDetails;
