import React, { useState } from "react";
import AddForm from "./content/books/AddForm";
import { useHistory } from "react-router-dom";
import LoaderModal from "./content/LoaderModal";
import { addBook } from "../../../services/books";

const AddBook = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [formState, setFormState] = useState({
    book: null,
    name: "",
    description: "",
    type: "free",
    price: null,
    isAvailable: "true",
    image: null,
  });

  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto">
      <div className="p-8 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">إضافة كتاب</h3>
      </div>
      <div className="x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-10">
        <div className="bg-white x-sm:w-full md:w-11/12 rounded-lg flex flex-col x-sm:p-2 md:p-4 2xl:p-12">
          <div className="flex flex-row items-center justify-end md:space-x-8 2xl:space-x-16 border-b border-gray-200 md:mb-2 md:pb-2 2xl:mb-4 2xl:pb-4 md:text-xl 2xl:text-4xl">
            <div>محتوى</div>
          </div>
          <div>
            <div className="x-sm:p-2 md:p-8 2xl:p-16">
              <AddForm
                formState={formState}
                setFormState={setFormState}
              ></AddForm>
              <div>
                <button
                  onClick={async (e) => {
                    if (formState.type === "paid" && formState.price < 3.75) {
                      alert("الحد الأدنى للسعر هو 3.75  ريال");
                      return;
                    }

                    setLoading(true);
                    const formData = new FormData();

                    if (formState.book) {
                      formData.append(`book`, formState.book);
                    }

                    formData.append(`name`, formState.name);
                    formData.append(`description`, formState.description);
                    formData.append(`is_available`, formState.isAvailable);
                    formData.append(`type`, formState.type);

                    if (formState.price) {
                      formData.append(`price`, formState.price);
                    } else {
                      formData.append(`price`, "");
                    }

                    if (formState.image) {
                      formData.append(`image`, formState.image);
                    }

                    let res = await addBook(formData);
                    console.log(res, "👀");
                    setLoading(false);
                    res && res.data && history.push(`/admin/books`);
                  }}
                  className="bg-reghda-blue float-left text-white py-2 px-4 rounded-xl md:text-base 2xl:text-3xl"
                >
                  التالي
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default AddBook;
