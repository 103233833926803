import React, { useState } from "react";
import { IconContext } from "react-icons";
import { AiOutlineClose } from "react-icons/ai";

const PreviewImage = ({ file, formState, setFormState }) => {
  return (
    <div className="flex flex-col items-center md:text-xs 2xl:text-lg">
      <div className="w-full flex flex-row items-center justify-end">
        <div
          onClick={() => {
            let newImagesList = formState.images.filter(
              (image) => image !== file
            );
            setFormState({
              ...formState,
              images: newImagesList,
            });
          }}
          className="cursor-pointer"
        >
          <IconContext.Provider
            value={{ size: "1.25em", style: { color: "#e53e3e" } }}
          >
            <AiOutlineClose></AiOutlineClose>
          </IconContext.Provider>
        </div>
      </div>
      {typeof file === "string" && <img src={file} alt="somePreview" />}
      {!(typeof file === "string") && (
        <img src={URL.createObjectURL(file)} alt="somePreview" />
      )}
    </div>
  );
};

export default PreviewImage;
