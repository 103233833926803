import React, { useState } from "react";
import { IconContext } from "react-icons";
import { FaRegUserCircle } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { AiFillEdit, AiTwotoneEdit, AiFillDelete } from "react-icons/ai";
import { Link, useLocation, useHistory } from "react-router-dom";
import cn from "classnames";
import { iconsColor } from "./../../../../../global/iconsColor";
import ModalDelete from "./ModalDelete";

const CourseCard = ({ courseDetail, deleteCourse, follow }) => {
  let history = useHistory();

  const [openModal, setOpenModal] = useState(false);

  const { pathname } = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  let menuStyle = cn(
    "relative cursor-pointer flex flex-col items-center justify-center md:ml-2 2xl:ml-4  ",
    { dropdown: menuOpen }
  );

  let menuItemsStyle = cn(
    "bg-reghda-black  rounded-lg p-2 absolute hidden top-8 absolute",
    {
      dropdownContent: menuOpen,
    }
  );

  return (
    <>
      <div className="flex x-sm:flex-col-reverse md:flex-row items-center justify-between border-t-2 border-gray-100 md:pt-4 mt-8">
        <Link
          to={`/admin/course-subscribers/${courseDetail.id}`}
          className="x-sm:w-full x-sm:space-x-2 md:w-1/3 text-reghda-blue flex x-sm:flex-row md:flex-col space-y-1 items-center justify-center md:text-base 2xl:text-2xl mt-2 md:mt-0"
        >
          {/* <div className="x-sm:hidden md:block"> */}
          <IconContext.Provider
            value={{ size: "2.5em", style: { color: iconsColor } }}
          >
            <FaRegUserCircle></FaRegUserCircle>
          </IconContext.Provider>
          {/* </div> */}
          <h3 style={{ margin: "0.2rem 0 0 0" }} className="px-1 md:px-0">
            {courseDetail.students_count}
          </h3>
          <h3 style={{ margin: "0.2rem 0 0 0 " }} className="px-1 md:px-0">
            مشترك
          </h3>
        </Link>
        <div className="flex x-sm:flex-col-reverse x-sm:space-y-2 md:flex-row items-center pt-2 md:pt-0">
          <div className="text-right  x-sm:space-y-1  2xl:space-y-4 md:pr-8">
            <h3 className="x-sm:text-center md:text-lg 2xl:text-4xl font-bold mt-2 md:mt-0">
              {courseDetail.title}
            </h3>
            {/* <p className="md:text-base 2xl:text-2xl">
            {courseDetail.description.length > 302
              ? courseDetail.description.substring(0, 302) + " . . ."
              : courseDetail.description}
          </p> */}
            {courseDetail.type === "paid" && (
              <p className="text-reghda-blue md:text-base 2xl:text-2xl">
                دورة مدفوعة
              </p>
            )}
            {courseDetail.type === "free" && (
              <p className="text-reghda-blue md:text-base 2xl:text-2xl text-center py-2">
                دورة مجانية
              </p>
            )}
          </div>
          <img
            style={{ margin: 0 }}
            className="x-sm:w-40 md:w-48 rounded-lg shadow-xl "
            src={courseDetail.picture}
            alt="course"
          />
          {!pathname.includes("overview") && (
            <div className={`${menuStyle} absolute md:pr-2 md:pl-4`}>
              <div onClick={() => setMenuOpen(!menuOpen)} className="py-2">
                <IconContext.Provider
                  value={{ size: "1.5em", style: { color: "#5b5b5b" } }}
                >
                  <BsThreeDots></BsThreeDots>
                </IconContext.Provider>
              </div>

              <div className={menuItemsStyle}>
                <div
                  onClick={() => {
                    if (follow) {
                      history.push(
                        `/admin/follow-courses/edit-course/${courseDetail.id}`
                      );
                      return;
                    }
                    history.push(
                      `/admin/edit-course/${courseDetail.type}/${courseDetail.id}`
                    );
                    return;
                  }}
                  className="text-white flex flex-row space-x-1 items-center justify-center cursor-pointer"
                >
                  <span className="text-sm">تعديل</span>
                  <IconContext.Provider
                    value={{ size: "1em", style: { color: "#fff" } }}
                  >
                    <AiTwotoneEdit></AiTwotoneEdit>
                  </IconContext.Provider>
                </div>
                <div
                  onClick={() => setOpenModal(true)}
                  className="text-white flex flex-row space-x-1 items-center justify-center cursor-pointer"
                >
                  <span className="text-sm">حذف</span>
                  <IconContext.Provider
                    value={{ size: "1em", style: { color: "#fff" } }}
                  >
                    <AiFillDelete></AiFillDelete>
                  </IconContext.Provider>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalDelete
        open={openModal}
        handleClose={() => setOpenModal(false)}
        deleteCourse={deleteCourse}
      />
    </>
  );
};

export default CourseCard;
