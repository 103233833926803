import React, { useState } from "react";
import PreviewImage from "./PreviewImage";
import TextEditorContent from "./TextEditorContent";

const AddForm = ({ formState, setFormState, categories }) => {
  return (
    <form className="flex flex-col pb-4 x-sm:space-y-4 md:space-y-6 2xl:space-y-12 text-right x-sm:text-sm sm:text-base md:text-xl 2xl:text-4xl">
      <fieldset className="flex flex-row items-center justify-end space-x-16">
        <textarea
          onChange={(event) =>
            setFormState({ ...formState, title: event.target.value })
          }
          value={formState.title}
          rows="1"
          id="name"
          type="text"
          class="w-2/4 md:ml-44 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
        />
        <label className="w-1/6" htmlFor="title">
          عنوان المقال
        </label>
      </fieldset>
      <fieldset className="flex flex-row items-center justify-end md:space-x-28 2xl:space-x-40">
        <div className="space-x-4">
          <select
            onChange={(event) => {
              if (event.target.value === "none") {
                return;
              }
              setFormState({
                ...formState,
                category: {
                  id: event.target.value.split("@")[0],
                  title: event.target.value.split("@")[1],
                },
              });
            }}
            value={
              formState.category &&
              `${formState.category.id}@${formState.category.title}`
            }
            id="category"
            class="x-sm:w-32 md:w-48 md:ml-44 border-2 border-gray-300 text-right p-1 2xl:p-2 md:text-lg 2xl:text-2xl pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
          >
            <option value="none" selected>
              اختر صنف
            </option>
            {categories &&
              categories.map((category) => (
                <option value={`${category.id}@${category.title}`}>
                  {category.title}
                </option>
              ))}
          </select>
        </div>
        <label className="w-1/6" htmlFor="category">
          صنف
        </label>
      </fieldset>

      <fieldset className="flex flex-row items-start justify-end space-x-16 ">
        <div class="w-3/4">
          <TextEditorContent
            initialContentState={formState.body}
            formState={formState}
            setFormState={setFormState}
          ></TextEditorContent>
        </div>
        <label className="w-1/6 " htmlFor="body">
          المقال
        </label>
      </fieldset>

      <fieldset className="flex flex-row items-center justify-end space-x-12">
        <div className="flex flex-row items-center">
          <div>
            <input
              onChange={(event) => {
                if (event.target.files[0]) {
                  setFormState({
                    ...formState,
                    image: event.target.files[0],
                  });
                }
              }}
              id="upload-img"
              type="file"
              accept="image/*"
              // multiple="multiple"
              style={{ display: "none" }}
            />
            <div
              onClick={() => {
                document.querySelector("#upload-img").click();
              }}
              className="md:ml-44 bg-reghda-black text-reghda-blue py-2 px-4 rounded-xl md:text-base 2xl:text-3xl text-right cursor-pointer"
            >
              تحميل
            </div>
          </div>
        </div>
        <label className="w-1/6" htmlFor="upload-img">
          الصور
        </label>
      </fieldset>
      <div className="grid x-sm:grid-cols-2 sm:grid-cols-6 md:grid-cols-6 2xl:grid-cols-6 gap-8 justify-around">
        {formState.image && (
          <PreviewImage file={formState.image}></PreviewImage>
        )}
      </div>
    </form>
  );
};

export default AddForm;
