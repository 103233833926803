import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { getTheTokenFromStorage } from "../../../../services/auth";
import { fetcherAuth } from "../../../../services/fetcher";

const ReferralLinkDetails = () => {
  const { id } = useParams();
  const token = getTheTokenFromStorage();

  //Query Links
  const { data, error, mutate } = useSWR(
    [`https://ragda.site/affiliate/links/${id}/`, token],
    fetcherAuth
  );
  data && console.log(data);
  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto base-clamp rtl">
      <div className="p-8 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
          تفاصيل الرابط
        </h3>
      </div>

      <div className="x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-2">
        <div className="bg-white w-full rounded-lg p-4 md:p-8 2xl:p-16 flex flex-col space-y-4 md:space-y-8 2xl:space-y-16">
          Need more info on the endpoint
        </div>
      </div>
    </div>
  );
};

export default ReferralLinkDetails;
