import axios from "axios";
import {
  getTheRefresh,
  storeTheRefresh,
  getTheUserFromStorage,
  storeTheUser,
} from "./auth";
import jwt from "jwt-decode";
import { domain } from "./../global/domain";

export function initInterceptors() {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  axios.interceptors.response.use(
    (response) => {
      // let user = getTheUserFromStorage();
      // let date = new Date(user.exp * 1000);
      // console.log("from inter 🎂✔", date);
      return response;
    },
    async (err) => {
      // let user = getTheUserFromStorage();
      // let date = new Date(user.exp * 1000);
      // console.log("from inter 🎂❌", date);
      if (
        err.response.status === 403 &&
        err.config &&
        !err.config.__isRetryRequest
      ) {
        const res = await axios.post(`https://${domain}/users/token/refresh`, {
          refresh: getTheRefresh(),
        });
        const token = res.data.access;
        const user = jwt(res.data.access);

        if (res.data && res.data.access && res.status === 200) {
          storeTheUser(user, token, getTheRefresh());
          window.location.reload();
        }
        console.log("🎁", res);
      }
    }
  );
}
