import axios from "axios";
import { domain } from "./../global/domain";

export async function addNewCertificate(data, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `https://${domain}/certificates/`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function editCertification(data, id, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.patch(
      `https://${domain}/certificates/${id}/`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function deleteCertificate(id, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.delete(
      `https://${domain}/certificates/${id}`,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}
