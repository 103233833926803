import React, { useState } from "react";
import { IconContext } from "react-icons";
import { AiOutlineClose } from "react-icons/ai";

const PreviewImage = ({ file }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      className="flex flex-col items-center space-y-2 md:text-xs 2xl:text-lg cursor-pointer"
    >
      {typeof file === "string" && <img src={file} alt="somePreview" />}
      {!(typeof file === "string") && (
        <img src={URL.createObjectURL(file)} alt="somePreview" />
      )}
      {/* <img src={file.uploaded_image} alt="somePreview" /> */}
      {/* {!hover && (
          <p className="text-center break-all">
            {file.uploaded_image.replace("http://ragda.site/media/images/", "")}
          </p>
        )} */}
      {/* {hover && ( */}
      {/* <div>
        <IconContext.Provider
          value={{ size: "2em", style: { color: "#e53e3e" } }}
        >
          <AiOutlineClose></AiOutlineClose>
        </IconContext.Provider>
      </div> */}
      {/* )} */}
    </div>
  );
};

export default PreviewImage;
