import React, { useEffect, useState } from "react";
import {
  deleteCampaign,
  editCampaign,
} from "../../../../../services/affiliate";
import { getTheTokenFromStorage } from "../../../../../services/auth";
import LoaderModal from "../LoaderModal";
import DeleteModal from "./DeleteModal";

import { IconContext } from "react-icons";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { Button } from "@material-ui/core";
import { iconsColor } from "../../../../../global/iconsColor";

const AllCampaigns = ({ data, mutate }) => {
  const token = getTheTokenFromStorage();
  const [loading, setLoading] = useState(false);
  const [rewardType, setRewardType] = useState("");
  const [fields, setFields] = useState({
    name: "",
    description: "",
    is_active: false,
    reward_amount: null,
    reward_percentage: null,
  });
  const [openModal, setOpenModal] = useState(false);
  const [campaignId, setCampaignId] = useState(null);
  const [openEdit, setOpenEdit] = useState(null);

  useEffect(() => {
    rewardType === "reward_amount" &&
      setFields({ ...fields, reward_percentage: null });
    rewardType === "reward_percentage" &&
      setFields({ ...fields, reward_amount: null });
  }, [rewardType]);

  //HandleDelete
  const handleDelete = async (id) => {
    setOpenModal(false);
    setLoading(true);
    const res = await deleteCampaign(id, token);
    if (res?.status === 204) {
      mutate();
    } else {
      alert(res?.data?.message);
    }
    setLoading(false);
  };

  //HandleEdit
  const handleSaveEdit = async () => {
    setLoading(true);
    const res = await editCampaign(fields, openEdit, token);
    if (res?.status === 200) {
      mutate();
      setOpenEdit(null);
    } else {
      alert(res?.data?.message);
    }
    console.log(res);
    setLoading(false);
  };

  return (
    <>
      {/* <h1 className="pb-4 text-xl font-semibold">جميع الحملات</h1> */}
      <div className="flex flex-wrap" style={{ gap: "2%" }}>
        {data?.length === 0 && (
          <div className="w-full text-center p-4 text-2xl">ليس لديك حملة</div>
        )}
        {data?.map((item) =>
          openEdit === item.id ? (
            <div className="w-full bg-white border rounded-md shadow-2xl p-2 space-y-4">
              <div
                className="flex justify-start items-center"
                style={{ gap: "3rem" }}
              >
                <label className="w-24"> اسم</label>
                <input
                  placeholder="اسم"
                  className="w-full border rounded-md p-2"
                  value={fields.name}
                  onChange={(e) =>
                    setFields({ ...fields, name: e.target.value })
                  }
                />
              </div>
              <div className="flex justify-start" style={{ gap: "3rem" }}>
                <label className="w-24"> وصف</label>
                <textarea
                  placeholder="وصف"
                  rows={3}
                  className="w-full border rounded-md p-2"
                  value={fields.description}
                  onChange={(e) =>
                    setFields({ ...fields, description: e.target.value })
                  }
                />
              </div>
              <div
                className="flex justify-start items-center"
                style={{ gap: "3rem" }}
              >
                <label className="w-24"> فعال</label>
                <input
                  type="checkbox"
                  value={fields.is_active}
                  checked={fields.is_active}
                  onChange={(e) =>
                    setFields({ ...fields, is_active: !fields.is_active })
                  }
                />
              </div>
              <div
                className="flex justify-start items-center"
                style={{ gap: "3rem" }}
              >
                <label className="w-24">نوع المكافأة </label>
                <select
                  id="select"
                  variant="outlined"
                  placeholder="اختر دورة"
                  className="w-1/2 border rounded-md p-2"
                  value={rewardType}
                  onChange={(e) => setRewardType(e.target.value)}
                >
                  <option value=""></option>
                  <option value="reward_amount"> مكافأة بمبلغ</option>
                  <option value="reward_percentage">
                    المكافأة بالنسبة المئوية
                  </option>
                </select>
              </div>
              {rewardType === "reward_amount" && (
                <div
                  className="flex justify-start items-center"
                  style={{ gap: "3rem" }}
                >
                  <label className="w-24">مبلغ المكافأة</label>
                  <input
                    placeholder="مبلغ المكافأة"
                    className="w-full border rounded-md p-2"
                    value={fields.reward_amount}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        reward_amount: Number(e.target.value),
                      })
                    }
                  />
                </div>
              )}
              {rewardType === "reward_percentage" && (
                <div
                  className="flex justify-start items-center"
                  style={{ gap: "3rem" }}
                >
                  <label className="w-24">نسبة المكافأة</label>
                  <input
                    placeholder="نسبة المكافأة"
                    className="w-full border rounded-md p-2"
                    value={fields.reward_percentage}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        reward_percentage: Number(e.target.value),
                      })
                    }
                  />
                </div>
              )}
              <div className="flex justify-end gap-2">
                <button
                  className="bg-red-500 px-4 py-2 rounded-md text-white"
                  onClick={() => setOpenEdit(null)}
                >
                  إلغاء
                </button>
                <button
                  className="bg-reghda-blue text-white px-4 py-2 rounded-md"
                  onClick={handleSaveEdit}
                >
                  حفظ
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className="flex-1 md:flex-49 bg-white p-4 border rounded-md shadow-2xl p-2 space-y-4 my-4 text-base 2xl:text-xl">
                <div className="flex">
                  <span className="w-32 text-reghda-blue">اسم الدورة</span>
                  <span>{item?.course?.title}</span>
                </div>
                <div className="flex">
                  <span className="w-32 text-reghda-blue"> رمز الحملة</span>
                  <span>{item?.campaign_code}</span>
                </div>
                <div className="flex">
                  <span className="w-32 text-reghda-blue">اسم الحملة</span>
                  <span>{item?.name}</span>
                </div>
                <div className="flex">
                  <span className="w-32 text-reghda-blue">وصف الحملة</span>
                  <span>{item?.description}</span>
                </div>
                <div className="flex">
                  <span className="w-32 text-reghda-blue">فعال</span>
                  <span>{item?.is_active ? "نعم" : "لا"}</span>
                </div>
                <div className="flex">
                  <span className="w-32 text-reghda-blue"> نوع المكافأة </span>
                  <span>
                    {item?.reward_amount
                      ? "مكافأة بمبلغ"
                      : "المكافأة بالنسبة المئوية"}
                  </span>
                </div>
                {item?.reward_amount && (
                  <div className="flex">
                    <span className="w-32 text-reghda-blue">
                      {" "}
                      مبلغ المكافأة{" "}
                    </span>
                    <span>{item?.reward_amount} ريال</span>
                  </div>
                )}
                {item?.reward_percentage && (
                  <div className="flex">
                    <span className="w-32 text-reghda-blue">
                      نسبة المكافأة{" "}
                    </span>
                    <span>{item?.reward_percentage * 100} %</span>
                  </div>
                )}

                <div className="w-full flex flex-row items-center justify-between">
                  <button
                    onClick={() => {
                      setRewardType(
                        item?.reward_amount
                          ? "reward_amount"
                          : "reward_percentage"
                      );
                      setFields({
                        name: item?.name,
                        description: item?.description,
                        is_active: item?.is_active,
                        reward_amount: item?.reward_amount,
                        reward_percentage: item?.reward_percentage,
                      });
                      setOpenEdit(item?.id);
                    }}
                    className=""
                  >
                    <IconContext.Provider
                      value={{ size: "1.5em", style: { color: iconsColor } }}
                    >
                      <FiEdit></FiEdit>
                    </IconContext.Provider>
                  </button>
                  <button
                    onClick={() => {
                      setCampaignId(item?.id);
                      setOpenModal(true);
                    }}
                    className=""
                  >
                    <IconContext.Provider
                      value={{ size: "1.5em", style: { color: "#f56565" } }}
                    >
                      <RiDeleteBin5Fill></RiDeleteBin5Fill>
                    </IconContext.Provider>
                  </button>
                </div>
              </div>
            </>
          )
        )}
      </div>
      <DeleteModal
        isOpen={openModal}
        closing={() => setOpenModal(false)}
        deleteFn={() => handleDelete(campaignId)}
      />
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </>
  );
};

export default AllCampaigns;
