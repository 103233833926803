import React, { useState } from "react";
import MediaBookModal from "./MediaBookModal";

const AddForm = ({ formState, setFormState, initValues }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  return (
    <form className="flex flex-col pb-4 x-sm:space-y-4 md:space-y-6 2xl:space-y-12 text-right x-sm:text-sm sm:text-base md:text-xl 2xl:text-4xl">
      <fieldset className="flex flex-row items-center justify-end x-sm:space-x-6 md:space-x-12">
        <div className="flex flex-row items-center">
          {formState.book && <p className="text-green-300">تم التحميل</p>}
          <div>
            <div
              onClick={() => {
                setIsOpen(true);
              }}
              className="md:ml-44 bg-reghda-black text-reghda-blue py-2 px-4 rounded-xl md:text-base 2xl:text-3xl text-right cursor-pointer"
            >
              تحميل
            </div>
          </div>
        </div>
        <label className="w-1/6" htmlFor="upload-img">
          الكتاب
        </label>
      </fieldset>

      <fieldset className="flex flex-row items-center justify-end space-x-16">
        <textarea
          onChange={(event) =>
            setFormState({ ...formState, name: event.target.value })
          }
          value={formState.name}
          rows="1"
          id="name"
          type="text"
          class="w-2/4 md:ml-44 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
        />
        <label className="w-1/6" htmlFor="name">
          إسم الكتاب
        </label>
      </fieldset>
      <fieldset className="flex flex-row items-start justify-end space-x-16">
        <textarea
          onChange={(event) =>
            setFormState({
              ...formState,
              description: event.target.value,
            })
          }
          value={formState.description}
          rows="5"
          id="description"
          type="text"
          class="w-2/4 md:ml-44 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
        />
        <label className="w-1/6" htmlFor="description">
          وصف الكتاب
        </label>
      </fieldset>
      <fieldset className="flex flex-row items-center justify-end md:space-x-28 2xl:space-x-40">
        <div className="space-x-4">
          <select
            onChange={(event) => {
              setFormState({
                ...formState,
                type: event.target.value,
                price: null,
              });
            }}
            // defaultChecked={formState.isLocked}
            value={formState.type}
            id="isAvailable"
            class="w-32 md:ml-44 border-2 border-gray-300 text-right p-1 2xl:p-2 md:text-lg 2xl:text-2xl pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
          >
            <option value="free">مجاني</option>
            <option value="paid">مدفوع</option>
          </select>
        </div>
        <label className="w-1/6" htmlFor="type">
          النوع
        </label>
      </fieldset>
      {formState.type === "paid" && (
        <fieldset className="flex flex-row items-center justify-end md:space-x-28 2xl:space-x-40">
          <div className="space-x-4">
            <span>ريال</span>
            <input
              onChange={(event) =>
                setFormState({ ...formState, price: event.target.value })
              }
              value={formState.price}
              id="price"
              type="text"
              class="w-24 md:ml-44 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
            />
          </div>
          <label className="w-1/6" htmlFor="price">
            الثمن
          </label>
        </fieldset>
      )}
      <fieldset className="flex flex-row items-center justify-end md:space-x-28 2xl:space-x-40">
        <div className="space-x-4">
          <select
            onChange={(event) => {
              setFormState({ ...formState, isAvailable: event.target.value });
            }}
            // defaultChecked={formState.isLocked}
            value={formState.isAvailable}
            id="isAvailable"
            class="w-24 md:ml-44 border-2 border-gray-300 text-right p-1 2xl:p-2 md:text-lg 2xl:text-2xl pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
          >
            <option value="true">نعم</option>
            <option value="false">لا</option>
          </select>
        </div>
        <label className="w-1/6" htmlFor="isAvailable">
          متوفر
        </label>
      </fieldset>
      <fieldset className="flex flex-row items-center justify-end x-sm:space-x-6 md:space-x-12">
        <div className="flex flex-row items-center">
          <p>{formState.image && formState.image.name}</p>
          <div>
            <input
              onChange={(event) => {
                if (event.target.files[0]) {
                  setFormState({ ...formState, image: event.target.files[0] });
                }
              }}
              id="upload-img-book"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
            />
            <div
              onClick={() => {
                document.querySelector("#upload-img-book").click();
              }}
              className="md:ml-44 bg-reghda-black text-reghda-blue py-2 px-4 rounded-xl md:text-base 2xl:text-3xl text-right cursor-pointer"
            >
              تحميل
            </div>
          </div>
        </div>
        <label className="w-1/6" htmlFor="upload-img">
          صورة توضيحية
        </label>
      </fieldset>
      <MediaBookModal
        formState={formState}
        setFormState={setFormState}
        isOpen={modalIsOpen}
        closing={() => setIsOpen(false)}
      ></MediaBookModal>
    </form>
  );
};

export default AddForm;
