import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin5Fill } from "react-icons/ri";
import ModalDelete from "./ModalDelete";

const BookCard = ({ detail, deleteOne }) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <div className="rounded-2xl shadow-2xl bg-white x-sm:p-2 md:w-56 md:p-4 2xl:w-80 2xl:p-8 flex flex-col items-center justify-around x-sm:space-y-1 md:space-y-2 2xl:space-y-3">
        <img
          className="flex-1"
          alt="logo"
          className="max-h-72"
          src={detail.image ? detail.image : "/images/product.png"}
        />
        <h1 className="text-reghda-black font-bold md:text-lg 2xl:text-4xl">
          {detail.name}
        </h1>
        <div className="w-full flex flex-row items-center justify-between">
          <button onClick={() => setOpenModal(true)} className="">
            <IconContext.Provider
              value={{ size: "1.5em", style: { color: "#5b5b5b" } }}
            >
              <RiDeleteBin5Fill></RiDeleteBin5Fill>
            </IconContext.Provider>
          </button>
          <Link className="" to={`/admin/books/edit-book/${detail.id}`}>
            <IconContext.Provider
              value={{ size: "1.5em", style: { color: "#5b5b5b" } }}
            >
              <FiEdit></FiEdit>
            </IconContext.Provider>
          </Link>
        </div>
      </div>
      <ModalDelete
        open={openModal}
        handleClose={() => setOpenModal(false)}
        deleteOne={deleteOne}
      />
    </>
  );
};

export default BookCard;
