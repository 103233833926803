import React, { useState } from "react";
import useSWR from "swr";
import { domain } from "../../../../global/domain";
import { getTheTokenFromStorage } from "../../../../services/auth";
import { fetcherAuth } from "../../../../services/fetcher";
import AddBalance from "../content/affiliate/AddBalance";
import AllBalances from "../content/affiliate/AllBalances";

const Balances = () => {
  const token = getTheTokenFromStorage();
  const [showAddBalance, setShowAddBalance] = useState(false);

  const { data, mutate } = useSWR(
    [`https://${domain}/payments/affiliate/balance/`, token],
    fetcherAuth
  );

  return (
    <div className=" flex flex-col overflow-y-auto base-clamp rtl">
      <div className="p-8 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">أرصدة </h3>
      </div>
      <div className="x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-4">
        <button
          onClick={() => setShowAddBalance(!showAddBalance)}
          className="bg-reghda-blue px-4 py-2 rounded-md text-white">
          أضف رصيد
        </button>
        {showAddBalance && (
          <div className=" w-full bg-white rounded-lg p-4">
            <AddBalance
              mutate={mutate}
              closeAddBalance={() => setShowAddBalance(false)}
            />
          </div>
        )}
        <AllBalances data={data} mutate={mutate} />
      </div>
    </div>
  );
};

export default Balances;
