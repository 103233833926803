import React, { useState } from "react";
import Modal from "react-modal";
import LoaderModal from "../LoaderModal";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import { disEnroll } from "../../../../../services/enrollment";

const RemoveSubscriberModal = ({
  isOpen,
  closing,
  mutate,
  courseId,
  userId,
}) => {
  const screenWidth = useWindowWidth();
  const [loading, setLoading] = useState(false);
  const [agreeCondition, setAgreeCondition] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: screenWidth >= 768 ? "40%" : "80%",
        },
      }}>
      <div class="flex flex-row justify-end pb-3">
        <div
          onClick={closing}
          class="self-end cursor-pointer z-50 text-reghda-blue">
          <svg
            class="fill-current text-black"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18">
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center x-sm:space-y-5 md:space-y-10 2xl:space-y-16">
        <h3 className="text-red-500 font-semibold">
          هل أنت متأكد من أنك تريد حذف هذا المشترك من الدورة ؟
        </h3>
        <div className="space-x-3 cursor-pointer">
          <label for="condition">أنا متأكد من رغبة حذف المشترك</label>
          <input
            type="checkbox"
            id="condition"
            onChange={() => {
              setAgreeCondition(!agreeCondition);
            }}
          />
        </div>
        <button
          onClick={async () => {
            if (!agreeCondition) {
              return;
            }
            setLoading(true);
            let res = await disEnroll({
              user: userId,
              course: courseId,
            });
            if (res && res.status === 200) {
              mutate();
            } else {
              alert("retry! something wrong");
            }
            setLoading(false);
            setAgreeCondition(!agreeCondition);
            closing();
          }}
          className="w-1/2 bg-red-500 text-center text-white py-1 px-2 rounded-xl md:text-base 2xl:text-3xl outline-none focus:outline-none">
          تاكيد
        </button>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </Modal>
  );
};

export default RemoveSubscriberModal;
