import axios from "axios";
import { domain } from "./../global/domain";

export async function addNewCampaign(data, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `https://${domain}/affiliate/campaigns/courses/`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function deleteCampaign(id, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.delete(
      `https://ragda.site/affiliate/campaigns/all/${id}/`,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function editCampaign(data, id, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.patch(
      `https://ragda.site/affiliate/campaigns/courses/${id}/`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function addReferrer(data, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `https://${domain}/affiliate/referrers/`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function deleteReferrer(id, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.delete(
      `https://${domain}/affiliate/referrers/${id}/`,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function editReferrer(data, id, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.patch(
      `https://${domain}/affiliate/referrers/${id}/`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function addReferralLink(data, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `https://${domain}/affiliate/links/`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function deleteReferralLink(id, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.delete(
      `https://${domain}/affiliate/links/${id}/`,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function createBalance(data, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `https://${domain}/payments/affiliate/balance/`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function deleteBalance(id, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.delete(
      `https://${domain}/payments/affiliate/balance/${id}/`,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function addWithdrawOption(data, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `https://${domain}/payments/affiliate/withdraw/options/`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function deleteWithdrawOption(id, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.delete(
      `https://${domain}/payments/affiliate/withdraw/options/${id}/`,

      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function deleteWithdrawRequest(id, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.delete(
      `https://${domain}/payments/affiliate/withdraw/${id}/`,

      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function editStatusWithdrawRequest(data, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.patch(
      `https://${domain}/payments/affiliate/withdraw/status/edit`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}
