import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { fetcher, fetcherAuth } from "../../../../services/fetcher";
import { domain } from "../../../../global/domain";
import { CircularProgress, MenuItem, Select } from "@material-ui/core";
import { addNewCampaign, addReferrer } from "../../../../services/affiliate";
import { getTheTokenFromStorage } from "../../../../services/auth";
import AllCampaigns from "../content/affiliate/AllCampaigns";
import LoaderModal from "../content/LoaderModal";

const Affiliate = () => {
  const token = getTheTokenFromStorage();

  const [addCampaign, setAddCampaign] = useState(false);
  const [addReferrer, setAddReferrer] = useState(false);
  const [addLinks, setAddLinks] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rewardType, setRewardType] = useState("");
  const [fields, setFields] = useState({
    course: "", // course id
    name: "", // name of the campaign
    description: "", // description for the campaign
    is_active: false, // boolean : is active or not
    reward_amount: null,
    reward_percentage: null,
  });

  useEffect(() => {
    rewardType === "reward_amount" &&
      setFields({ ...fields, reward_percentage: null });
    rewardType === "reward_percentage" &&
      setFields({ ...fields, reward_amount: null });
  }, [rewardType]);

  //Query all Campaigns
  const {
    data: Campaigns,
    error: campaignsError,
    mutate,
  } = useSWR(
    [`https://${domain}/affiliate/campaigns/courses/`, token],
    fetcherAuth
  );

  // Courses to display in the select
  const { data, error } = useSWR(`https://${domain}/courses/paid/`, fetcher);

  const handleCreateCampaign = async () => {
    if (
      rewardType === "reward_percentage" &&
      (fields.reward_percentage > 100 || fields.reward_percentage < 0)
    ) {
      alert("percentage should be between 100 & 1");
      return;
    }
    setLoading(true);

    const data = {
      ...fields,
      reward_percentage: fields.reward_percentage
        ? fields.reward_percentage / 100
        : null,
    };
    const res = await addNewCampaign(data, token);
    if (res?.status === 201) {
      mutate();
      setFields({
        course: "", // course id
        name: "", // name of the campaign
        description: "", // description for the campaign
        is_active: false, // boolean : is active or not
        reward_amount: null,
        reward_percentage: null,
      });
      setAddCampaign(false);
    } else {
      alert("لا يمكنك إضافة حملات متعددة لنفس الدورة");
    }
    setLoading(false);
  };

  if (!Campaigns)
    return (
      <div className="min-h-screen h-full flex flex-col overflow-y-auto base-clamp">
        <div className="p-8 text-right">
          <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
            تسويق بالعمولة
          </h3>
        </div>
        <div className="x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-10">
          <div className="h-48 flex justify-center items-center w-full">
            <CircularProgress />
          </div>
        </div>
      </div>
    );

  if (Campaigns)
    return (
      <div className="min-h-screen h-full flex flex-col overflow-y-auto base-clamp rtl">
        <div className="p-8 text-right">
          <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
            تسويق بالعمولة
          </h3>
        </div>
        <div className="x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-2">
          <button
            onClick={() => setAddCampaign(!addCampaign)}
            className="bg-reghda-blue text-white px-4 py-2 rounded-md">
            {addCampaign ? "إلغاء" : "أضف حملة"}
          </button>
          {addCampaign && (
            <div className="w-full bg-white rounded-md p-4 space-y-4">
              <div className="flex justify-start" style={{ gap: "3rem" }}>
                <label className="w-24"> اسم</label>
                <input
                  placeholder="اسم"
                  className="w-full border rounded-md p-2"
                  value={fields.name}
                  onChange={(e) =>
                    setFields({ ...fields, name: e.target.value })
                  }
                />
              </div>
              <div className="flex justify-start" style={{ gap: "3rem" }}>
                <label className="w-24"> وصف</label>
                <textarea
                  placeholder="وصف"
                  rows={3}
                  className="w-full border rounded-md p-2"
                  value={fields.description}
                  onChange={(e) =>
                    setFields({ ...fields, description: e.target.value })
                  }
                />
              </div>
              <div
                className="flex justify-start items-center"
                style={{ gap: "3rem" }}>
                <label className="w-24"> فعال</label>
                <input
                  type="checkbox"
                  value={fields.is_active}
                  checked={fields.is_active}
                  onChange={(e) =>
                    setFields({ ...fields, is_active: !fields.is_active })
                  }
                />
              </div>
              <div className="flex justify-start" style={{ gap: "3rem" }}>
                <label className="w-24">دورة</label>
                <select
                  id="select"
                  variant="outlined"
                  placeholder="اختر دورة"
                  className="w-1/2 border rounded-md p-2"
                  value={fields.course}
                  onChange={(e) =>
                    setFields({ ...fields, course: Number(e.target.value) })
                  }>
                  <option value={null}>إختر الدورة</option>
                  {data?.map((course) => (
                    <option value={course.id}>{course.title}</option>
                  ))}
                </select>
              </div>
              <div
                className="flex justify-start items-center"
                style={{ gap: "3rem" }}>
                <label className="w-24">نوع المكافأة </label>
                <select
                  id="select"
                  variant="outlined"
                  placeholder="اختر دورة"
                  className="w-1/2 border rounded-md p-2"
                  value={rewardType}
                  onChange={(e) => setRewardType(e.target.value)}>
                  <option value={null}>إختر النوع</option>
                  <option value="reward_amount"> مكافأة بمبلغ</option>
                  <option value="reward_percentage">
                    المكافأة بالنسبة المئوية
                  </option>
                </select>
              </div>
              {rewardType === "reward_amount" && (
                <div
                  className="flex justify-start items-center"
                  style={{ gap: "3rem" }}>
                  <label className="w-24">مبلغ المكافأة</label>
                  <input
                    placeholder="مبلغ المكافأة بريال"
                    className="w-full border rounded-md p-2"
                    value={fields.reward_amount}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        reward_amount: Number(e.target.value),
                      })
                    }
                  />
                </div>
              )}
              {rewardType === "reward_percentage" && (
                <div
                  className="flex justify-start items-center"
                  style={{ gap: "3rem" }}>
                  <label className="w-24">نسبة المكافأة</label>
                  <input
                    placeholder="نسبة المكافأة"
                    className="w-full border rounded-md p-2"
                    value={fields.reward_percentage}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        reward_percentage: Number(e.target.value),
                      })
                    }
                  />
                </div>
              )}
              <div className="flex justify-end">
                <button
                  className="bg-reghda-blue text-white px-4 py-2 rounded-md"
                  onClick={handleCreateCampaign}>
                  حفظ
                </button>
              </div>
            </div>
          )}
          <div className="w-full rounded-md p-4">
            {Campaigns && <AllCampaigns data={Campaigns} mutate={mutate} />}
          </div>
        </div>
        <LoaderModal modalIsOpen={loading}></LoaderModal>
      </div>
    );
};

export default Affiliate;
