import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import useSWR, { mutate } from "swr";
import { domain } from "../../../../../global/domain";
import { deleteReferralLink } from "../../../../../services/affiliate";
import { getTheTokenFromStorage } from "../../../../../services/auth";
import { fetcherAuth } from "../../../../../services/fetcher";
import LoaderModal from "../LoaderModal";
import DeleteModal from "./DeleteModal";

import { IconContext } from "react-icons";
import { AiFillDelete, AiFillCopy } from "react-icons/ai";

const AllLinks = () => {
  const token = getTheTokenFromStorage();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [linkId, setLinkId] = useState(null);

  const { data, error, mutate } = useSWR(
    [`https://${domain}/affiliate/links/`, token],
    fetcherAuth
  );

  const handleDelete = async (id) => {
    setOpenModal(false);
    setLoading(true);
    const res = await deleteReferralLink(id, token);
    if (res?.status === 204) {
      mutate();
    } else {
      alert(res?.data?.message);
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col overflow-y-auto base-clamp">
      <div className="x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-4">
        {data?.length === 0 && (
          <div className="text-center p-4 border rounded-md text-reghda-black text-lg">
            لا توجد روابط
          </div>
        )}
        {data?.map((item) => (
          <div
            key={item.id}
            className="flex flex-row items-center p-2 shadow-md rounded-md bg-white">
            <div
              onClick={() => {
                const url = `https://ragdasairafi369.com/payed-courses/course-detail/${item.ref_slug}?ref_by=${item.coupon}`;
                window.open(url, "_blank");
              }}
              className="cursor-pointer w-full flex flex-col md:flex-row items-center p-2 mx-2 text-reghda-blue">
              <div className="w-full md:w-1/2">رابط ذو القسيمة :</div>
              <div className="w-full md:w-1/2">{item?.coupon}</div>
            </div>
            <div className="flex flex-col space-y-4 md:flex-row md:space-y-0">
              <button
                className="cursor-pointer bg-green-300 text-white px-4 py-2 rounded-md ml-2 outline-none focus:outline-none"
                onClick={async () => {
                  await navigator.clipboard.writeText(item.ref_link);
                  alert("تم نسخ");
                }}>
                <IconContext.Provider
                  value={{ size: "1em", style: { color: "#fff" } }}>
                  <AiFillCopy></AiFillCopy>
                </IconContext.Provider>
              </button>
              <button
                className="cursor-pointer bg-red-400 text-white px-4 py-2 rounded-md ml-2 outline-none focus:outline-none"
                onClick={() => {
                  setLinkId(item.id);
                  setOpenModal(true);
                }}>
                <IconContext.Provider
                  value={{ size: "1em", style: { color: "#fff" } }}>
                  <AiFillDelete></AiFillDelete>
                </IconContext.Provider>
              </button>
            </div>
          </div>
        ))}
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>

      <DeleteModal
        isOpen={openModal}
        closing={() => setOpenModal(false)}
        deleteFn={() => handleDelete(linkId)}
      />
    </div>
  );
};

export default AllLinks;
