import { CircularProgress, IconButton } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useState } from "react";

import {
  deleteWithdrawOption,
  deleteWithdrawRequest,
} from "../../../../../services/affiliate";

import { getTheTokenFromStorage } from "../../../../../services/auth";
import DeleteIcon from "@material-ui/icons/Delete";
import useSWR from "swr";
import { domain } from "../../../../../global/domain";
import { fetcherAuth } from "../../../../../services/fetcher";
import { useHistory } from "react-router-dom";

import DeleteModal from "./DeleteModal";
import LoaderModal from "../LoaderModal";

const AllWithdrawRequests = () => {
  const token = getTheTokenFromStorage();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [withdrawOptionId, setWithdrawOptionId] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { data, mutate } = useSWR(
    [`https://${domain}/payments/affiliate/withdraw/`, token],
    fetcherAuth
  );

  const handleDelete = async (id) => {
    setOpenModal(false);
    setLoading(true);
    const res = await deleteWithdrawRequest(id, token);
    if (res?.status === 204) {
      mutate();
    } else {
      alert(res?.data?.message);
    }
    setLoading(false);
  };

  return (
    <div className=" mb-8 space-y-10">
      <div className="bg-white x-sm:w-full md:w-full rounded-lg p-4 md:p-8  flex flex-col space-y-4 md:space-y-8 2xl:space-y-16">
        <h1 className="text-2xl"> جميع طلبات السحب</h1>
        {data?.length === 0 && (
          <div className="text-center p-4 border rounded-md text-reghda-blue">
            لا توجد طلبات سحب
          </div>
        )}
        {data?.length > 0 && (
          <table className="w-full border-collapse w-full text-center x-sm:text-xs sm:text-sm md:text-lg 2xl:text-3xl">
            <tr>
              <th className="border-2 border-solid border-gray-200 p-4">رقم</th>
              <th className="border-2 border-solid border-gray-200 p-4">
                اسم االمستخدم
              </th>
              <th className="border-2 border-solid border-gray-200 p-4 hidden md:table-cell">
                إسم الحساب{" "}
              </th>
              <th className="border-2 border-solid border-gray-200 p-4 ">
                الحالة{" "}
              </th>
              <th className="border-2 border-solid border-gray-200 p-4 ">
                مبلغ{" "}
              </th>
              <th className="border-2 border-solid border-gray-200 p-4 ">
                حذف{" "}
              </th>
            </tr>

            {data?.map((withdraw, i) => {
              return (
                <tr
                  className="w-full hover:text-gray-400 cursor-pointer"
                  key={withdraw.id}>
                  <td
                    className="border-2 border-solid border-gray-200 p-4"
                    onClick={() =>
                      history.push(`/admin/affiliate/withdraw/${withdraw.id}`)
                    }>
                    {withdraw.id}
                  </td>{" "}
                  <td
                    className="border-2 border-solid border-gray-200 sm:p-4"
                    onClick={() =>
                      history.push(`/admin/affiliate/withdraw/${withdraw.id}`)
                    }>
                    {withdraw?.user?.username}
                  </td>
                  <td
                    className="border-2 border-solid border-gray-200 p-4 hidden md:table-cell"
                    onClick={() =>
                      history.push(`/admin/affiliate/withdraw/${withdraw.id}`)
                    }>
                    {withdraw?.withdraw_option?.account_name}
                  </td>
                  <td
                    className="border-2 border-solid border-gray-200 p-4"
                    onClick={() =>
                      history.push(`/admin/affiliate/withdraw/${withdraw.id}`)
                    }>
                    {withdraw?.status}
                  </td>
                  <td
                    className="border-2 border-solid border-gray-200 p-4"
                    onClick={() =>
                      history.push(`/admin/affiliate/withdraw/${withdraw.id}`)
                    }>
                    {withdraw?.amount}
                  </td>
                  <td className="border-2 border-solid border-gray-200 p-4">
                    <IconButton
                      onClick={() => {
                        setWithdrawOptionId(withdraw.id);
                        setOpenModal(true);
                      }}>
                      <DeleteIcon />
                    </IconButton>
                  </td>
                </tr>
              );
            })}
          </table>
        )}
        {!data && (
          <div className="h-48 flex justify-center items-center w-full">
            <CircularProgress />
          </div>
        )}
      </div>

      <DeleteModal
        isOpen={openModal}
        closing={() => setOpenModal(false)}
        deleteFn={() => handleDelete(withdrawOptionId)}
      />
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default AllWithdrawRequests;
