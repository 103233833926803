import React from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { useWindowWidth } from "@react-hook/window-size/throttled";

const CourseModal = ({ isOpen, closing }) => {
  const screenWidth = useWindowWidth();

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: screenWidth >= 768 ? "40%" : "80%",
        },
      }}
    >
      <div class="flex flex-row justify-end pb-3">
        <div
          onClick={closing}
          class="self-end cursor-pointer z-50 text-reghda-blue"
        >
          <svg
            class="fill-current text-black"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>
      </div>
      <div className="flex flex-col 2xl:m-16 space-y-4 2xl:space-y-12 justify-around items-center">
        <Link to="/admin/normal-courses/add-course/free">
          <div className="border-2 border-reghda-blue rounded-lg text-reghda-blue x-sm:text-base sm:text-lg md:text-2xl 2xl:text-5xl x-sm:p-2 md:p-4 cursor-pointer">
            دورة مجانية
          </div>
        </Link>
        <Link to="/admin/normal-courses/add-course/paid">
          <div className="border-2 border-reghda-blue rounded-lg text-reghda-blue x-sm:text-base sm:text-lg md:text-2xl 2xl:text-5xl x-sm:p-2 md:p-4 cursor-pointer">
            دورة مدفوعة
          </div>
        </Link>
      </div>
    </Modal>
  );
};

export default CourseModal;
