import Axios from "axios";
import { domain } from "./../global/domain";

export async function addCategory(data) {
  try {
    const response = await Axios.post(
      `https://${domain}/blog/categories/`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function deleteCategory(id) {
  try {
    const response = await Axios.delete(
      `https://${domain}/blog/categories/${id}`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function addBlog(data) {
  try {
    const response = await Axios.post(`https://${domain}/blog/posts/`, data);
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function editBlog(data, id) {
  try {
    const response = await Axios.patch(
      `https://${domain}/blog/posts/${id}/`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function deleteBlog(id) {
  try {
    const response = await Axios.delete(`https://${domain}/blog/posts/${id}/`);
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
