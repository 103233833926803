import React from "react";
import ProfileForm from "./content/settings/ProfileForm";
import PasswordForm from "./content/settings/PasswordForm";

const Settings = () => {
  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto ">
      <div className="p-8 text-right">
        <p className="md:text-base 2xl:text-2xl">الاعدادات</p>
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
          اعدادات الحساب
        </h3>
      </div>
      <div className="x-sm:px-3 md:px-6 2xl:p-12 mb-16 flex flex-col space-y-10">
        <div className="bg-white x-sm:w-full md:w-11/12 rounded-lg x-sm:p-6 md:p-12 2xl:p-24 font-semibold">
          <ProfileForm></ProfileForm>
        </div>
        <div className="bg-white x-sm:w-full md:w-11/12 rounded-lg x-sm:p-6 md:p-12 2xl:p-24 font-semibold">
          <PasswordForm></PasswordForm>
        </div>
      </div>
    </div>
  );
};

export default Settings;
