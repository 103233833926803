import React, { useState } from "react";
import CourseCard from "./content/overview/CourseCard";
import LoaderModal from "./content/LoaderModal";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../services/fetcher";
import { deleteCourse } from "./../../../services/followCourse";
import { domain } from "./../../../global/domain";
import { useHistory } from "react-router-dom";

const FollowCourses = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { data, error } = useSWR(
    `https://${domain}/courses/follow-up`,
    fetcher
  );

  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto">
      <div className="p-8 text-right">
        <p className="md:text-base 2xl:text-2xl">دورات متابعة</p>
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">عرض الدورات</h3>
      </div>
      <div className="md:px-6 2xl:p-12 mb-16 space-y-10">
        <div className="bg-white x-sm:w-full md:w-11/12 rounded-lg flex flex-col x-sm:p-2 md:p-4 2xl:p-12">
          <div className="flex flex-row space-x-1 items-center self-end x-sm:text-sm md:text-base 2xl:text-4xl">
            <div className="text-center">
              <button
                onClick={() => history.push(`/admin/follow-courses/add-course`)}
                className="bg-reghda-blue text-white  p-2 px-8 rounded-xl x-sm:text-sm md:text-base 2xl:text-4xl outline-none focus:outline-none">
                أضاف دورة
              </button>
            </div>
          </div>
          {data &&
            data.map((course, i) => (
              <CourseCard
                deleteCourse={async () => {
                  setLoading(true);
                  let res = await deleteCourse(course.id);
                  if (res && res.status === 204) {
                    mutate(`https://${domain}/courses/follow-up`);
                  } else {
                    alert("retry! something wrong");
                  }
                  setLoading(false);
                }}
                courseDetail={course}
                follow={true}></CourseCard>
            ))}
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default FollowCourses;
