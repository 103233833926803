import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import LoaderModal from "./content/LoaderModal";
import useSWR, { mutate } from "swr";
import { fetcher, fetcherAuth } from "./../../../services/fetcher";
import { domain } from "./../../../global/domain";
import { getTheTokenFromStorage } from "../../../services/auth";
import { Pagination } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";

const CoursesOrders = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const { data } = useSWR(
    [
      `https://${domain}/users/manage/all/?page=${page}&search=${search}`,
      getTheTokenFromStorage(),
    ],
    fetcherAuth
  );

  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto">
      <div className="p-8 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">المشتركين </h3>
      </div>
      <div className="x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-10">
        <div className="bg-white x-sm:w-full md:w-full rounded-lg p-4 md:p-8 2xl:p-16 flex flex-col space-y-4 md:space-y-8 2xl:space-y-16">
          <div className="flex items-center gap-1 rtl">
            <span className="md:w-24"> البحث </span>
            <textarea
              value={search}
              onChange={(e) => {
                setPage(1);
                setSearch(e.target.value);
              }}
              rows="1"
              id="title"
              type="text"
              class="w-2/4 md:ml-44 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
            />
          </div>

          <table className="w-full border-collapse w-full text-center x-sm:text-xs sm:text-sm md:text-lg 2xl:text-3xl">
            <tr>
              <th className="border-2 border-solid border-gray-200 p-4 ">
                مفعل
              </th>
              <th className="border-2 border-solid border-gray-200 p-4 hidden md:table-cell">
                الاسم
              </th>
              <th className="border-2 border-solid border-gray-200 p-4">
                البريد الإلكتروني
              </th>
              <th className="border-2 border-solid border-gray-200 p-4">رقم</th>
            </tr>
            {data &&
              data.results &&
              data.results.map((user, i) => {
                return (
                  <tr
                    onClick={() => {
                      history.push(`/admin/user/${user.id}`);
                    }}
                    className="w-full hover:text-gray-300 cursor-pointer"
                    key={i}>
                    <td className="border-2 border-solid border-gray-200 p-4">
                      {user.is_active && (
                        <span className="text-green-300">نعم</span>
                      )}
                    </td>

                    <td className="border-2 border-solid border-gray-200 p-4 hidden md:table-cell">
                      {user.username}
                    </td>
                    <td className="border-2 border-solid border-gray-200 sm:p-4">
                      {user.email}
                    </td>
                    <td className="border-2 border-solid border-gray-200 p-4">
                      {user.id}
                    </td>
                  </tr>
                );
              })}
          </table>
          {!data && (
            <div className="h-48 flex justify-center items-center w-full">
              <CircularProgress />
            </div>
          )}
          {/* <div className="flex flex-row space-x-4 items-center justify-end md:text-base 2xl:text-2xl">
            <p className="bg-reghda-blue px-2 rounded-l-2xl text-white cursor-pointer">
              السابق
            </p>
            <p className="text-reghda-blue">1</p>
            <p className="bg-reghda-blue px-2 rounded-r-2xl text-white cursor-pointer">
              التالي
            </p>
          </div> */}
        </div>
        {data && Math.ceil(data.count / 50) > 1 && (
          <div className="flex justify-center bg-white p-2 rounded-md">
            <Pagination
              count={Math.ceil(data.count / 50)}
              page={page}
              onChange={(e, page) => setPage(page)}
            />
          </div>
        )}
      </div>

      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default CoursesOrders;
