import React from "react";
import { IconContext } from "react-icons";
import { BsCalendar } from "react-icons/bs";
import DateRow from "./../overview/DateRow";
import useSwr from "swr";
import { fetcher } from "../../../../../services/fetcher";
import { domain } from "./../../../../../global/domain";

const ComingSession = () => {
  const { data, error } = useSwr(
    `https://${domain}/appointments/coming-sessions`,
    fetcher
  );

  if (data) {
    console.log(data);
  }
  return (
    <div className="bg-white rounded-xl flex flex-col x-sm:p-2 md:p-4 2xl:p-8">
      <div className="flex flex-row space-x-1 items-center self-end md:text-base 2xl:text-4xl">
        <h3>الجلسات القادمة</h3>
        <IconContext.Provider
          value={{ size: "1em", style: { color: "#5b5b5b" } }}>
          <BsCalendar></BsCalendar>
        </IconContext.Provider>
      </div>
      {data && (
        <div className="flex flex-col">
          {data.map((date) => (
            <DateRow key={date.id} date={date} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ComingSession;
