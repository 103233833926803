import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import useSWR, { mutate } from "swr";
import { domain } from "../../../../../global/domain";
import {
  deleteReferrer,
  editReferrer,
} from "../../../../../services/affiliate";
import { getTheTokenFromStorage } from "../../../../../services/auth";
import { fetcherAuth } from "../../../../../services/fetcher";
import LoaderModal from "../LoaderModal";
import DeleteModal from "./DeleteModal";

const AllReferrers = ({ data, mutate }) => {
  const token = getTheTokenFromStorage();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [referrerId, setReferrerId] = useState(null);

  const history = useHistory();

  //Query all Campaigns

  const handleDelete = async (id) => {
    setOpenModal(false);
    setLoading(true);
    const res = await deleteReferrer(id, token);
    if (res?.status === 204) {
      mutate();
    } else {
      alert(res?.data?.message);
    }
    setLoading(false);
  };

  const handleAcceptReferrer = async (id) => {
    setLoading(true);
    const res = await editReferrer({ status: "مقبول" }, id, token);
    if (res?.status === 200) {
      mutate();
    } else {
      alert(res?.data?.message);
    }
    setLoading(false);
  };

  const handleRejectReferrer = async (id) => {
    setLoading(true);
    const res = await editReferrer({ status: "مرفوض" }, id, token);
    if (res?.status === 200) {
      mutate();
    } else {
      alert(res?.data?.message);
    }
    setLoading(false);
  };

  return (
    <div>
      <h1 className="pb-4 text-xl font-semibold">جميع مسوقيين</h1>
      <table className="w-full border-collapse w-full text-center x-sm:text-xs sm:text-sm md:text-lg 2xl:text-3xl">
        <tr>
          <th className="border-2 border-solid border-gray-200 p-4">رقم</th>
          <th className="border-2 border-solid border-gray-200 p-4 hidden md:table-cell">
            الاسم
          </th>
          <th className="border-2 border-solid border-gray-200 p-4">الحالة </th>
          <th className="border-2 border-solid border-gray-200 p-4">حذف </th>
        </tr>
        {data?.map((user, i) => {
          return (
            <tr
              className="w-full hover:text-gray-300"
              key={i}
              // onClick={() =>
              //   history.push(`/admin/affiliate/referrers/${user.id}`)
              // }
            >
              <td className="border-2 border-solid border-gray-200 p-4">
                {user?.user?.id}
              </td>
              <td className="border-2 border-solid border-gray-200 p-4 hidden md:table-cell">
                {user?.user?.username}
              </td>
              <td className="border-2 border-solid border-gray-200 sm:p-4">
                {user?.status}
              </td>
              <td className="border-2 border-solid border-gray-200 sm:p-4 ">
                <div className="flex gap-2 justify-center">
                  {user?.status === "في الإنتظار" && (
                    <>
                      <button
                        onClick={() => handleAcceptReferrer(user?.id)}
                        className="px-4 py-2 rounded-md bg-green-600 text-white"
                      >
                        قبول
                      </button>
                      <button
                        onClick={() => handleRejectReferrer(user?.id)}
                        className="px-4 py-2 rounded-md bg-yellow-600 text-white"
                      >
                        رفض
                      </button>
                    </>
                  )}
                  <button
                    className="px-4 py-2 rounded-md bg-red-600 text-white"
                    onClick={() => {
                      setReferrerId(user.id);
                      setOpenModal(true);
                    }}
                  >
                    حذف
                  </button>
                </div>
              </td>
            </tr>
          );
        })}
      </table>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
      <DeleteModal
        isOpen={openModal}
        closing={() => setOpenModal(false)}
        deleteFn={() => handleDelete(referrerId)}
      />
    </div>
  );
};

export default AllReferrers;
