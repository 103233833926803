import React, { useState } from "react";
import { IconContext } from "react-icons";
import { AiFillFolder } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import PreviewFile from "./../media-center-modal/PreviewFile";
import PreviewImage from "./../media-center-modal/PreviewImage";
import PreviewAudio from "./../media-center-modal/PreviewAudio";
import PreviewVideo from "./../media-center-modal/PreviewVideo";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../../../services/fetcher";
import { domain } from "./../../../../../global/domain";
import { iconsColor } from "./../../../../../global/iconsColor";

const MediaCenter = ({ addItem, setIsOpen }) => {
  const [chooseOpen, setChooseOpen] = useState(true);
  const [filesUploadOpen, setFilesUploadOpen] = useState(false);
  const [imageUploadOpen, setImageUploadOpen] = useState(false);
  const [audioUploadOpen, setAudioUploadOpen] = useState(false);
  const [videoUploadOpen, setVideoUploadOpen] = useState(false);
  const [url, setUrl] = useState(`https://${domain}/media-library/files/`);

  const { data, error } = useSWR(url, fetcher);

  return (
    <div className="h-full flex flex-col">
      <div className="bg-white x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-10">
        {chooseOpen && (
          <div className="x-sm:w-full md:w-11/12 rounded-lg flex x-sm:flex-col md:flex-row x-sm:p-2 md:p-4 2xl:p-12 x-sm:text-xxs md:text-sm 2xl:text-3xl">
            <div
              onClick={() => {
                setChooseOpen(false);
                setFilesUploadOpen(true);
                setUrl(`https://${domain}/media-library/files/`);
              }}
              className="flex flex-col items-center cursor-pointer">
              <IconContext.Provider
                value={{ size: "8em", style: { color: iconsColor } }}>
                <AiFillFolder></AiFillFolder>
              </IconContext.Provider>
              <p>مرفقات</p>
            </div>
            <div
              onClick={() => {
                setChooseOpen(false);
                setImageUploadOpen(true);
                setUrl(`https://${domain}/media-library/images/`);
              }}
              className="flex flex-col items-center cursor-pointer">
              <IconContext.Provider
                value={{ size: "8em", style: { color: iconsColor } }}>
                <AiFillFolder></AiFillFolder>
              </IconContext.Provider>
              <p>صور</p>
            </div>
            <div
              onClick={() => {
                setChooseOpen(false);
                setAudioUploadOpen(true);
                setUrl(`https://${domain}/media-library/audios/`);
              }}
              className="flex flex-col items-center cursor-pointer">
              <IconContext.Provider
                value={{ size: "8em", style: { color: iconsColor } }}>
                <AiFillFolder></AiFillFolder>
              </IconContext.Provider>
              <p>اوديو</p>
            </div>
            <div
              onClick={() => {
                setChooseOpen(false);
                setVideoUploadOpen(true);
                setUrl(`https://${domain}/media-library/videos/`);
              }}
              className="flex flex-col items-center cursor-pointer">
              <IconContext.Provider
                value={{ size: "8em", style: { color: iconsColor } }}>
                <AiFillFolder></AiFillFolder>
              </IconContext.Provider>
              <p>فيديو</p>
            </div>
          </div>
        )}
        {filesUploadOpen && (
          <div className="x-sm:w-full md:w-11/12 rounded-lg flex flex-row x-sm:p-2 md:p-4 2xl:p-12 x-sm:text-xxs md:text-sm 2xl:text-4xl">
            <div className="grid x-sm:grid-cols-1 md:grid-cols-4 2xl:grid-cols-4 gap-x-16 gap-y-10 justify-around h-72">
              <div
                onClick={() => {
                  setChooseOpen(true);
                  setFilesUploadOpen(false);
                  setImageUploadOpen(false);
                  setAudioUploadOpen(false);
                  setVideoUploadOpen(false);
                }}
                class="flex flex-col items-center space-y-2 md:text-xs 2xl:text-lg cursor-pointer">
                <IconContext.Provider
                  value={{ size: "8em", style: { color: iconsColor } }}>
                  <BiArrowBack></BiArrowBack>
                </IconContext.Provider>
                <p className="text-center break-words">عودة</p>
              </div>
              {data &&
                data.length > 0 &&
                data.map((file, i) => {
                  return (
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        addItem({ from: "media", type: "file", file });
                        setIsOpen(false);
                      }}>
                      <PreviewFile
                        key={i}
                        file={file}
                        name={file.name}></PreviewFile>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        {imageUploadOpen && (
          <div className="x-sm:w-full md:w-11/12 rounded-lg flex flex-row x-sm:p-2 md:p-4 2xl:p-12 x-sm:text-xs md:text-sm 2xl:text-4xl">
            <div className="grid md:grid-cols-4 2xl:grid-cols-4 gap-x-16 gap-y-10 justify-around h-72">
              <div
                onClick={() => {
                  setChooseOpen(true);
                  setFilesUploadOpen(false);
                  setImageUploadOpen(false);
                  setAudioUploadOpen(false);
                  setVideoUploadOpen(false);
                }}
                class="flex flex-col items-center space-y-2 x-sm:text-xxs md:text-xs 2xl:text-lg cursor-pointer">
                <IconContext.Provider
                  value={{ size: "8em", style: { color: iconsColor } }}>
                  <BiArrowBack></BiArrowBack>
                </IconContext.Provider>
                <p className="text-center break-words">عودة</p>
              </div>
              {data &&
                data.length > 0 &&
                data.map((file, i) => {
                  return (
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        addItem({ from: "media", type: "image", file });
                        setIsOpen(false);
                      }}>
                      <PreviewImage
                        key={i}
                        file={file}
                        name={file.name}></PreviewImage>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        {audioUploadOpen && (
          <div className="x-sm:w-full md:w-11/12 rounded-lg flex flex-row x-sm:p-2 md:p-4 2xl:p-12 x-sm:text-xs md:text-sm 2xl:text-4xl">
            <div className="grid md:grid-cols-4 2xl:grid-cols-4 gap-x-16 gap-y-10 justify-around h-72">
              <div
                onClick={() => {
                  setChooseOpen(true);
                  setFilesUploadOpen(false);
                  setImageUploadOpen(false);
                  setAudioUploadOpen(false);
                  setVideoUploadOpen(false);
                }}
                class="flex flex-col items-center space-y-2 x-sm:text-xxs md:text-xs 2xl:text-lg cursor-pointer">
                <IconContext.Provider
                  value={{ size: "8em", style: { color: iconsColor } }}>
                  <BiArrowBack></BiArrowBack>
                </IconContext.Provider>
                <p className="text-center break-words">عودة</p>
              </div>
              {data &&
                data.length > 0 &&
                data.map((file, i) => {
                  return (
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        addItem({ from: "media", type: "audio", file });
                        setIsOpen(false);
                      }}>
                      <PreviewAudio
                        key={i}
                        file={file}
                        name={file.name}></PreviewAudio>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        {videoUploadOpen && (
          <div className="x-sm:w-full md:w-11/12 rounded-lg flex flex-row x-sm:p-2 md:p-4 2xl:p-12 x-sm:text-xs md:text-sm 2xl:text-4xl">
            <div className="grid md:grid-cols-4 2xl:grid-cols-4 gap-x-16 gap-y-10 justify-around h-72">
              <div
                onClick={() => {
                  setChooseOpen(true);
                  setFilesUploadOpen(false);
                  setImageUploadOpen(false);
                  setAudioUploadOpen(false);
                  setVideoUploadOpen(false);
                }}
                class="flex flex-col items-center space-y-2 x-sm:text-xxs md:text-xs 2xl:text-lg cursor-pointer">
                <IconContext.Provider
                  value={{ size: "8em", style: { color: iconsColor } }}>
                  <BiArrowBack></BiArrowBack>
                </IconContext.Provider>
                <p className="text-center break-words">عودة</p>
              </div>
              {data &&
                data.length > 0 &&
                data.map((file, i) => {
                  return (
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        addItem({ from: "media", type: "video", file });
                        setIsOpen(false);
                      }}>
                      <PreviewVideo
                        key={i}
                        file={file}
                        name={file.name}></PreviewVideo>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaCenter;
