import React, { useState } from "react";
import CourseCard from "./content/subscribers/CourseCard";
import LoaderModal from "./content/LoaderModal";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../services/fetcher";
import { domain } from "./../../../global/domain";

const Subscribers = () => {
  const [loading, setLoading] = useState(false);

  const { data, error } = useSWR(
    `https://${domain}/courses/follow-up`,
    fetcher
  );

  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto">
      <div className="p-8 text-right">
        <p className="md:text-base 2xl:text-2xl">برامج المتابعة</p>
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
          متابعة المشتركين
        </h3>
      </div>
      <h3 className="text-center md:text-4xl 2xl:text-7xl font-extrabold">
        إختر دورة لكي ترى متابعينها
      </h3>
      <div className="x-sm:p-8 md:p-16 h-full grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-8 justify-around">
        {data &&
          data.map((course, i) => <CourseCard course={course}></CourseCard>)}
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default Subscribers;
