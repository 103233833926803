import React, { useState } from "react";
import Modal from "react-modal";
import LoaderModal from "../LoaderModal";
import { unlockChapter } from "../../../../../services/followCourses";
import { getTheUserFromStorage } from "../../../../../services/auth";

const OpenChapterModal = ({ isOpen, closing, mutate, chapterId, userId }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
        },
      }}
    >
      <div class="flex flex-row justify-end pb-3">
        <div
          onClick={closing}
          class="self-end cursor-pointer z-50 text-reghda-blue"
        >
          <svg
            class="fill-current text-black"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center x-sm:space-y-5 md:space-y-10 2xl:space-y-16">
        <h3 className="text-reghda-blue font-semibold md:text-base 2xl:text-3xl">
          هل أنت متاكد من انك تريد فتح هذا الفصل للمشترك ؟
        </h3>
        <button
          onClick={async () => {
            setLoading(true);
            const res = await unlockChapter({
              user: userId,
              chapter: chapterId,
            });
            console.log(res, "🎉");
            setLoading(false);
            mutate();
            closing();
          }}
          className="x-sm:w-full md:w-1/2 bg-reghda-blue text-center text-white py-1 px-2 rounded-xl md:text-base 2xl:text-3xl outline-none focus:outline-none"
        >
          تاكيد
        </button>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </Modal>
  );
};

export default OpenChapterModal;
