import React, { useState } from "react";
import { IconContext } from "react-icons";
import { FaRegFilePdf } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { domain } from "./../../../../../global/domain";
import { iconsColor } from "./../../../../../global/iconsColor";

const PreviewFile = ({ file, deleteOne }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      className="flex flex-col items-center space-y-2 md:text-xs 2xl:text-lg cursor-pointer">
      <a
        href={file.uploaded_file}
        className="flex flex-col items-center space-y-2"
        target="_blank"
        rel="noreferrer">
        <IconContext.Provider
          value={{ size: "8em", style: { color: iconsColor } }}>
          <FaRegFilePdf></FaRegFilePdf>
        </IconContext.Provider>
        {!hover && (
          <p className="text-center break-all">
            {file.uploaded_file
              .replace(`http://${domain}/media/files/`, "")
              .replace(`http://${domain}/media/books/`, "")}
          </p>
        )}
      </a>
      {hover && (
        <div onClick={deleteOne}>
          <IconContext.Provider
            value={{ size: "2em", style: { color: "#e53e3e" } }}>
            <AiOutlineClose></AiOutlineClose>
          </IconContext.Provider>
        </div>
      )}
    </div>
  );
};

export default PreviewFile;
