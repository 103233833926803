import React, { useState } from "react";
import Modal from "react-modal";
import LoaderModal from "../LoaderModal";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { enroll } from "../../../../../services/enrollment";

const AddSubscriberModal = ({ isOpen, closing, mutate, courseId }) => {
  const screenWidth = useWindowWidth();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("عنوان البريد الإلكتروني غير صالح")
      .required("هذا الحقل مطلوب"),
  });

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: screenWidth >= 768 ? "40%" : "80%",
        },
      }}
    >
      <div class="flex flex-row justify-end pb-3">
        <div
          onClick={closing}
          class="self-end cursor-pointer z-50 text-reghda-blue"
        >
          <svg
            class="fill-current text-black"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center x-sm:space-y-5 md:space-y-10 2xl:space-y-16">
        <h3 className="text-reghda-blue font-semibold">معلومات المشترك</h3>

        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setLoading(true);
            let res = await enroll({
              email: values.email,
              course: [courseId],
              book: [],
            });
            if (res && res.status === 201) {
              mutate();
            } else if (res && res.status === 200) {
              alert(res.data.message);
            } else {
              alert("retry! something wrong");
            }
            setSubmitting(false);
            setLoading(false);
            closing();
          }}
        >
          <Form className="text-white x-sm:space-y-2 md:space-y-4 2xl:space-y-6">
            <Field
              className="x-sm:w-full text-reghda-black text-right p-1 pr-6 rounded-2xl border-2 border-gray-200 outline-none focus:outline-none"
              placeholder="البريد الالكتروني"
              name="email"
              type="email"
            />
            <div className="x-sm:w-full text-center text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="email" />
            </div>
            <button
              type="submit"
              className="x-sm:w-full bg-reghda-blue text-center text-white py-1 px-2 rounded-xl md:text-base 2xl:text-3xl"
            >
              تاكيد
            </button>
          </Form>
        </Formik>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </Modal>
  );
};

export default AddSubscriberModal;
