import React, { useState } from "react";
import CourseCard from "./content/overview/CourseCard";
import CourseModal from "./content/normal-courses/CourseModal";
import LoaderModal from "./content/LoaderModal";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../services/fetcher";
import { deleteCourse } from "./../../../services/course";
import { domain } from "./../../../global/domain";

const NormalCourses = () => {
  const [loading, setLoading] = useState(false);

  const [active, setActive] = useState("all");
  const [coursesUrl, setCoursesUrl] = useState(
    `https://${domain}/courses/all/`
  );

  const { data, error } = useSWR(coursesUrl, fetcher);
  // const { data2, error2 } = useSWR(`https://ragda.site/courses/paid/`, fetcher);

  const [modalIsOpen, setIsOpen] = useState(false);

  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto">
      <div className="p-8 text-right">
        <p className="md:text-base 2xl:text-2xl">دورات عادية</p>
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">عرض الدورات</h3>
      </div>
      <div className="x-sm:px-2 md:px-6 2xl:p-12 mb-16 space-y-10">
        <div className="bg-white text-reghda-black x-sm:w-full md:w-11/12 flex flex-row justify-end rounded-lg x-sm:p-1 md:p-2 2xl:p-4 x-sm:space-x-2 md:space-x-8 2xl:space-x-16 font-semibold x-sm:text-sm md:text-lg 2xl:text-4xl">
          <p
            onClick={() => {
              setCoursesUrl(`https://${domain}/courses/paid/`);
              mutate(`https://${domain}/courses/paid/`);
              setActive("paid");
            }}
            // className="cursor-pointer"
            className={`cursor-pointer ${
              active === "paid" ? "text-reghda-blue" : ""
            }`}>
            الدورات المدفوعة
          </p>
          <p
            onClick={() => {
              setCoursesUrl(`https://${domain}/courses/free/`);
              mutate(`https://${domain}/courses/free/`);
              setActive("free");
            }}
            // className="cursor-pointer"
            className={`cursor-pointer ${
              active === "free" ? "text-reghda-blue" : ""
            }`}>
            الدورات المجانية
          </p>
          <p
            onClick={() => {
              setCoursesUrl(`https://${domain}/courses/all/`);
              mutate(`https://${domain}/courses/all/`);
              setActive("all");
            }}
            // className="cursor-pointer text-reghda-blue"
            className={`cursor-pointer ${
              active === "all" ? "text-reghda-blue" : ""
            }`}>
            الكل
          </p>
        </div>
        <div className="bg-white x-sm:w-full md:w-11/12 rounded-lg flex flex-col x-sm:p-2 md:p-4 2xl:p-12">
          <div className="flex flex-row space-x-1 items-center self-end x-sm:text-sm md:text-base 2xl:text-4xl">
            <div className="text-center">
              <button
                onClick={() => setIsOpen(true)}
                className="bg-reghda-blue text-white  p-2 px-8 rounded-xl x-sm:text-sm md:text-base 2xl:text-4xl outline-none focus:outline-none">
                أضاف دورة
              </button>
            </div>
          </div>
          {data &&
            data.map((course, i) => (
              <CourseCard
                deleteCourse={async () => {
                  setLoading(true);
                  let res = await deleteCourse("all", course.id);
                  if (res && res.status === 204) {
                    mutate(coursesUrl);
                  } else {
                    alert("retry! something wrong");
                  }
                  setLoading(false);
                }}
                courseDetail={course}></CourseCard>
            ))}
        </div>
      </div>
      <CourseModal
        isOpen={modalIsOpen}
        closing={() => setIsOpen(false)}></CourseModal>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default NormalCourses;
