import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import LoaderModal from "./content/LoaderModal";
import CategoryModal from "./content/sessions/CategoryModal";
import SessionCard from "./content/sessions/SessionCard";
import CalendarModal from "./content/sessions/CalendarModal";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../services/fetcher";
import { domain } from "./../../../global/domain";
import { deleteAppointment } from "../../../services/appointments";

const Sessions = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);

  const { data, error } = useSWR(
    `https://${domain}/appointments/sessions/`,
    fetcher
  );

  return (
    <div className="min-h-screen h-full w-full flex flex-col overflow-y-auto">
      <div className="p-8 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">حجوزات</h3>
      </div>
      <div className="x-sm:px-2 md:px-6 2xl:p-12 space-y-10">
        <div className="x-sm:w-full rounded-lg flex flex-col x-sm:p-2 md:p-4 2xl:p-12">
          <div className="flex flex-row space-x-4 items-center self-end x-sm:text-sm md:text-base 2xl:text-4xl">
            <div className="text-center">
              <button
                onClick={() => {
                  setCalendarOpen(true);
                }}
                className="bg-reghda-blue text-white  p-2 px-8 rounded-xl x-sm:text-xs md:text-base 2xl:text-4xl outline-none focus:outline-none">
                أضف حجز
              </button>
            </div>
            <div className="text-center">
              <button
                onClick={() => {
                  setIsOpen(true);
                }}
                className="bg-reghda-blue text-white  p-2 px-8 rounded-xl x-sm:text-xs md:text-base 2xl:text-4xl outline-none focus:outline-none">
                أصناف
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full x-sm:p-4 md:p-8 2xl:p-12">
        <div className="text-center text-reghda-black font-bold  w-full x-sm:p-2  md:p-4 2xl:p-8 flex flex-row items-center">
          <h6 className="x-sm:w-1/6 md:w-2/12 x-sm:text-xxs sm:text-xs md:text-base 2xl:text-2xl"></h6>
          <h6 className="x-sm:w-1/6 md:w-1/12 x-sm:text-xxs md:text-base 2xl:text-2xl">
            التوفر
          </h6>
          <h6 className="x-sm:w-1/6 md:w-2/12 flex flex-row items-center justify-center space-x-2 x-sm:text-xxs sm:text-xs md:text-base 2xl:text-2xl">
            السعر
          </h6>
          <h6 className="x-sm:w-1/6 md:w-2/12 flex flex-row items-center justify-center space-x-2 x-sm:text-xxs sm:text-xs md:text-base 2xl:text-2xl">
            المدة
          </h6>
          <h6 className="x-sm:w-1/6 md:w-1/12 x-sm:text-xxs sm:text-xs md:text-base 2xl:text-2xl">
            الوقت
          </h6>
          <h6 className="x-sm:w-1/6 md:w-2/12 x-sm:text-xxs sm:text-xs md:text-base 2xl:text-2xl">
            اليوم
          </h6>
          <h1 className="x-sm:w-1/6 md:w-2/12 x-sm:text-xxs sm:text-xs md:text-lg 2xl:text-2xl">
            الإسم
          </h1>
        </div>
        <div className="w-full space-y-6">
          {data &&
            data.map((session) => (
              <SessionCard
                deleteOne={async () => {
                  setLoading(true);
                  let res = await deleteAppointment(session.id);
                  if (res && res.status === 204) {
                    mutate(`https://${domain}/appointments/sessions/`);
                  } else {
                    alert("retry! something wrong");
                  }
                  setLoading(false);
                }}
                detail={session}></SessionCard>
            ))}
        </div>
      </div>
      <CalendarModal
        isOpen={calendarOpen}
        closing={() => setCalendarOpen(false)}
        mutate={() =>
          mutate(`https://${domain}/appointments/sessions/`)
        }></CalendarModal>
      <CategoryModal
        isOpen={modalIsOpen}
        closing={() => setIsOpen(false)}></CategoryModal>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default Sessions;
