import React, { useState } from "react";
import { IconContext } from "react-icons";
import { AiFillDelete } from "react-icons/ai";
import { iconsColor } from "./../../../../../global/iconsColor";
import ModalDelete from "./ModalDelete";

const SessionCard = ({ detail, deleteOne }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div className="w-full text-center rounded-xl shadow-2xl bg-white x-sm:p-2 md:p-4 2xl:p-8 flex flex-row items-center text-reghda-blue md:text-base 2xl:text-xl">
        <h6
          onClick={() => setOpenModal(true)}
          className="x-sm:w-1/6 md:w-2/12 x-sm:text-xxs sm:text-xs md:text-base 2xl:text-2xl cursor-pointer"
        >
          <IconContext.Provider
            value={{ size: "1.5em", style: { color: "#5b5b5b" } }}
          >
            <AiFillDelete></AiFillDelete>
          </IconContext.Provider>
        </h6>
        <h6 className="x-sm:w-1/6 md:w-1/12 x-sm:text-xxs sm:text-xs md:text-base 2xl:text-2xl">
          {detail.is_available ? (
            <span className="text-green-300">متوفر</span>
          ) : (
            <span className="text-red-500">غير متوفر</span>
          )}
        </h6>
        <h6 className="x-sm:w-1/6 md:w-2/12 x-sm:text-xxs sm:text-xs md:text-base 2xl:text-2xl flex flex-row items-center justify-center space-x-2">
          <span>ريال</span>
          <span>{detail.type.price}</span>
        </h6>
        <h6 className="x-sm:w-1/6 md:w-2/12 x-sm:text-xxs sm:text-xs md:text-base 2xl:text-2xl flex flex-row items-center justify-center space-x-2">
          <span>دقيقة</span>
          <span>{detail.type.duration}</span>
        </h6>
        <h6 className="x-sm:w-1/6 md:w-1/12 x-sm:text-xxs sm:text-xs md:text-base 2xl:text-2xl">
          {detail.time}
        </h6>
        <h6 className="x-sm:w-1/6 md:w-2/12 x-sm:text-xxs sm:text-xs md:text-base 2xl:text-2xl">
          {detail.date}
        </h6>
        <h1 className="x-sm:w-1/6 md:w-2/12 x-sm:text-xxs sm:text-xs md:text-base 2xl:text-2xl">
          {detail.type.title}
        </h1>
      </div>{" "}
      <ModalDelete
        open={openModal}
        handleClose={() => setOpenModal(false)}
        deleteOne={deleteOne}
      />
    </>
  );
};

export default SessionCard;
