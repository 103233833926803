import React, { useState, useEffect } from "react";
import cn from "classnames";
import SessionModal from "./SessionModal";

const TodaySessions = ({ data }) => {
  const [sessionModel, setSessionModel] = useState(false);
  const [clickedSession, setClickedSession] = useState(null);

  const allTimes = [
    "صباحا ۰۸:۰۰",
    "صباحا ۰۸:۳۰",
    "صباحا ۰۹:۰۰",
    "صباحا ۰۹:۳۰",
    "صباحا ١۰:۰۰",
    "صباحا ١۰:۳۰",
    "صباحا ١١:۰۰",
    "صباحا ١١:۳۰",
    "مساءا ١٢:۰۰",
    "مساءا ١٢:۳۰",
    "مساءا ۰١:۰۰",
    "مساءا ۰١:۳۰",
    "مساءا ۰٢:۰۰",
    "مساءا ۰٢:۳۰",
    "مساءا ۰۳:۰۰",
    "مساءا ۰۳:۳۰",
    "مساءا ۰٤:۰۰",
    "مساءا ۰٤:۳۰",
    "مساءا ۰۵:۰۰",
    "مساءا ۰۵:۳۰",
    "مساءا ۰٦:۰۰",
    "مساءا ۰٦:۳۰",
    "مساءا ۰۷:۰۰",
    "مساءا ۰۷:۳۰",
    "مساءا ۰۸:۰۰",
    "مساءا ۰۸:۳۰",
    "مساءا ۰۹:۰۰",
  ];

  console.log(data, "🎉");

  return (
    <div className="flex flex-col rounded-xl">
      {allTimes.map((e, i) => {
        let btr = false;
        let bbr = false;
        if (e === "صباحا ۰۸:۰۰") {
          btr = true;
        }
        if (e === "مساءا ۰۹:۰۰") {
          bbr = true;
        }
        let style1 = cn(
          "w-1/4 bg-reghda-blue text-white md:text-lg 2xl:text-5xl text-center p-4 flex flex-row items-center justify-center space-x-2",
          {
            "rounded-tr-xl": btr,
            "rounded-br-xl": bbr,
          }
        );

        return (
          <div key={i} className="flex flex-row ">
            {data &&
              data.length > 0 &&
              data.map((session) => {
                return session.disabled_times.map((time, i) => {
                  if (e === time) {
                    return (
                      <div
                        onClick={() => {
                          setClickedSession(session);
                          setSessionModel(true);
                        }}
                        className="border-0 cursor-pointer w-3/4 bg-reghda-black text-white md:text-lg 2xl:text-4xl text-center p-4 session flex flex-row items-center justify-around"
                      >
                        {i ===
                          Math.floor(session.disabled_times.length / 2) - 1 && (
                          <p>{session.title}</p>
                        )}
                        {i ===
                          Math.floor(session.disabled_times.length / 2) && (
                          <p>
                            {`${session.user.given_name} ${session.user.family_name}  مع`}
                          </p>
                        )}
                        {session.disabled_times.length === 1 && (
                          <p>{session.title}</p>
                        )}
                      </div>
                    );
                  } else {
                    return <div className="flex-auto"></div>;
                  }
                });
              })}
            {!data || (data.length < 1 && <div className="flex-auto"></div>)}
            <div className={style1}>
              <span> {`${e.split(" ")[0]}`}</span>
              <span>{`${e.split(" ")[1]}`}</span>
            </div>
          </div>
        );
      })}
      {clickedSession && (
        <SessionModal
          session={clickedSession}
          isOpen={sessionModel}
          closing={() => setSessionModel(false)}
        ></SessionModal>
      )}
    </div>
  );
};

export default TodaySessions;
