import Axios from "axios";
import { domain } from "../global/domain";

export async function addSaying(data) {
  try {
    const response = await Axios.post(`https://${domain}/about/sayings/`, data);
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function editSaying(id, data) {
  try {
    const response = await Axios.patch(
      `https://${domain}/about/sayings/${id}`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function deleteSaying(id) {
  try {
    const response = await Axios.delete(
      `https://${domain}/about/sayings/${id}/`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
