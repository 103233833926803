import React, { useState } from "react";
import { IconContext } from "react-icons";
import { FaRegFilePdf } from "react-icons/fa";
import { domain } from "./../../../../../global/domain";
import { iconsColor } from "./../../../../../global/iconsColor";

const PreviewFile = ({ file, deleteOne }) => {
  return (
    <div className="flex flex-col items-center space-y-2 md:text-xs 2xl:text-lg cursor-pointer">
      <div>
        <IconContext.Provider
          value={{ size: "8em", style: { color: iconsColor } }}>
          <FaRegFilePdf></FaRegFilePdf>
        </IconContext.Provider>
        <p className="text-center break-all">
          {file.uploaded_file
            .replace(`http://${domain}/media/files/`, "")
            .replace(`http://${domain}/media/books/`, "")}
        </p>
      </div>
    </div>
  );
};

export default PreviewFile;
