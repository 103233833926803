import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import LoaderModal from "./../../content/LoaderModal";
import useSWR, { mutate } from "swr";
import { fetcher, fetcherAuth } from "./../../../../../services/fetcher";
import { domain } from "./../../../../../global/domain";
import { Pagination } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import { getTheTokenFromStorage } from "../../../../../services/auth";
import { addReferrer } from "../../../../../services/affiliate";

const AddReferrer = ({ closeAddReferrer, mutate }) => {
  const history = useHistory();

  const token = getTheTokenFromStorage();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [referrerToAdd, setReferrerToAdd] = useState(null);

  const { data } = useSWR(
    [
      `https://${domain}/users/manage/all/?page=${page}&search=${search}`,
      token,
    ],
    fetcherAuth
  );

  const handleAddReferrer = async () => {
    setLoading(true);
    const res = await addReferrer({ user: referrerToAdd.id }, token);
    console.log(res);
    if (res?.status === 201) {
      mutate();
      setReferrerToAdd(null);
      closeAddReferrer();
    } else {
      alert(res?.data?.message);
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="py-4 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">المشتركين</h3>
      </div>
      {referrerToAdd ? (
        <div className="bg-white x-sm:w-full md:w-full rounded-lg p-4 md:p-8 2xl:p-16 flex flex-col space-y-4 md:space-y-8 2xl:space-y-16">
          <table className="w-full border-collapse w-full text-center x-sm:text-xs sm:text-sm md:text-lg 2xl:text-3xl">
            <tr>
              <th className="border-2 border-solid border-gray-200 p-4">رقم</th>
              <th className="border-2 border-solid border-gray-200 p-4">
                البريد الإلكتروني
              </th>
              <th className="border-2 border-solid border-gray-200 p-4 hidden md:table-cell">
                الاسم
              </th>
              <th className="border-2 border-solid border-gray-200 p-4 ">
                مفعل
              </th>
            </tr>

            <tr className="w-full hover:text-gray-300 cursor-pointer">
              <td className="border-2 border-solid border-gray-200 p-4">
                {referrerToAdd.id}
              </td>{" "}
              <td className="border-2 border-solid border-gray-200 sm:p-4">
                {referrerToAdd.email}
              </td>
              <td className="border-2 border-solid border-gray-200 p-4 hidden md:table-cell">
                {referrerToAdd.username}
              </td>
              <td className="border-2 border-solid border-gray-200 p-4">
                {referrerToAdd.is_active && (
                  <span className="text-green-300">نعم</span>
                )}
              </td>
            </tr>
          </table>
          <div className="flex justify-end gap-2">
            <button
              className="px-4 py-2 rounded-md bg-reghda-blue text-white"
              onClick={() => {
                handleAddReferrer();
              }}>
              أضف
            </button>
            <button
              className="px-4 py-2 rounded-md bg-red-600 text-white"
              onClick={() => {
                setReferrerToAdd(null);
              }}>
              إلغاء
            </button>
          </div>
        </div>
      ) : (
        <div className=" mb-8 space-y-10">
          <div className="bg-white x-sm:w-full md:w-full rounded-lg p-4 md:p-8 2xl:p-16 flex flex-col space-y-4 md:space-y-8 2xl:space-y-16">
            <div className="flex items-center gap-1 rtl">
              <span className="md:w-24"> البحث </span>
              <textarea
                value={search}
                onChange={(e) => {
                  setPage(1);
                  setSearch(e.target.value);
                }}
                rows="1"
                id="title"
                type="text"
                class="w-2/4 md:ml-44 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
              />
            </div>

            <table className="w-full border-collapse w-full text-center x-sm:text-xs sm:text-sm md:text-lg 2xl:text-3xl">
              <tr>
                <th className="border-2 border-solid border-gray-200 p-4">
                  رقم
                </th>
                <th className="border-2 border-solid border-gray-200 p-4">
                  البريد الإلكتروني
                </th>
                <th className="border-2 border-solid border-gray-200 p-4 hidden md:table-cell">
                  الاسم
                </th>
                <th className="border-2 border-solid border-gray-200 p-4 ">
                  مفعل
                </th>
              </tr>

              {data?.results?.map((user, i) => {
                return (
                  <tr
                    onClick={() => {
                      setReferrerToAdd(user);
                    }}
                    className="w-full hover:text-gray-300 cursor-pointer"
                    key={i}>
                    <td className="border-2 border-solid border-gray-200 p-4">
                      {user.id}
                    </td>{" "}
                    <td className="border-2 border-solid border-gray-200 sm:p-4">
                      {user.email}
                    </td>
                    <td className="border-2 border-solid border-gray-200 p-4 hidden md:table-cell">
                      {user.username}
                    </td>
                    <td className="border-2 border-solid border-gray-200 p-4">
                      {user.is_active && (
                        <span className="text-green-300">نعم</span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </table>
            {!data && (
              <div className="h-48 flex justify-center items-center w-full">
                <CircularProgress />
              </div>
            )}
          </div>
          {data && Math.ceil(data.count / 50) > 1 && (
            <div className="flex justify-center bg-white p-2 rounded-md">
              <Pagination
                count={Math.ceil(data.count / 50)}
                page={page}
                onChange={(e, page) => setPage(page)}
              />
            </div>
          )}
        </div>
      )}

      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default AddReferrer;
