import React, { useState } from "react";
import LoaderModal from "./content/LoaderModal";
import CategoryModal from "./content/store/CategoryModal";
import ProductCard from "./content/store/ProductCard";
import { useHistory } from "react-router-dom";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../services/fetcher";
import { domain } from "./../../../global/domain";
import { deleteProduct } from "../../../services/products";

import { IconContext } from "react-icons";
import { AiOutlineDown } from "react-icons/ai";
import { iconsColor } from "./../../../global/iconsColor";
import cn from "classnames";

const Store = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  const categories = useSWR(`https://${domain}/store/categories/`, fetcher);

  const [productsUrl, setProductsUrl] = useState(
    `https://${domain}/store/products/`
  );
  const [currentCategory, setCurrentCategory] = useState("الكل");

  const { data, error } = useSWR(productsUrl, fetcher);

  const [menuOpen, setMenuOpen] = useState(false);
  let menuStyle = cn("relative cursor-pointer", { dropdownBlog: menuOpen });

  let menuItemsStyle = cn(
    "mt-2 w-full text-white rounded-lg p-2 absolute hidden top-8 ",
    {
      dropdownContentBlog: menuOpen,
    }
  );

  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto">
      <div className="p-8 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">المتجر</h3>
      </div>
      <div className="x-sm:px-2 md:px-6 2xl:p-12 mb-16 space-y-10">
        <div className="x-sm:w-full md:w-11/12 rounded-lg flex flex-col x-sm:p-2 md:p-4 2xl:p-12">
          <div className="text-reghda-black x-sm:w-full flex flex-row items-center justify-center rounded-lg x-sm:p-1 md:p-2 2xl:p-4  font-semibold x-sm:text-sm md:text-lg 2xl:text-4xl">
            <div className="w-1/2 flex flex-row items-center justify-start">
              <div className={`${menuStyle}`}>
                <button
                  onClick={() => setMenuOpen(!menuOpen)}
                  className="self-start flex flex-row items-center justify-center space-x-2 bg-white text-reghda-blue font-extrabold p-2 md:px-8 rounded-xl x-sm:text-sm md:text-base 2xl:text-4xl outline-none focus:outline-none shadow-xl">
                  <span>
                    <IconContext.Provider
                      value={{ size: "1em", style: { color: iconsColor } }}>
                      <AiOutlineDown></AiOutlineDown>
                    </IconContext.Provider>
                  </span>
                  <span> {currentCategory} </span>
                </button>
                <div className={menuItemsStyle}>
                  {categories.data &&
                    [{ id: 0, name: "الكل" }, ...categories.data.results].map(
                      (category) => (
                        <div
                          onClick={() => {
                            if (category.id === 0) {
                              setProductsUrl(
                                `https://${domain}/store/products/`
                              );
                              setMenuOpen(!menuOpen);
                              setCurrentCategory(category.name);
                              return;
                            }
                            setProductsUrl(
                              `https://${domain}/store/products/?category=${category.id}`
                            );
                            setMenuOpen(!menuOpen);
                            setCurrentCategory(category.name);
                            return;
                          }}
                          className="bg-reghda-blue py-2 flex flex-row space-x-1 items-center justify-center cursor-pointer">
                          <span className="text-sm text-center">
                            {category.name}
                          </span>
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
            <div className="w-1/2 flex flex-row items-center justify-end x-sm:space-x-2 md:space-x-8 2xl:space-x-16">
              <button
                onClick={() => {
                  history.push("/admin/store/add-product");
                }}
                className="bg-reghda-blue text-white  p-2 md:px-8 rounded-xl x-sm:text-sm md:text-base 2xl:text-4xl outline-none focus:outline-none">
                أضاف منتج
              </button>
              <button
                onClick={() => {
                  setIsOpen(true);
                }}
                className="bg-reghda-blue text-white  p-2 md:px-8 rounded-xl x-sm:text-sm md:text-base 2xl:text-4xl outline-none focus:outline-none">
                أضف صنف
              </button>
            </div>
          </div>
        </div>
        <div className="x-sm:p-4 md:p-8 x-sm:w-full md:w-11/12 h-full grid x-sm:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-8 justify-around">
          {/* {!data && <LoaderModal modalIsOpen={true}></LoaderModal>} */}
          {data &&
            data.results.map((product) => (
              <ProductCard
                deleteOne={async () => {
                  setLoading(true);
                  let res = await deleteProduct(product.id);
                  if (res && res.status === 204) {
                    mutate(`https://${domain}/store/products/`);
                  } else {
                    alert("retry! something wrong");
                  }
                  setLoading(false);
                }}
                detail={product}></ProductCard>
            ))}
          {/* <ProductCard></ProductCard>
          <ProductCard></ProductCard>
          <ProductCard></ProductCard>
          */}
        </div>
      </div>
      <CategoryModal
        isOpen={modalIsOpen}
        closing={() => setIsOpen(false)}></CategoryModal>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default Store;
