import React from "react";

const Item = ({ number, title, deleteItem, url }) => {
  const whichType = () => {
    if (
      title.includes(".jpg") ||
      title.includes(".jpeg") ||
      title.includes(".png")
    ) {
      return "images";
    }
    if (title.includes(".mp3")) {
      return "audios";
    }
    if (title.includes(".mp4") || title.includes("Youtube")) {
      return "videos";
    }
    if (
      title.includes(".pdf") ||
      title.includes(".doc" || title.includes(".docx"))
    ) {
      return "files";
    }
  };
  return (
    <div className="x-sm:p-1 md:p-2 flex flex-row items-center justify-between">
      <button
        onClick={() => deleteItem(whichType())}
        className="text-indigo-300 cursor-pointer"
      >
        حذف
      </button>
      <p
        onClick={() => {
          if (title.includes("Youtube")) {
            window.open(url, "_blank");
          }
        }}
        className={`${
          title.includes("Youtube") ? "underline cursor-pointer" : ""
        }`}
      >
        {title}
      </p>
    </div>
  );
};

export default Item;
