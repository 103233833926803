import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";

import useSWR, { mutate } from "swr";
import { fetcher } from "../../../services/fetcher";
import { domain } from "../../../global/domain";
import { getTheTokenFromStorage } from "../../../services/auth";
import {
  addNewCertificate,
  deleteCertificate,
  editCertification,
} from "../../../services/certifications";
import LoaderModal from "./content/LoaderModal";
import ModalDelete from "./ModalDelete";

const Certifications = () => {
  const [addCertificate, setAddCertificate] = useState(false);
  const [editCertificate, setEditCertificate] = useState(null);
  const [loading, setLoading] = useState(false);
  const { data, error } = useSWR(`https://${domain}/certificates/`, fetcher);
  const [formState, setFormState] = useState({
    title: "",
    description: "",
    attestation: null,
  });
  const [editFormState, setEditFormState] = useState({
    title: "",
    description: "",
    attestation: null,
  });

  const [openModal, setOpenModal] = useState(false);
  const [certificateId, setCertificateId] = useState(null);

  //Add
  const handleAddCertificate = async () => {
    if (formState.title.length < 1 || formState.description.length < 1) {
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append(`title`, formState.title);
    formData.append(`description`, formState.description);
    if (formState.attestation) {
      formData.append(`attestation`, formState.attestation);
    }
    let res = await addNewCertificate(formData, getTheTokenFromStorage());
    mutate(`https://${domain}/certificates/`);
    setFormState({
      title: "",
      description: "",
      attestation: null,
    });
    setAddCertificate(false);
    setLoading(false);
  };

  //Edit
  const handleEditCertificate = async (id) => {
    if (
      editFormState.title.length < 1 ||
      editFormState.description.length < 1
    ) {
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append(`title`, editFormState.title);
    formData.append(`description`, editFormState.description);
    if (editFormState.attestation) {
      formData.append(`attestation`, editFormState.attestation);
    }
    let res = await editCertification(formData, id, getTheTokenFromStorage());

    mutate(`https://${domain}/certificates/`);
    setEditFormState({
      title: "",
      description: "",
      attestation: null,
    });
    setEditCertificate(null);
    setLoading(false);
  };

  //Delete
  const handleDeleteCertificate = async (id) => {
    setLoading(true);
    await deleteCertificate(id, getTheTokenFromStorage());
    mutate(`https://${domain}/certificates/`);
    setLoading(false);
  };

  if (!data)
    return (
      <div className="min-h-screen h-full flex flex-col overflow-y-auto base-clamp">
        <div className="p-8 text-right">
          <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
            بيانات المستخدم
          </h3>
        </div>
        <div className="x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-10">
          <div className="h-48 flex justify-center items-center w-full bg-white rounded-lg">
            <CircularProgress />
          </div>
        </div>
      </div>
    );

  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto base-clamp rtl">
      <div className="p-8 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">الشهادات</h3>
      </div>
      <div className="x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-10">
        <button
          className="bg-reghda-black text-white p-2 rounded-xl md:text-base 2xl:text-xl"
          onClick={() => setAddCertificate(!addCertificate)}>
          أضف شهادة
        </button>
        {addCertificate && (
          <div className="bg-white x-sm:w-full md:w-full rounded-lg p-4 md:p-8 2xl:p-16 flex flex-col space-y-2 md:space-y-8 2xl:space-y-16  base-clamp">
            <fieldset className="w-full flex flex-row items-center justify-center">
              <textarea
                onChange={(event) =>
                  setFormState({
                    ...formState,
                    title: event.target.value,
                  })
                }
                defaultValue={formState.title}
                placeholder="عنوان"
                rows="1"
                type="text"
                className="w-11/12 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
              />
            </fieldset>
            <fieldset className="w-full flex flex-row items-center justify-center">
              <textarea
                onChange={(event) =>
                  setFormState({
                    ...formState,
                    description: event.target.value,
                  })
                }
                defaultValue={formState.description}
                placeholder="وصف"
                rows="3"
                type="text"
                className="w-11/12 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
              />
            </fieldset>
            <fieldset className="w-2/12 flex flex-row items-center justify-end x-sm:space-x-6 md:space-x-12">
              <div className="flex justify-center items-center">
                <p className="mx-2">
                  {formState.attestation && formState.attestation.name}
                </p>
                <div>
                  <input
                    onChange={(event) => {
                      if (event.target.files[0]) {
                        setFormState({
                          ...formState,
                          attestation: event.target.files[0],
                        });
                      }
                    }}
                    id="upload-img"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <div
                    onClick={() => {
                      document.querySelector("#upload-img").click();
                    }}
                    className=" bg-reghda-black text-reghda-blue py-2 px-4 rounded-xl md:text-base 2xl:text-3xl text-right cursor-pointer">
                    تحميل
                  </div>
                </div>
              </div>
            </fieldset>
            <button
              onClick={handleAddCertificate}
              className="bg-reghda-blue p-2 text-white rounded-xl x-sm:text-sm  outline-none focus:outline-none">
              حفظ
            </button>
          </div>
        )}
        <div className="bg-white x-sm:w-full md:w-full rounded-lg p-4 md:p-8 2xl:p-16 flex flex-col space-y-2 md:space-y-8 2xl:space-y-16  base-clamp">
          <div className="flex flex-wrap gap-2 mt-8">
            {data?.map((item) => (
              <div className="flex-100 md:flex-49 lg:flex-32 mb-4 md:mb-8 bg-white p-2 rounded-md shadow-md">
                {editCertificate !== item.id ? (
                  <>
                    <div className="flex gap-2">
                      <button
                        onClick={() => {
                          setEditCertificate(item.id);
                          setEditFormState({
                            title: item.title,
                            description: item.description,
                          });
                        }}
                        className="bg-reghda-blue p-2 text-white rounded-xl x-sm:text-sm  outline-none focus:outline-none">
                        تعديل
                      </button>
                      <button
                        onClick={() => {
                          setCertificateId(item.id);
                          setOpenModal(true);
                        }}
                        className="bg-red-500 p-2 text-white rounded-xl x-sm:text-sm  outline-none focus:outline-none">
                        حذف
                      </button>
                    </div>
                    <h1 className="text-center text-base md:text-xl font-semibold text-reghda-black">
                      {item.title}
                    </h1>
                    <img
                      src={item.attestation}
                      alt={item.slug}
                      className="w-9/12 mx-auto md:w-full"
                      style={{ maxHeight: 400, objectFit: "cover" }}
                    />
                    <p className="text-center text-sm md:text-base font-medium text-reghda-black">
                      {item.description}
                    </p>
                  </>
                ) : (
                  <div className="bg-white x-sm:w-full md:w-full rounded-lg p-4 md:p-8 2xl:p-16 flex flex-col space-y-2 md:space-y-4 2xl:space-y-8  base-clamp">
                    <fieldset className="w-full flex flex-row items-center justify-center">
                      <textarea
                        onChange={(event) =>
                          setEditFormState({
                            ...editFormState,
                            title: event.target.value,
                          })
                        }
                        defaultValue={editFormState.title}
                        placeholder="عنوان"
                        rows="1"
                        type="text"
                        className="w-11/12 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
                      />
                    </fieldset>
                    <fieldset className="w-full flex flex-row items-center justify-center">
                      <textarea
                        onChange={(event) =>
                          setEditFormState({
                            ...editFormState,
                            description: event.target.value,
                          })
                        }
                        defaultValue={editFormState.description}
                        placeholder="وصف"
                        rows="3"
                        type="text"
                        className="w-11/12 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
                      />
                    </fieldset>
                    <fieldset className="w-2/12 flex flex-row items-center justify-end x-sm:space-x-6 md:space-x-12">
                      <div className="flex justify-evenly items-center space-x-">
                        <p className="mx-2">
                          {editFormState.attestation &&
                            editFormState.attestation.name}
                        </p>

                        <div>
                          <input
                            onChange={(event) => {
                              if (event.target.files[0]) {
                                setEditFormState({
                                  ...editFormState,
                                  attestation: event.target.files[0],
                                });
                              }
                            }}
                            id="upload-img"
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          <div
                            onClick={() => {
                              document.querySelector("#upload-img").click();
                            }}
                            className=" bg-reghda-black text-reghda-blue py-2 px-4 rounded-xl md:text-base 2xl:text-3xl text-right cursor-pointer">
                            تحميل
                          </div>
                        </div>
                      </div>
                    </fieldset>

                    <button
                      onClick={() => handleEditCertificate(item.id)}
                      className="bg-reghda-blue p-2 text-white rounded-xl x-sm:text-sm  outline-none focus:outline-none">
                      حفظ
                    </button>
                    <button
                      onClick={() => setEditCertificate(null)}
                      className="bg-red-500 p-2 text-white rounded-xl x-sm:text-sm  outline-none focus:outline-none">
                      إلغاء
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <LoaderModal modalIsOpen={loading} />
      <ModalDelete
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleDeleteCertificate={() => handleDeleteCertificate(certificateId)}
      />
    </div>
  );
};

export default Certifications;
