import Axios from "axios";
import { domain } from "./../global/domain";

Axios.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

export async function addCourse(data, type) {
  try {
    const response = await Axios.post(
      `https://${domain}/courses/${type}/`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function updateCourse(type, id, data) {
  try {
    const response = await Axios.patch(
      `https://${domain}/courses/${type}/${id}/`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function deleteCourse(type, id) {
  try {
    const response = await Axios.delete(
      `https://${domain}/courses/${type}/${id}/`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

// export async function addPayedCourse(data, type) {
//   try {
//     const response = await Axios.post(
//       `https://ragda.site/courses/${type}/`,
//       data
//     );
//     return response;
//   } catch (error) {
//     return { error, isError: true };
//   }
// }

// export async function updatePayedCourse(type, id, data) {
//   try {
//     const response = await Axios.patch(
//       `https://ragda.site/courses/${type}/${id}`,
//       data
//     );
//     return response;
//   } catch (error) {
//     return { error, isError: true };
//   }
// }

// export async function deletePayedCourse(type, id) {
//   try {
//     const response = await Axios.delete(
//       `https://ragda.site/courses/${type}/${id}/`
//     );
//     return response;
//   } catch (error) {
//     return { error, isError: true };
//   }
// }
