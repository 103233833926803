import { CircularProgress, IconButton } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useState } from "react";
import useSWR from "swr";
import { domain } from "../../../../../global/domain";
import { deleteBalance } from "../../../../../services/affiliate";

import { getTheTokenFromStorage } from "../../../../../services/auth";
import { fetcherAuth } from "../../../../../services/fetcher";
import DeleteIcon from "@material-ui/icons/Delete";

import DeleteModal from "./DeleteModal";
import LoaderModal from "../LoaderModal";

const AddBalance = ({ data, mutate }) => {
  const token = getTheTokenFromStorage();

  const [loading, setLoading] = useState(false);
  const [balanceId, setBalanceId] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [page, setPage] = useState(1);

  const handleDelete = async (id) => {
    setOpenModal(false);
    setLoading(true);
    const res = await deleteBalance(id, token);
    if (res?.status === 204) {
      mutate();
    } else {
      alert(res?.data?.message);
    }
    setLoading(false);
  };

  return (
    <div className=" mb-8 space-y-10">
      <div className="bg-white x-sm:w-full md:w-full rounded-lg p-4 md:p-8  flex flex-col space-y-4 md:space-y-8 2xl:space-y-16">
        <h1 className="text-2xl"> جميع الأرصدة</h1>
        {data?.length === 0 && (
          <div className="text-center p-4 border rounded-md text-reghda-blue">
            لا توجد الأرصدة
          </div>
        )}
        {data?.length > 0 && (
          <table className="w-full border-collapse w-full text-center x-sm:text-xs sm:text-sm md:text-lg 2xl:text-3xl">
            <tr>
              <th className="border-2 border-solid border-gray-200 p-4">رقم</th>
              <th className="border-2 border-solid border-gray-200 p-4">
                اسم االمستخدم
              </th>
              <th className="border-2 border-solid border-gray-200 p-4 hidden md:table-cell">
                رمز العملة
              </th>
              <th className="border-2 border-solid border-gray-200 p-4 ">
                الحالة
              </th>
              <th className="border-2 border-solid border-gray-200 p-4 ">
                المبلغ المتاح
              </th>
              <th className="border-2 border-solid border-gray-200 p-4 ">
                حذف{" "}
              </th>
            </tr>

            {data?.map((user, i) => {
              return (
                <tr
                  className="w-full hover:text-gray-300 cursor-pointer"
                  key={i}
                >
                  <td className="border-2 border-solid border-gray-200 p-4">
                    {user.id}
                  </td>{" "}
                  <td className="border-2 border-solid border-gray-200 sm:p-4">
                    {user?.user?.username}
                  </td>
                  <td className="border-2 border-solid border-gray-200 p-4 hidden md:table-cell">
                    {user?.currency_code}
                  </td>
                  <td className="border-2 border-solid border-gray-200 p-4">
                    {user?.status}
                  </td>
                  <td className="border-2 border-solid border-gray-200 p-4">
                    {user?.available_amount}
                  </td>
                  <td className="border-2 border-solid border-gray-200 p-4">
                    <IconButton
                      onClick={() => {
                        setBalanceId(user.id);
                        setOpenModal(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </td>
                </tr>
              );
            })}
          </table>
        )}
        {!data && (
          <div className="h-48 flex justify-center items-center w-full">
            <CircularProgress />
          </div>
        )}
      </div>
      {data && Math.ceil(data.count / 50) > 1 && (
        <div className="flex justify-center bg-white p-2 rounded-md">
          <Pagination
            count={Math.ceil(data.count / 50)}
            page={page}
            onChange={(e, page) => setPage(page)}
          />
        </div>
      )}

      <DeleteModal
        isOpen={openModal}
        closing={() => setOpenModal(false)}
        deleteFn={() => handleDelete(balanceId)}
      />
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default AddBalance;
