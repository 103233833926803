import React from "react";
import { Link } from "react-router-dom";

const CourseCard = ({ course }) => {
  return (
    <Link to={`/admin/subscribers/${course.id}`} className="cursor-pointer">
      <div className="flex flex-col items-center space-y-4 text-gray-600 x-sm:text-lg sm:text-xl lg:text-2xl 2xl:text-4xl font-bold">
        <img src={course.picture} alt="course-pic" className="shadow-2xl" />
        <h6 className="text-center x-sm:text-sm sm:text-lg lg:text-xl 2xl:text-2xl">
          {course.title}
        </h6>
      </div>
    </Link>
  );
};

export default CourseCard;
