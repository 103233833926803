import React, { useState } from "react";
import PreviewVideo from "../media-center-modal/PreviewVideo";

import useSWR, { mutate } from "swr";
import { fetcher } from "../../../../../services/fetcher";
import { domain } from "../../../../../global/domain";

const MediaCenterOnlyVideo = ({ addItem, setIsOpen }) => {
  const [url, setUrl] = useState(`https://${domain}/media-library/videos/`);

  const { data, error } = useSWR(url, fetcher);

  return (
    <div className="h-full flex flex-col">
      <div className="bg-white x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-10">
        <div className="x-sm:w-full md:w-11/12 rounded-lg flex flex-row x-sm:p-2 md:p-4 2xl:p-12 x-sm:text-xs md:text-sm 2xl:text-4xl">
          <div className="grid md:grid-cols-4 2xl:grid-cols-4 gap-x-16 gap-y-10 justify-around h-72">
            {data &&
              data.length > 0 &&
              data.map((file, i) => {
                return (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      addItem({ from: "media", url: file.uploaded_video });
                      setIsOpen(false);
                    }}>
                    <PreviewVideo
                      key={i}
                      file={file}
                      name={file.name}></PreviewVideo>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaCenterOnlyVideo;
