import React, { useState } from "react";
import TableRow from "./content/subscriberDetail/TableRow";
import LoaderModal from "./content/LoaderModal";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../services/fetcher";
import { domain } from "./../../../global/domain";
import { useParams } from "react-router-dom";
import OpenChapterModal from "./content/subscriberDetail/OpenChapterModal";
import CalendarModal from "./content/subscriberDetail/CalendarModal";
import SessionModal from "./content/subscriberDetail/SessionModal";

const SubscribersDetail = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [sessionModel, setSessionModel] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [clickedChapter, setClickedChapter] = useState(null);
  const [clickedSession, setClickedSession] = useState(null);

  const { data, error } = useSWR(
    `https://${domain}/subscriptions/all/${id}/details`,
    fetcher
  );

  return (
    <>
      {data && (
        <div className="min-h-screen h-full flex flex-col space-y-6 overflow-y-auto x-sm:px-4 md:px-0">
          <div className="p-8 text-right">
            <p className="md:text-base 2xl:text-2xl">تدريب 1 ـ 1</p>
            <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
              متابعة المشتركين
            </h3>
          </div>
          <div className="bg-white rounded-2xl shadow-2xl x-sm:w-full md:w-11/12 md:mx-8 x-sm:p-2 md:p-4 2xl:p-12 flex flex-col">
            <div className="flex flex-row items-center justify-end space-x-6">
              <h1 className="font-bold x-sm:text-xs md:text-2xl 2xl:text-6xl">
                {`${data.user.family_name} ${data.user.given_name}`}
              </h1>
              <img
                className="rounded-full x-sm:w-14 x-sm:h-14 md:w-20 md:h-20 2xl:w-36 2xl:h-36 picfit"
                alt="logo"
                src={data.user.image}
              />
            </div>
            <div className="flex flex-col x-sm:space-y-4 md:space-y-8 2xl:space-y-12 border-t-2 x-sm:mt-2 md:mt-4 md:pt-4 2xl:mt-8 2xl:pt-8 border-gray-200">
              <div className="flex flex-row space-x-4 justify-between font-bold text-gray-300 x-sm:text-xs md:text-base 2xl:text-4xl">
                <i>التقدم</i>
                <i>الجلسات المنجزة</i>
                <i>فصول المكتملة</i>
                <i>تاريخ الإشتراك</i>
              </div>
              <div className="flex flex-row space-x-4 justify-between font-bold text-reghda-black x-sm:text-xs md:text-base 2xl:text-4xl">
                <i>{data.progress}</i>
                <i>{data.completed_sessions} جلسات</i>
                <i>{data.completed_chapters} فصل</i>
                <i>{data.debut_date}</i>
              </div>
              <div className="flex flex-row space-x-4 self-end">
                <button
                  onClick={() => setCalendarOpen(true)}
                  className="bg-reghda-blue text-white x-sm:p-1 x-sm:px-4 md:p-2 md:px-8 rounded-xl x-sm:text-xs md:text-base 2xl:text-4xl outline-none focus:outline-none">
                  برمج جلسة
                </button>
                <a
                  href={`mailto:${data.user.email}`}
                  target="_blank"
                  rel="noreferrer"
                  className="">
                  <button className="bg-reghda-black text-white x-sm:p-1 x-sm:px-4 md:p-2 md:px-8 rounded-xl x-sm:text-xs md:text-base 2xl:text-4xl outline-none focus:outline-none">
                    تواصل معه
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="x-sm:w-full md:w-11/12 md:mx-8 md:p-4 2xl:p-12 flex x-sm:flex-col x-sm:space-y-6 md:flex-row md:space-x-6">
            <div className="bg-white rounded-2xl shadow-2xl x-sm:w-full md:w-1/2 x-sm:p-2 md:p-4 2xl:p-12 flex flex-col space-y-3 2xl:space-y-6">
              <div className="flex flex-row items-center justify-center font-bold text-reghda-blue border-b-2 border-reghda-blue pb-2 mb-2 x-sm:text-xs md:text-base 2xl:text-4xl">
                <i className="w-1/3 text-center">وقت الإنجاز</i>
                <i className="w-1/3 text-center">تاريخ الإنجاز</i>
                <i className="w-1/3 text-center">الجلسات</i>
              </div>
              {data &&
                data.sessions.map((session) => (
                  <TableRow
                    sessionItem={true}
                    setSessionModel={setSessionModel}
                    data={[session.time, session.date, session.title]}
                    detail={session}
                    setClickedSession={setClickedSession}></TableRow>
                ))}
            </div>
            <div className="bg-white rounded-2xl shadow-2xl x-sm:w-full md:w-1/2 x-sm:p-2 md:p-4 2xl:p-12 flex flex-col space-y-3 2xl:space-y-6">
              <div className="flex flex-row items-center justify-center font-bold text-reghda-blue border-b-2 border-reghda-blue pb-2 mb-2 x-sm:text-xs md:text-base 2xl:text-4xl">
                <i className="w-1/3 text-center">الوضعية</i>
                <i className="w-1/3 text-center">الدروس المكتملة</i>
                <i className="w-1/3 text-center">فصول</i>
              </div>
              {data.details.map((item) => (
                <TableRow
                  setIsOpen={setIsOpen}
                  setClickedChapter={setClickedChapter}
                  data={[
                    item.status,
                    item.completed_modules,
                    item.chapter.title,
                  ]}
                  chapterId={item.chapter.id}></TableRow>
              ))}
            </div>
          </div>
          <CalendarModal
            userId={data.user.id}
            chapterId={clickedChapter}
            isOpen={calendarOpen}
            closing={() => setCalendarOpen(false)}
            mutate={() =>
              mutate(`https://${domain}/subscriptions/all/${id}/details`)
            }></CalendarModal>
          <OpenChapterModal
            userId={data.user.id}
            chapterId={clickedChapter}
            isOpen={modalIsOpen}
            closing={() => setIsOpen(false)}
            mutate={() =>
              mutate(`https://${domain}/subscriptions/all/${id}/details`)
            }></OpenChapterModal>
          {clickedSession && (
            <SessionModal
              session={clickedSession}
              isOpen={sessionModel}
              closing={() => setSessionModel(false)}></SessionModal>
          )}
          <LoaderModal modalIsOpen={loading}></LoaderModal>
        </div>
      )}
    </>
  );
};

export default SubscribersDetail;
