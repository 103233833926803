import React from "react";

const TableRow = ({
  data,
  chapterId,
  setIsOpen,
  setClickedChapter,
  sessionItem,
  setSessionModel,
  setClickedSession,
  detail,
}) => {
  return (
    <div
      onClick={() => {
        if (sessionItem) {
          setSessionModel(true);
          setClickedSession(detail);
        }
      }}
      className={`flex flex-row items-center justify-center font-bold text-gray-500 x-sm:text-xs md:text-base 2xl:text-4xl ${
        sessionItem && "cursor-pointer"
      }`}
    >
      {data &&
        data.map((e, i) => {
          if (e === "مغلق") {
            return (
              <i className="w-1/3 text-center text-red-500" key={i}>
                {e}
              </i>
            );
          } else if (e === "مفتوح") {
            return (
              <i className="w-1/3 text-center text-indigo-300" key={i}>
                {e}
              </i>
            );
          } else if (e === "مكتمل") {
            return (
              <i className="w-1/3 text-center text-green-300" key={i}>
                {e}
              </i>
            );
          } else if (e === "في الإنتظار") {
            return (
              <i
                onClick={() => {
                  setIsOpen(true);
                  setClickedChapter(chapterId);
                }}
                className="w-1/3 text-center text-yellow-300 cursor-pointer"
                key={i}
              >
                {e}
              </i>
            );
          }
          return (
            <i className="w-1/3 text-center" key={i}>
              {e}
            </i>
          );
        })}
    </div>
  );
};

export default TableRow;
