import Axios from "axios";
import { domain } from "./../global/domain";

Axios.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

export async function addChapterToServer(data) {
  try {
    const response = await Axios.post(
      `https://${domain}/courses/chapters/`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function updateChapter(id, data) {
  try {
    const response = await Axios.patch(
      `https://${domain}/courses/chapters/${id}/`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function deleteChapterFromServer(id) {
  try {
    const response = await Axios.delete(
      `https://${domain}/courses/chapters/${id}/`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function changeChapterOrder(data) {
  try {
    const response = await Axios.post(
      `https://${domain}/courses/change-order/chapters`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
