import React, { useState } from "react";
import { IconContext } from "react-icons";
import { domain } from "../../../../../global/domain";
// import { FaRegFileImage } from "react-icons/fa";

const PreviewImage = ({ file, deleteOne }) => {
  return (
    <div className="flex flex-col items-center space-y-2 md:text-xs 2xl:text-lg cursor-pointer">
      {/* {!file.size && (
        <IconContext.Provider
          value={{ size: "8em", style: { color: "#fed7e2" } }}
        >
          <FaRegFileImage></FaRegFileImage>
        </IconContext.Provider>
      )} */}
      {/* {file.size && <img src={URL.createObjectURL(file)} alt="somePreview" />}
      <p className="text-center break-all">{name}</p>
    </div> */}
      <div>
        <img src={file.uploaded_image} alt="somePreview" />
        <p className="text-center break-all">
          {file.uploaded_image.replace(`http://${domain}/media/images/`, "")}
        </p>
      </div>
    </div>
  );
};

export default PreviewImage;
