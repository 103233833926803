import React from "react";
import Modal from "react-modal";

const ProgressModal = ({ modalIsOpen, progress }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      style={{
        overlay: {
          zIndex: 1000,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "rgba(0, 0, 0, 0)",
          borderColor: "rgba(0, 0, 0, 0)",
          width: "70%",
        },
      }}
    >
      <div className="w-full flex flex-col items-center justify-center space-y-4">
        <progress
          className="w-full h-8 bg-gradient-to-r from-pink-400 to-indigo-300"
          value={progress}
          min="0"
          max="100"
        ></progress>
        <div className="text-gray-200">{progress} %</div>
      </div>
    </Modal>
  );
};

export default ProgressModal;
