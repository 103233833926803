import React, { useState } from "react";
import { IconContext } from "react-icons";
import { AiOutlinePlaySquare } from "react-icons/ai";
import CourseCard from "./CourseCard";
import { Link } from "react-router-dom";
import { Loader } from "react-loader-spinner";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../../../services/fetcher";
import { deleteCourse } from "../../../../../services/course";
import { domain } from "./../../../../../global/domain";

const SecondCoursesSection = () => {
  const [loading, setLoading] = useState(false);

  const { data, error } = useSWR(`https://${domain}/courses/all/`, fetcher);

  return (
    <div className="bg-white x-sm:w-full md:w-11/12 rounded-lg flex flex-col x-sm:p-2 md:p-4 2xl:p-12">
      <div className="flex flex-row space-x-1 items-center self-end x-sm:text-2xl md:text-base 2xl:text-4xl">
        <h3>دوراتي</h3>
        <IconContext.Provider
          value={{ size: "1em", style: { color: "#5b5b5b" } }}>
          <AiOutlinePlaySquare></AiOutlinePlaySquare>
        </IconContext.Provider>
      </div>
      {data &&
        data.map((course, i) => (
          <CourseCard
            deleteCourse={async () => {
              setLoading(true);
              let res = await deleteCourse("free", course.id);
              if (res && res.status === 204) {
                mutate("https://ragda.site/courses/free/");
              } else {
                alert("retry! something wrong");
              }
              setLoading(false);
            }}
            courseDetail={course}></CourseCard>
        ))}
      <div className="text-center mt-4">
        <Link
          to="/admin/normal-courses"
          className="bg-reghda-black text-white p-2 px-8 rounded-xl x-sm:text-sm md:text-base 2xl:text-4xl">
          تصفح جميع الدورات
        </Link>
      </div>
    </div>
  );
};

export default SecondCoursesSection;
